import { Route, Routes as ReactRouterRoutes } from 'react-router-dom'
import ExternalScripts from '../../components/ExternalScripts'
import { FloatingButtonGroup } from '../../components/FloatingButtonGroup'
import LocalSettingsProvider from '../../components/LocalSettings'
import ShopStatusChecker from '../../components/ShopStatusChecker'
import { useShopStatus } from '../../hooks/useShopStatus'
import Routes from '../../Routes'
import ConfirmCharge from '../Charge'

export default function Application() {
  const shopStatus = useShopStatus()
  return (
    <>
      <ReactRouterRoutes>
        <Route
          path="/charge/confirm"
          element={<ConfirmCharge refetchShopStatus={shopStatus.refetch} />}
        />
        <Route
          path="*"
          element={
            <ShopStatusChecker shopStatus={shopStatus}>
              <LocalSettingsProvider>
                <Routes />
                <FloatingButtonGroup />
              </LocalSettingsProvider>
              <ExternalScripts />
            </ShopStatusChecker>
          }
        />
      </ReactRouterRoutes>
    </>
  )
}
