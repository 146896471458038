import { useField, notEmpty } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'

import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_TRIGGER_OPTIONS,
} from '../../../../../../../../constants/checkout'
import { checkTriggerGroup } from '../validators/triggerCondition'

export default function useFormFields(currentValues) {
  const { t } = useTranslation()
  const schema = getFields(currentValues, t)

  const fields = schema.reduce((acc, field) => {
    if (field.associatedFieldHook === 'useField') {
      acc[field.name] = useField({
        value: field.value,
        validates: field.validates,
      })
    }
    return acc
  }, {})

  if(fields.triggerType.value === CAMPAIGN_TRIGGER_OPTIONS.ALL) {
    delete fields.triggerGroup
  }

  return fields
}

const getFields = (currentValues, t) => {
  let common = [
    {
      name: 'name',
      value: currentValues?.name || '',
      associatedFieldHook: 'useField',
      validates: [
        notEmpty(t('CheckoutUpsell.CampaignCreate.section.form.name.emptyText')),
        (value) =>
          value.length < 3 &&
          t('CheckoutUpsell.CampaignCreate.section.form.name.minLength'),
        (value) =>
          value.length < 50 &&
          t('CheckoutUpsell.CampaignCreate.section.form.name.maxLength'),
      ],
    },
    {
      name: 'duration',
      value: {
        start: currentValues.startDate
          ? new Date(currentValues.startDate * 1000)
          : null,
        end: currentValues.endDate
          ? new Date(currentValues.endDate * 1000)
          : null,
      },
      associatedFieldHook: 'useField',
      validates: [],
    },
    {
      name: 'triggerType',
      value: currentValues?.triggerType || CAMPAIGN_TRIGGER_OPTIONS.ALL,
      associatedFieldHook: 'useField',
      validates: [],
    },
    {
      name: 'triggerGroup',
      value: currentValues?.triggerGroup || {},
      associatedFieldHook: 'useField',
      // TODO: Fix validator later
      validates: [
        (tg)=> checkTriggerGroup(tg, t)
      ],
    },
    {
      name: 'products',
      value: currentValues?.products || [],
      associatedFieldHook: 'useField',
      validates: [],
    },
    {
      name: 'status',
      value: currentValues?.status || CAMPAIGN_STATUSES.ACTIVE,
      associatedFieldHook: 'useField',
      validates: [],
    },
    {
      name: 'postCheckoutData',
      value: currentValues?.postCheckoutData || {},
      associatedFieldHook: 'useField',
      validates: [],
    }
  ]

  return common

}
