import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Collapsible,
  Divider,
  FormLayout,
  Grid,
  Icon,
  InlineError,
  InlineGrid,
  InlineStack,
  Scrollable,
  Select,
  Text,
  TextField,
} from '@shopify/polaris'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DeleteIcon,
  PlusIcon,
} from '@shopify/polaris-icons'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import merge from 'lodash.merge'

let ACTIVE_FIELD = null

export default function SideBar({ form }) {
  const { t } = useTranslation()

  const [bannerSettingsOpen, setBannerSettingsOpen] = useState(false)
  const [productSettingsOpen, setProductSettingsOpen] = useState(false)
  const [textsOpen, setTextsOpen] = useState(false)

  return (
    <Scrollable height={'calc(100% - 58px)'}>
      <div
        style={{
          width: '440px',
          height: '100%',
          borderRight: '1px solid #dfe3e8',
        }}
      >
        <BlockStack>
          <Box padding={'300'}>
            <Select
              {...form.fields.promotionType}
              helpText={t('PostCheckoutEditor.sideBar.promotionType.helpText')}
              label={t('PostCheckoutEditor.sideBar.promotionType.title')}
              options={[
                {
                  label: t(
                    'PostCheckoutEditor.sideBar.promotionType.option.single_product',
                  ),
                  value: 'single_product',
                },
                {
                  label: t(
                    'PostCheckoutEditor.sideBar.promotionType.option.multiple_products',
                  ),
                  value: 'multiple_products',
                },
              ]}
            />
          </Box>
          <Divider />
          <SideBarTab
            open={bannerSettingsOpen}
            setOpen={setBannerSettingsOpen}
            label={t('PostCheckoutEditor.sideBar.Banner.title')}
          />
          <Collapsible open={bannerSettingsOpen}>
            <BannerSettings form={form} />
          </Collapsible>
          <SideBarTab
            open={productSettingsOpen}
            setOpen={setProductSettingsOpen}
            label={t(
              `PostCheckoutEditor.sideBar.ProductSetting.${form.fields.promotionType.value}.title`,
            )}
          />
          <Collapsible open={productSettingsOpen}>
            <ProductSettings form={form} />
          </Collapsible>
          <SideBarTab
            open={textsOpen}
            setOpen={setTextsOpen}
            label={t(`PostCheckoutEditor.sideBar.LabelConfig.title`)}
          />
          <Collapsible open={textsOpen}>
            <Texts form={form} />
          </Collapsible>
        </BlockStack>
      </div>
    </Scrollable>
  )
}

function BannerSettings({ form }) {
  const { t } = useTranslation()

  const generalSettingSchema = getGeneralSettingSchema()
  const lineSettingsSchema = getLineSettingsSchema()

  const bannerSettings = form.fields.banner.value
  return (
    <div
      style={{
        padding: '0 var(--p-space-200) 0 var(--p-space-800)',
      }}
    >
      <BlockStack gap={'300'}>
        <Card padding={'0'}>
          <Box padding={'300'}>
            <Text variant="headingXs">
              {t('PostCheckoutEditor.sideBar.Banner.generalSettingsTitle')}
            </Text>
          </Box>
          <Divider />
          <Box padding={'300'}>
            <BlockStack gap={'300'}>
              {generalSettingSchema.map((item, index) => (
                <Fragment key={index}>
                  {(() => {
                    const Component = getComponent(item.controlType)
                    return (
                      <Component
                        options={item.options}
                        label={item.label}
                        value={bannerSettings.general[item.key]}
                        onChange={(value) => {
                          form.fields.banner.onChange({
                            ...bannerSettings,
                            general: {
                              ...bannerSettings.general,
                              [item.key]: value,
                            },
                          })
                        }}
                      />
                    )
                  })()}
                </Fragment>
              ))}
            </BlockStack>
          </Box>
        </Card>
        <Card padding={'0'}>
          <Box padding={'300'}>
            <Text variant="headingXs">
              {t('PostCheckoutEditor.sideBar.Banner.lineSettingsTitle')}
            </Text>
          </Box>
          <Divider />
          <Box padding={'300'}>
            <BlockStack gap={'300'}>
              {lineSettingsSchema.map((item, index) => (
                <Fragment key={index}>
                  {(() => {
                    const Component = getComponent(item.controlType)
                    return (
                      <Component
                        options={item.options ?? []}
                        label={item.label}
                        value={bannerSettings.line[item.key]}
                        onChange={(value) => {
                          form.fields.banner.onChange({
                            ...bannerSettings,
                            line: {
                              ...bannerSettings.line,
                              [item.key]: value,
                            },
                          })
                        }}
                      />
                    )
                  })()}
                </Fragment>
              ))}
            </BlockStack>
          </Box>
        </Card>
      </BlockStack>
    </div>
  )

  function getGeneralSettingSchema() {
    return [
      {
        key: 'layout',
        label: t('PostCheckoutEditor.sideBar.Banner.form.layout.label'),
        controlType: 'selector',
        options: [
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.layout.options.full_width',
            ),
            value: 'full_width',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.layout.options.center',
            ),
            value: 'center',
          },
        ],
      },
      {
        key: 'background',
        label: t('PostCheckoutEditor.sideBar.Banner.form.background.label'),
        controlType: 'selector',
        options: [
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.background.options.transparent',
            ),
            value: 'transparent',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.background.options.secondary',
            ),
            value: 'secondary',
          },
        ],
      },
      ,
      {
        key: 'border',
        label: t('PostCheckoutEditor.sideBar.Banner.form.border.label'),
        controlType: 'selector',
        options: [
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.border.options.hide',
            ),
            value: 'none',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.border.options.show',
            ),
            value: 'block',
          },
        ],
      },
      {
        key: 'alignment',
        label: t('PostCheckoutEditor.sideBar.Banner.form.alignment.label'),
        controlType: 'selector',
        options: [
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.alignment.options.leading',
            ),
            value: 'leading',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.alignment.options.center',
            ),
            value: 'center',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.alignment.options.trailing',
            ),
            value: 'trailing',
          },
        ],
      },
      ,
      {
        key: 'spacing',
        label: t('PostCheckoutEditor.sideBar.Banner.form.spacing.label'),
        controlType: 'selector',
        options: [
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.spacing.options.xtight',
            ),
            value: 'xtight',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.spacing.options.tight',
            ),
            value: 'tight',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.spacing.options.loose',
            ),
            value: 'loose',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.form.spacing.options.xloose',
            ),
            value: 'xloose',
          },
        ],
      },
    ]
  }

  function getLineSettingsSchema() {
    return [
      {
        key: 'text',
        label: t('PostCheckoutEditor.sideBar.Banner.lineSettings.text.label'),
        controlType: 'textfield',
        disabled: false,
      },
      {
        key: 'appearance',
        label: t('PostCheckoutEditor.sideBar.Banner.lineSettings.color.label'),
        controlType: 'selector',
        options: [
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.lineSettings.color.options.regular',
            ),
            value: 'regular',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.lineSettings.color.options.critical',
            ),
            value: 'critical',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.lineSettings.color.options.warning',
            ),
            value: 'warning',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.lineSettings.color.options.success',
            ),
            value: 'success',
          },
        ],
      },
      {
        key: 'size',
        label: t('PostCheckoutEditor.sideBar.Banner.lineSettings.size.label'),
        controlType: 'selector',
        options: [
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.lineSettings.size.options.small',
            ),
            value: 'small',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.lineSettings.size.options.medium',
            ),
            value: 'medium',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.lineSettings.size.options.large',
            ),
            value: 'large',
          },
          {
            label: t(
              'PostCheckoutEditor.sideBar.Banner.lineSettings.size.options.xlarge',
            ),
            value: 'xlarge',
          },
        ],
      },
    ]
  }

  function getComponent(controlType) {
    if (controlType === 'selector') {
      return Select
    }

    if (controlType === 'textarea') {
      return (props) => <TextField multiline={2} {...props} />
    }

    if (controlType === 'textfield') {
      return TextField
    }

    return TextField
  }
}

function ProductSettings({ form }) {
  const promotionType = form.fields.promotionType.value
  const { t } = useTranslation()
  const slots = form.fields.slots.value
  if (promotionType === 'single_product') {
    return (
      <Box padding={'300'}>
        <SlotCard
          promotionType={promotionType}
          currentLanguage={form.fields.translations.value}
          error={form.fields.slots.error?.[0]}
          slot={slots[0]}
          onChange={(slot) => {
            merge(form.fields.slots.value, [slot])
            form.fields.slots.onChange(form.fields.slots.value)
          }}
        />
      </Box>
    )
  }
  return (
    <div
      style={{
        padding: '0 var(--p-space-200) 0 var(--p-space-800)',
      }}
    >
      <BlockStack gap={'300'}>
        {form.fields.slots.value.map((slot, index) => (
          <SlotCard
            promotionType={promotionType}
            index={index}
            key={index}
            currentLanguage={form.fields.translations.value}
            error={form.fields.slots.error?.[index]}
            slot={slot}
            onChange={(slot) => {
              const copy = form.fields.slots.value
              copy[index] = slot
              form.fields.slots.onChange(copy)
            }}
            onDelete={
              index >= 2
                ? () => {
                    const copy = [...form.fields.slots.value]
                    form.fields.slots.onChange(
                      copy.filter((_, i) => i !== index),
                    )
                  }
                : undefined
            }
          />
        ))}
        <Button
          onClick={() => {
            form.fields.slots.onChange([
              ...form.fields.slots.value,
              {
                product: null,
                recommendation: 'automatic',
                translations: {},
                discount: {
                  value: 0,
                  type: 'percentage',
                },
                allowQuantityChange: true,
                minQuantity: 1,
                maxQuantity: 1,
              },
            ])
          }}
          icon={PlusIcon}
          fullWidth
          variant="primary"
          disabled={form.fields.slots.value.length >= 3}
        >
          {t('PostCheckoutEditor.sideBar.ProductSetting.addSlot')}
        </Button>
      </BlockStack>
    </div>
  )

  function SlotCard({
    slot,
    onChange,
    currentLanguage,
    onDelete,
    index = -1,
    error,
  }) {
    const [activeFieled, setActiveField] = useState(ACTIVE_FIELD)
    const recommendationOptions = [
      {
        label: t(
          'PostCheckoutEditor.sideBar.ProductSetting.recommendation.option.automatic',
        ),
        value: 'automatic',
      },
      {
        label: t(
          'PostCheckoutEditor.sideBar.ProductSetting.recommendation.option.manual',
        ),
        value: 'manual',
      },
    ]

    const productHandler = async () => {
      const products = await window.shopify.resourcePicker({
        type: 'product',
        action: 'select',
        filter: {
          archived: false,
        },
      })

      if (products.length > 0) {
        onChange({
          ...slot,
          product: products[0],
          translations: {
            ...slot.translations,
            [currentLanguage]: {
              title: products[0].title,
              description: '',
            },
          },
        })
      }
    }

    return (
      <Card padding={'0'}>
        <Box padding={'300'}>
          <BlockStack gap={'300'}>
            {error && <Banner tone="critical" title={error} />}
            {typeof onDelete === 'function' && (
              <InlineStack align="end">
                <Button onClick={onDelete} icon={DeleteIcon} size="slim" />
              </InlineStack>
            )}
            <Select
              helpText={t(
                'PostCheckoutEditor.sideBar.ProductSetting.recommendation.helpText',
              )}
              label={t(
                'PostCheckoutEditor.sideBar.ProductSetting.recommendation.label',
              )}
              options={recommendationOptions}
              value={slot?.recommendation}
              onChange={(val) => {
                onChange({
                  ...slot,
                  recommendation: val,
                })
              }}
            />
            <InlineGrid columns={'1fr 1fr'} gap={'300'}>
              <Select
                label={t(
                  'PostCheckoutEditor.sideBar.ProductSetting.discountType.label',
                )}
                value={slot?.discount?.type}
                onChange={(val) => {
                  onChange({
                    ...slot,
                    discount: {
                      ...slot.discount,
                      type: val,
                    },
                  })
                }}
                options={[
                  {
                    label: t(
                      'PostCheckoutEditor.sideBar.ProductSetting.discountType.options.AMOUNT',
                    ),
                    value: 'flat',
                  },
                  {
                    label: t(
                      'PostCheckoutEditor.sideBar.ProductSetting.discountType.options.PERCENTAGE',
                    ),
                    value: 'percentage',
                  },
                ]}
              />
              <TextField
                label={t('PostCheckoutEditor.sideBar.ProductSetting.discount')}
                suffix={
                  slot?.discount?.type === 'percentage'
                    ? '%'
                    : window.shopify.data.shop.currency
                }
                type="number"
                value={slot?.discount?.value}
                onChange={(val) => {
                  let value = 0
                  try {
                    value = parseFloat(val)
                    value = isNaN(value) ? 0 : value
                  } catch (e) {
                    value = 0
                  }
                  onChange({
                    ...slot,
                    discount: {
                      ...slot.discount,
                      value: value,
                    },
                  })
                }}
                {...focusUtil('discount')}
              />
            </InlineGrid>
          </BlockStack>
        </Box>
        <Divider />
        {slot?.recommendation === 'manual' && (
          <Box padding={'300'}>
            {!slot.product ? (
              <Button
                fullWidth
                onClick={productHandler}
                variant="primary"
                icon={PlusIcon}
              >
                {t('PostCheckoutEditor.sideBar.ProductSetting.addProduct')}
              </Button>
            ) : (
              <BlockStack gap={'300'}>
                <InlineGrid columns={'1fr auto'} alignItems="center">
                  <Text variant="headingSm">
                    {t(
                      'PostCheckoutEditor.sideBar.ProductSetting.productDetails',
                    )}
                  </Text>
                  <Button
                    variant="secondary"
                    size="slim"
                    onClick={productHandler}
                  >
                    {t('PostCheckoutEditor.sideBar.ProductSetting.change')}
                  </Button>
                </InlineGrid>
                <TextField
                  label={t(
                    'PostCheckoutEditor.sideBar.ProductSetting.product.title',
                  )}
                  value={slot.product.title}
                  disabled
                />
                <TextField
                  label={t(
                    'PostCheckoutEditor.sideBar.ProductSetting.product.price',
                  )}
                  value={slot.product.variants[0].price}
                  prefix={window.shopify.data.shop.currency}
                  disabled
                />
                <TextField
                  label={t(
                    'PostCheckoutEditor.sideBar.ProductSetting.product.productTitle.label',
                  )}
                  helpText={t(
                    'PostCheckoutEditor.sideBar.ProductSetting.product.productTitle.helpText',
                  )}
                  {...focusUtil('productTitle')}
                  onChange={(val) => {
                    onChange({
                      ...slot,
                      translations: {
                        ...slot.translations,
                        [currentLanguage]: {
                          ...slot.translations[currentLanguage],
                          title: val,
                        },
                      },
                    })
                  }}
                  value={slot.translations[currentLanguage]?.title || ''}
                />
                {promotionType === 'single_product' && (
                  <TextField
                    label={t(
                      'PostCheckoutEditor.sideBar.ProductSetting.product.productDescription.label',
                    )}
                    helpText={t(
                      'PostCheckoutEditor.sideBar.ProductSetting.product.productDescription.helpText',
                    )}
                    onChange={(val) => {
                      onChange({
                        ...slot,
                        translations: {
                          ...slot.translations,
                          [currentLanguage]: {
                            ...slot.translations[currentLanguage],
                            description: val,
                          },
                        },
                      })
                    }}
                    value={
                      slot.translations[currentLanguage]?.description || ''
                    }
                    {...focusUtil('productDescription')}
                  />
                )}
                <Checkbox
                  checked={slot.allowQuantityChange}
                  onChange={(val) => {
                    onChange({
                      ...slot,
                      allowQuantityChange: val,
                    })
                  }}
                  label={t(
                    'PostCheckoutEditor.sideBar.ProductSetting.product.allowQuantityChange.label',
                  )}
                />
                <InlineGrid columns={'1fr 1fr'} gap={'300'}>
                  <TextField
                    label={t(
                      'PostCheckoutEditor.sideBar.ProductSetting.product.minQuantity.label',
                    )}
                    type="number"
                    value={slot.minQuantity}
                    onChange={(val) => {
                      onChange({
                        ...slot,
                        minQuantity: parseInt(val),
                      })
                    }}
                    {...focusUtil('minQuantity')}
                  />
                  <TextField
                    label={t(
                      'PostCheckoutEditor.sideBar.ProductSetting.product.maxQuantity.label',
                    )}
                    type="number"
                    value={slot.maxQuantity}
                    onChange={(val) => {
                      onChange({
                        ...slot,
                        maxQuantity: parseInt(val),
                      })
                    }}
                    {...focusUtil('maxQuantity')}
                  />
                </InlineGrid>
              </BlockStack>
            )}
          </Box>
        )}
      </Card>
    )

    function focusUtil(key) {
      key = index + '-' + key
      return {
        onFocus: () => {
          setActiveField(key)
          ACTIVE_FIELD = key
        },
        focused: activeFieled == key,
        onBlur: () => {
          setActiveField(null)
          ACTIVE_FIELD = null
        },
      }
    }
  }
}

function Texts({ form }) {
  const { t } = useTranslation()
  const currentLocale = form.fields.translations.value
  const currentValue = form.fields.texts.value[currentLocale] ?? {}
  return (
    <div
      style={{
        padding: '0 var(--p-space-200) 0 var(--p-space-800)',
      }}
    >
      <BlockStack gap={'300'}>
        <TextField
          label={t('PostCheckoutEditor.sideBar.LabelConfig.form.atc.label')}
          placeholder={t(
            'PostCheckoutEditor.sideBar.LabelConfig.form.atc.placeholder',
          )}
          readOnly
          value={currentValue.buy_button_text}
          onChange={(val) => {
            form.fields.texts.onChange({
              ...form.fields.texts.value,
              [currentLocale]: {
                ...currentValue,
                buy_button_text: val,
              },
            })
          }}
        />
        <TextField
          label={t('PostCheckoutEditor.sideBar.LabelConfig.form.decline.label')}
          placeholder={t(
            'PostCheckoutEditor.sideBar.LabelConfig.form.decline.placeholder',
          )}
          readOnly
          value={currentValue.decline_button_text}
          onChange={(val) => {
            form.fields.texts.onChange({
              ...form.fields.texts.value,
              [currentLocale]: {
                ...currentValue,
                decline_button_text: val,
              },
            })
          }}
        />
        <TextField
          label={t('PostCheckoutEditor.sideBar.LabelConfig.form.free.label')}
          placeholder={t(
            'PostCheckoutEditor.sideBar.LabelConfig.form.free.placeholder',
          )}
          readOnly
          value={currentValue.free_text}
          onChange={(val) => {
            form.fields.texts.onChange({
              ...form.fields.texts.value,
              [currentLocale]: {
                ...currentValue,
                free_text: val,
              },
            })
          }}
        />
        <TextField
          label={t(
            'PostCheckoutEditor.sideBar.LabelConfig.form.discount.label',
          )}
          placeholder={t(
            'PostCheckoutEditor.sideBar.LabelConfig.form.discount.placeholder',
          )}
          readOnly
          value={currentValue.discount_text}
          onChange={(val) => {
            form.fields.texts.onChange({
              ...form.fields.texts.value,
              [currentLocale]: {
                ...currentValue,
                discount_text: val,
              },
            })
          }}
        />
      </BlockStack>
    </div>
  )
}

function SideBarTab({ label, open, setOpen }) {
  const [hover, setHover] = useState(false)
  return (
    <div
      style={{
        padding: 'var(--p-space-100)',
      }}
    >
      <div
        onClick={() => setOpen(!open)}
        style={{
          cursor: 'pointer',
          transition: 'all 0.3s',
          borderRadius: 6,
          overflow: 'hidden',
          backgroundColor: hover ? 'var(--p-color-bg-surface-hover)' : null,
        }}
        onMouseEnter={(e) => {
          setHover(true)
        }}
        onMouseLeave={(e) => {
          setHover(false)
        }}
      >
        <Box padding={'200'}>
          <InlineGrid gap={'100'} columns={'auto 1fr'}>
            <Button
              variant="plain"
              size="slim"
              icon={open ? ChevronUpIcon : ChevronRightIcon}
            />
            <Text variant="headingXs">{label}</Text>
          </InlineGrid>
        </Box>
      </div>
    </div>
  )
}
