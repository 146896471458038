import { Box, Scrollable } from '@shopify/polaris'
import PromotionBuilderLayoutPreview from './Window'

export default function Preview({ form, randomProductId }) {
  return (
    <Scrollable height={'calc(100% - 58px)'}>
      <div
        style={{
          padding: 'var(--p-space-300)',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr',
        }}
      >
        <Box
          borderRadius="200"
          background="bg-fill-active"
          overflowX="hidden"
          overflowY="scroll"
          borderColor="border"
          borderWidth="0165"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <PromotionBuilderLayoutPreview
              activeLocale={form.fields.translations.value}
              layout={{
                data: {
                  sections: [
                    {
                      type: 'banner',
                      settings: form.fields.banner.value,
                    },
                    form.fields.promotionType.value === 'single_product'
                      ? {
                          type: 'single_product',
                          settings: getSingleProductSettings(
                            form,
                            randomProductId,
                          ),
                        }
                      : {
                          type: 'multiple_products',
                          settings: getMultipleProductsSettings(
                            form,
                            randomProductId,
                          ),
                        },
                  ],
                },
              }}
              translations={
                form.fields.texts.value[form.fields.translations.value]
              }
            />
          </div>
        </Box>
      </div>
    </Scrollable>
  )
}

function getSingleProductSettings(form, randomProductId) {
  const slot = form.fields.slots.value[0]
  const productId =
    slot.recommendation === 'manual' && slot.product
      ? parseInt(slot.product.id.replace('gid://shopify/Product/', ''))
      : randomProductId
  return {
    recommendationRules: {
      productId: productId,
      allowQuantityChange: slot.allowQuantityChange,
      defaultQuantity: 1,
      minQuantity: slot.minQuantity,
      maxQuantity: slot.maxQuantity,
    },
    recommendationType: slot.recommendation,
    discount: {
      type: slot.discount.type.toUpperCase(),
      value: slot.discount.value,
    },
    translations: slot.translations,
  }
}

function getMultipleProductsSettings(form, randomProductId) {
  let slots = form.fields.slots.value

  slots = slots.map((slot) => {
    const productId =
      slot.recommendation === 'manual' && slot.product
        ? parseInt(slot.product.id.replace('gid://shopify/Product/', ''))
        : randomProductId
    return {
      recommendationRules: {
        productId: productId,
        allowQuantityChange: slot.allowQuantityChange,
        defaultQuantity: 1,
        minQuantity: slot.minQuantity,
        maxQuantity: slot.maxQuantity,
      },
      recommendationType: slot.recommendation,
      discount: {
        type: slot.discount.type.toUpperCase(),
        value: slot.discount.value,
      },
      translations: slot.translations,
    }
  })

  return {
    slots: slots,
  }
}
