import { useQuery } from 'react-query'
import { getShopDetails } from '../apis/shop'

export function useShopDetails({
  token
} = {}) {
  const { data, isLoading, error, isRefetching } = useQuery({
    queryKey: ['shopDetails'],
    queryFn: async () => {
      const res = await getShopDetails(token)
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    enabled: Boolean(token) || Boolean(shopify.idToken),
    staleTime: 0,
    refetchOnWindowFocus: false,
  })

  return {
    data,
    isLoading,
    error,
    isRefetching,
  }
}
