import {
  Avatar,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Divider,
  Filters,
  InlineGrid,
  InlineStack,
  Layout,
  LegacyFilters,
  ResourceItem,
  ResourceList,
  Tag,
  Text,
  TextField,
} from '@shopify/polaris'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EditIcon,
} from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CollectionSelection from '../components/CollectionSelection'
import SlotsRecommendation from '../components/SlotsRecommendation'
import ObjectRecommendation from '../components/ObjectRecommendation'
import RecommendationSettingCard from '../../components/RecommendationSettingCard'
import * as WIDGETS from '../../../../constants/widgets'
import RecommendationSettingsModal from '../../../../components/RecommendationSettingModal'
import { AJAX_CART, CART, CHECKOUT, COLLECTION, ORDER_CONFIRM, PRODUCT } from '../../../../constants/page'

const PAGE_WINDOW_SIZE = 5

function Recommendation({
  recommendationOptions = [],
  onClick,
  excludedProducts = null,
  disabledProducts = null,
  whitelistedProducts = null,
  excludedTags = null,
  disabledTags = null,
  whitelistedTags = null,
  enabledUrls,
  slots,
  collections,
  objects,
  shopConfig,
  sectionId,
  sectionType,
  page
}) {
  const { t } = useTranslation()

  const [excludedProductCopy, setExcludedProductCopy] = useState(
    excludedProducts ?? [],
  )
  const [disabledProductCopy, setDisabledProductCopy] = useState(
    disabledProducts ?? [],
  )
  const [whitelistedProductCopy, setWhitelistedProductCopy] = useState(
    whitelistedProducts ?? [],
  )
  const [excludedProductQueryValue, setExcludedProductQueryValue] = useState('')
  const [disabledProductQueryValue, setDisabledProductQueryValue] = useState('')
  const [whitelistedProductQueryValue, setWhitelistedProductQueryValue] =
    useState('')
  const [openedSetting, setOpenedSetting] = useState(null)

  const [excludedPage, setExcludedPage] = useState(0)
  const [disabledPage, setDisabledPage] = useState(0)
  const [whitelistedPage, setWhitelistedPage] = useState(0)
  const [advanceConfigCollapsed, setAdvancedConfigCollapsed] = useState(true)

  useEffect(() => {
    if (excludedProducts) {
      setExcludedProductCopy(
        excludedProducts.filter(({ title }) =>
          title.toLowerCase().includes(excludedProductQueryValue.toLowerCase()),
        ),
      )
    }
  }, [excludedProductQueryValue])

  useEffect(() => {
    if (disabledProducts) {
      setDisabledProductCopy(
        disabledProducts.filter(({ title }) =>
          title.toLowerCase().includes(disabledProductQueryValue.toLowerCase()),
        ),
      )
    }
  }, [disabledProductQueryValue])

  useEffect(() => {
    if (whitelistedProducts) {
      setWhitelistedProductCopy(
        whitelistedProducts.filter(({ title }) =>
          title
            .toLowerCase()
            .includes(whitelistedProductQueryValue.toLowerCase()),
        ),
      )
    }
  }, [whitelistedProductQueryValue])

  const advancedSettingsVisible = Object.values(RecommendationsVisiblMap).map((fn)=>fn(sectionType, page)).some(Boolean)

  return (
    <Layout.AnnotatedSection
      title={t('Section.Details.Recommendation.title')}
      description={t('Section.Details.Recommendation.description')}
    >
      <BlockStack gap={'400'}>
        <Card>
          <InlineGrid columns="1fr auto">
            <div>
              <Text as="h4" variant="bodyMd">
                {t('Section.Details.Recommendation.ConfigSelection.title')}
              </Text>
              <InlineGrid columns={2} gap={'400'}>
                {recommendationOptions.includes('automaticEnabled') && (
                  <Text variant="headingMd" as="strong">
                    {t('WidgetConfig.automaticEnabled.label')}
                  </Text>
                )}
                {recommendationOptions.includes('enableRandom') && (
                  <Text variant="headingMd" as="strong">
                    {t('WidgetConfig.enableRandom.label')}
                  </Text>
                )}
                {recommendationOptions.includes('allowIfUnavailable') && (
                  <Text variant="headingMd" as="strong">
                    {t('WidgetConfig.allowIfUnavailable.label')}
                  </Text>
                )}
              </InlineGrid>
            </div>
            <div>
              <Button size="slim" icon={EditIcon} onClick={()=>{
                onClick({
                  id: 'recommendation-config'
                })
              }}>
                {t('Section.Details.Recommendation.btnText')}
              </Button>
            </div>
          </InlineGrid>
        </Card>
        {sectionType !== WIDGETS.SLOTS_WIDGET.value &&
          sectionType !== WIDGETS.PROMOTED_PRODUCTS.value &&
          sectionType !== WIDGETS.COLLECTIONS_WIDGET.value &&
          advancedSettingsVisible && (
            <Card>
              <BlockStack gap={'400'}>
                <InlineGrid columns={'1fr auto'}>
                  <div>
                    <Text as="strong" variant="headingSm">
                      {t('Section.Edit.Sections.AdvanceConfig.title')}
                    </Text>
                    <Text as="p" variant="bodyMd">
                      {t('Section.Edit.Sections.AdvanceConfig.description')}
                    </Text>
                  </div>
                  <Button
                    variant="plain"
                    icon={
                      advanceConfigCollapsed ? ChevronDownIcon : ChevronUpIcon
                    }
                    onClick={() => {
                      setAdvancedConfigCollapsed(!advanceConfigCollapsed)
                    }}
                  >
                    {t(`Button.${advanceConfigCollapsed ? 'show' : 'hide'}`, {
                      text: t('Section.Edit.Sections.AdvanceConfig.title'),
                    })}
                  </Button>
                </InlineGrid>
                <Collapsible open={!advanceConfigCollapsed}>
                  <BlockStack gap={'300'}>
                    {ALL_SETTINGS.sort((a, b) => {
                      const isFirstEnabled = RecommendationsVisiblMap[a](
                        sectionType,
                        page,
                      )
                      const isSecondEnabled = RecommendationsVisiblMap[b](
                        sectionType,
                        page,
                      )
                      if (isFirstEnabled && !isSecondEnabled) {
                        return 1
                      }
                      if (!isFirstEnabled && isSecondEnabled) {
                        return -1
                      }
                      return 0
                    }).map((setting) => (
                      <RecommendationSettingCard
                        title={t(`Recommendation.${setting}.title`)}
                        description={t(`Recommendation.${setting}.description`)}
                        onClick={() => {
                          setOpenedSetting(setting)
                        }}
                        btnText={t(`Recommendation.btnText`)}
                        disabled={
                          !RecommendationsVisiblMap[setting](sectionType, page)
                        }
                      />
                    ))}
                  </BlockStack>
                </Collapsible>
              </BlockStack>
            </Card>
          )}
        {excludedTags && excludedProducts && (
          <Card padding={'0'}>
            <div
              style={{
                padding: 'var(--p-space-400)',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Text as="strong">
                  {t('Section.Details.Recommendation.ExcludedProduct.title')}
                </Text>
                <Text as="p">
                  {t(
                    'Section.Details.Recommendation.ExcludedProduct.description',
                  )}
                </Text>
              </div>
              <Button icon={EditIcon} onClick={()=>{
                onClick({
                  id: 'recommendation-excluded'
                })
              }}>
                {t('Section.Details.Recommendation.ExcludedProduct.btnText')}
              </Button>
            </div>
            <Divider borderColor="border" />
            {excludedProducts && (
              <>
                <ResourceList
                  flushFilters
                  emptyState={<EmptyState />}
                  filterControl={
                    <Filters
                      queryPlaceholder={t(
                        'Section.Details.Recommendation.ExcludedProduct.queryPlaceholder',
                      )}
                      queryValue={excludedProductQueryValue}
                      onQueryChange={(val) => {
                        setExcludedPage(0)
                        setExcludedProductQueryValue(val)
                      }}
                      filters={[]}
                      onQueryClear={() => {
                        setExcludedPage(0)
                        setExcludedProductQueryValue('')
                      }}
                    />
                  }
                  resourceName={{ singular: 'product', plural: 'products' }}
                  items={excludedProductCopy.slice(
                    excludedPage * PAGE_WINDOW_SIZE,
                    excludedPage * PAGE_WINDOW_SIZE + PAGE_WINDOW_SIZE,
                  )}
                  renderItem={ProductCard}
                  // TODO: Fix has next logic
                  pagination={{
                    hasNext:
                      (excludedPage + 1) * PAGE_WINDOW_SIZE <
                      disabledProductCopy.length,
                    hasPrevious: excludedPage > 0,
                    onNext: () => {
                      setExcludedPage(excludedPage + 1)
                    },
                    onPrevious: () => {
                      setExcludedPage(excludedPage - 1)
                    },
                  }}
                />
                <Divider borderColor="border" />
              </>
            )}
            <div
              style={{
                paddingTop: 'var(--p-space-400)',
                paddingLeft: 'var(--p-space-400)',
                paddingRight: 'var(--p-space-400)',
              }}
            >
              <Text as="strong">
                {t('Section.Details.Recommendation.ExcludedProduct.tagsTitle')}
              </Text>
            </div>
            <div
              style={{
                padding: 'var(--p-space-400)',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--p-space-200)',
                }}
              >
                {excludedTags.length > 0 && (
                  <InlineStack gap={'200'}>
                    {excludedTags?.map((tag) => (
                      <Tag>{tag}</Tag>
                    ))}
                  </InlineStack>
                )}
                {excludedTags.length === 0 && (
                  <Text as="span">
                    {t('Section.Details.Recommendation.ExcludedProduct.noTags')}
                  </Text>
                )}
              </div>
            </div>
          </Card>
        )}
        {disabledTags && disabledProducts && (
          <Card padding={'0'}>
            <div
              style={{
                padding: 'var(--p-space-400)',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Text as="strong">
                  {t('Section.Details.Recommendation.DisabledProduct.title')}
                </Text>
                <Text as="p">
                  {t(
                    'Section.Details.Recommendation.DisabledProduct.description',
                  )}
                </Text>
              </div>
              <Button icon={EditIcon} onClick={()=>{
                onClick({
                  id: 'recommendation-disabled'
                })
              }}>
                {t('Section.Details.Recommendation.DisabledProduct.btnText')}
              </Button>
            </div>
            <Divider borderColor="border" />
            {disabledProducts && (
              <>
                <ResourceList
                  emptyState={<EmptyState />}
                  flushFilters
                  filterControl={
                    <Filters
                      queryPlaceholder={t(
                        'Section.Details.Recommendation.DisabledProduct.queryPlaceholder',
                      )}
                      queryValue={disabledProductQueryValue}
                      onQueryChange={(val) => {
                        setDisabledProductQueryValue(val)
                      }}
                      filters={[]}
                      onQueryClear={() => {
                        setDisabledProductQueryValue('')
                      }}
                    />
                  }
                  items={disabledProductCopy.slice(
                    disabledPage * PAGE_WINDOW_SIZE,
                    disabledPage * PAGE_WINDOW_SIZE + PAGE_WINDOW_SIZE,
                  )}
                  resourceName={{ singular: 'product', plural: 'products' }}
                  renderItem={ProductCard}
                  pagination={{
                    hasNext:
                      (disabledPage + 1) * PAGE_WINDOW_SIZE <
                      disabledProducts.length,
                    hasPrevious: disabledPage > 0,
                    onNext: () => {
                      setDisabledPage(disabledPage + 1)
                    },
                    onPrevious: () => {
                      setDisabledPage(disabledPage - 1)
                    },
                  }}
                />
                <Divider borderColor="border" />
              </>
            )}
            <div
              style={{
                paddingTop: 'var(--p-space-400)',
                paddingLeft: 'var(--p-space-400)',
                paddingRight: 'var(--p-space-400)',
              }}
            >
              <Text as="strong">
                {t('Section.Details.Recommendation.DisabledProduct.tagsTitle')}
              </Text>
            </div>
            <div
              style={{
                padding: 'var(--p-space-400)',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--p-space-200)',
                }}
              >
                {disabledTags.length > 0 && (
                  <InlineStack gap={'200'}>
                    {disabledTags?.map((tag) => (
                      <Tag>{tag}</Tag>
                    ))}
                  </InlineStack>
                )}
                {disabledTags.length === 0 && (
                  <Text as="span">
                    {t('Section.Details.Recommendation.DisabledProduct.noTags')}
                  </Text>
                )}
              </div>
            </div>
          </Card>
        )}
        {whitelistedTags && whitelistedProducts && (
          <Card padding={'0'}>
            <div
              style={{
                padding: 'var(--p-space-400)',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Text as="strong">
                  {t('Section.Details.Recommendation.WhitelistedProduct.title')}
                </Text>
                <Text as="p">
                  {t(
                    'Section.Details.Recommendation.WhitelistedProduct.description',
                  )}
                </Text>
              </div>
              <Button icon={EditIcon} onClick={()=>{
                onClick({
                  id: 'recommendation-whitelisted'
                })
              }}>
                {t('Section.Details.Recommendation.DisabledProduct.btnText')}
              </Button>
            </div>
            <Divider borderColor="border" />
            {whitelistedProducts && (
              <>
                <ResourceList
                  emptyState={<EmptyState />}
                  flushFilters
                  filterControl={
                    <Filters
                      queryPlaceholder={t(
                        'Section.Details.Recommendation.WhitelistedProduct.queryPlaceholder',
                      )}
                      queryValue={whitelistedProductQueryValue}
                      onQueryChange={(val) => {
                        setWhitelistedProductQueryValue(val)
                      }}
                      filters={[]}
                      onQueryClear={() => {
                        setWhitelistedProductQueryValue('')
                      }}
                    />
                  }
                  items={whitelistedProductCopy.slice(
                    disabledPage * PAGE_WINDOW_SIZE,
                    disabledPage * PAGE_WINDOW_SIZE + PAGE_WINDOW_SIZE,
                  )}
                  resourceName={{ singular: 'product', plural: 'products' }}
                  renderItem={ProductCard}
                  pagination={{
                    hasNext:
                      (whitelistedPage + 1) * PAGE_WINDOW_SIZE <
                      whitelistedPage.length,
                    hasPrevious: whitelistedPage > 0,
                    onNext: () => {
                      setWhitelistedPage(whitelistedPage + 1)
                    },
                    onPrevious: () => {
                      setWhitelistedPage(whitelistedPage - 1)
                    },
                  }}
                />
                <Divider borderColor="border" />
              </>
            )}
            <div
              style={{
                paddingTop: 'var(--p-space-400)',
                paddingLeft: 'var(--p-space-400)',
                paddingRight: 'var(--p-space-400)',
              }}
            >
              <Text as="strong">
                {t(
                  'Section.Details.Recommendation.WhitelistedProduct.tagsTitle',
                )}
              </Text>
            </div>
            <div
              style={{
                padding: 'var(--p-space-400)',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--p-space-200)',
                }}
              >
                {whitelistedTags.length > 0 && (
                  <InlineStack gap={'200'}>
                    {whitelistedTags?.map((tag) => (
                      <Tag>{tag}</Tag>
                    ))}
                  </InlineStack>
                )}
                {whitelistedTags.length === 0 && (
                  <Text as="span">
                    {t(
                      'Section.Details.Recommendation.WhitelistedProduct.noTags',
                    )}
                  </Text>
                )}
              </div>
            </div>
          </Card>
        )}
        {enabledUrls && <EnabledUrls onClick={()=>{
          onClick({
            id: 'recommendation-enabled-urls'
          })
        }} urls={enabledUrls} />}
        {slots && <SlotsRecommendation slots={slots} onClick={()=>{
          onClick({
            id: 'recommendation-slots'
          })
        }} />}
        {collections && (
          <CollectionSelection collections={collections} onClick={() => {
            onClick({
              id: 'recommendation-collection'
            })
          }} />
        )}
        {objects && (
          <ObjectRecommendation objects={objects} onClick={()=>{
            onClick({
              id: 'recommendation-object'
            })
          }} />
        )}
      </BlockStack>
      <RecommendationSettingsModal
        sectionId={sectionId}
        setting={openedSetting}
        dispatcher={setOpenedSetting}
        widgetType={sectionType}
        currency={shopConfig.currencyFormat}
      />
    </Layout.AnnotatedSection>
  )
}

export default Recommendation

const ProductCard = (item) => {
  const { title, handle, id, url, images } = item
  const imageSrc =
    images?.length > 0
      ? images[0].originalSrc
      : 'https://boltagency.ca/content/images/2020/03/placeholder-images-product-1_large.png'
  return (
    <ResourceItem
      id={id}
      url={url}
      media={<Avatar size="md" name={title} source={imageSrc} />}
      accessibilityLabel={`Remove ${title}`}
      name={title}
    >
      {title}
      <Text variant="bodyMd" fontWeight="bold" as="h3">
        {title}
      </Text>
      <div>
        ID {id.replace('gid://shopify/Product/', '')}
        {' | '}Handle {handle}
      </div>
    </ResourceItem>
  )
}

function EnabledUrls({ urls, onClick }) {
  const { t } = useTranslation()
  return (
    <Card>
      <InlineStack align="end">
        <Button icon={EditIcon} onClick={onClick}>
          {t('Section.Details.Recommendation.DisabledProduct.btnText')}
        </Button>
      </InlineStack>
      <BlockStack gap={'300'}>
        <BlockStack gap={'100'}>
          <Text as="strong">
            {t('Section.Edit.Sections.Recommendation.EnabledUrls.title')}
          </Text>
          <Text as="p">
            {t('Section.Edit.Sections.Recommendation.description')}
          </Text>
        </BlockStack>
        {urls.map((url, index) => (
          <TextField readOnly value={url} />
        ))}
      </BlockStack>
    </Card>
  )
}

function EmptyState() {
  const { t } = useTranslation()
  return (
    <InlineGrid columns={'1fr auto 1fr'}>
      <div />
      <Box padding={'400'}>
        <Text as="strong" variant="bodyXs">
          {t('noProductsSelectedText')}
        </Text>
      </Box>
      <div />
    </InlineGrid>
  )
}

const ALL_SETTINGS = [
  'manualRecommendation',
  'ruleBasedRecommendation',
  'globalRecommendation',
]

const RecommendationsVisiblMap = {
  manualRecommendation: (sectionType, pageType, accountData) =>
    [
      WIDGETS.SIMILAR_PRODUCTS.value,
      WIDGETS.CROSS_SELL.value,
      WIDGETS.BOUGHT_TOGETHER.value,
    ].includes(sectionType),
  ruleBasedRecommendation: (sectionType, pageType, accountData) =>
    [WIDGETS.BOUGHT_TOGETHER.value, WIDGETS.CROSS_SELL.value].includes(
      sectionType,
    ) &&
    [
      COLLECTION.value,
      PRODUCT.value,
      CART.value,
      ORDER_CONFIRM.value,
      AJAX_CART.value,
      CHECKOUT.value,
    ].includes(pageType),
  globalRecommendation: (sectionType, pageType, accountData) =>
    [
      WIDGETS.BOUGHT_TOGETHER.value,
      WIDGETS.SIMILAR_PRODUCTS.value,
      WIDGETS.TRENDING.value,
      WIDGETS.PURCHASES.value,
      WIDGETS.BESTSELLERS.value,
      WIDGETS.RECENTLY_LAUNCHED.value,
    ].includes(sectionType),
}