import React from 'react'
import { useQuery } from 'react-query'
import { Spinner } from '@shopify/polaris'

import { getProducts } from '../../../../../../../../../../apis/products'

export default function WithProducts({ productIds, children }) {
  const productSearchQuery = useQuery({
    queryKey: ['products', { productIds }],
    queryFn: async () => {
      const products = (await getProducts(productIds)).data.products
      return products
    },
    enabled: Boolean(productIds.length),
  })
  return (
    <React.Fragment>
      {productSearchQuery.isSuccess ? (
        children({
          products: productSearchQuery.data
        })
      ) : (
        <div className='flex w-full items-center justify-center border p-6'>
          <Spinner size='small' />
        </div>
      )}
    </React.Fragment>
  )
}
