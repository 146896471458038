import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DropZone,
  Text,
  Thumbnail,
  BlockStack,
  Box,
  InlineGrid,
  Card,
  Checkbox,
  InlineStack,
} from '@shopify/polaris'
import { NoteIcon } from '@shopify/polaris-icons'

import * as WIDGETS from '../../constants/widgets'
import CustomModal from '../CustomModal'
import { triggerBulkUpload } from '../../apis/recommendation'
import queryClient from '../../utils/query'

function BulkUploadFormModal({ show, setShow }) {
  const { t } = useTranslation()
  const [file, setFile] = useState(null)
  const [widgetType, setWidgetType] = useState(null)
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => setFile(acceptedFiles[0]),
    [],
  )
  const [loading, setLoading] = useState(false)

  const fileUpload = !file && (
    <DropZone.FileUpload
      actionTitle={t(
        'RecommendationPage.Components.BulkUpload.Modal.uploadText',
      )}
    />
  )

  const uploadedFiles = file && (
    <div
      style={{
        padding: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <BlockStack align="center" gap={'200'}>
        <InlineGrid columns={'1fr auto 1fr'}>
          <div />
          <Thumbnail size="small" alt={file.name} source={NoteIcon} />
          <div />
        </InlineGrid>
        <BlockStack align="center">
          <Text as="strong">{file.name}</Text>
          <Text variant="bodySm" as="p">
            {file.size} bytes
          </Text>
        </BlockStack>
      </BlockStack>
    </div>
  )

  const uploadHandler = useCallback(async () => {
    const form = new FormData()
    form.append('file', file)
    form.set('recommendationType', widgetType)
    setLoading(true)
    const res = await triggerBulkUpload(form)
    setLoading(false)
    if (res.error) {
      shopify.toast.show(
        t('RecommendationPage.Components.BulkUpload.Modal.errorText'),
        {
          isError: true,
        },
      )
      return
    }
    shopify.toast.show(
      t('RecommendationPage.Components.BulkUpload.Modal.successText'),
    )
    await queryClient.refetchQueries(['bulkUpload'])
    setShow(false)
  }, [file, widgetType])

  useEffect(() => {
    if (!show) {
      setFile(null)
      setLoading(false)
      setWidgetType(null)
    }
  }, [show])

  return (
    <CustomModal
      maxHeight={'100%'}
      maxWidth={'700px'}
      title={t('RecommendationPage.Components.BulkUpload.Modal.title')}
      show={show}
      setShow={setShow}
      variant="medium"
      onSave={uploadHandler}
      onDiscard={() => {
        setShow(false)
      }}
      saveButtonProps={{
        loading,
      }}
    >
      <Box padding={'400'}>
        <BlockStack gap={'300'}>
          <InlineGrid columns={'1fr'} gap={'300'}>
            {['bought_together', 'similar_products', 'cross_sell'].map(
              (widget) => (
                <div
                  onClick={() => {
                    setWidgetType(widget)
                  }}
                  style={{
                    cursor: 'pointer',
                    height: '100%',
                    backgroundColor: 'var(--p-color-bg-fill)',
                    borderRadius: 'var(--p-border-radius-300)',
                    border: 'solid 0.5px var(--p-color-bg-inverse)',
                    boxShadow: 'var(--p-shadow-100)',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 'var(--p-space-button-group-gap)',
                        right: 'var(--p-space-button-group-gap)',
                        zIndex: 1000,
                      }}
                    >
                      <Checkbox
                        checked={widget === widgetType}
                        onChange={(val) => {
                          
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: 'var(--p-space-card-padding)',
                        backgroundColor: 'var(--p-color-bg-surface)',
                        height: '100%',
                      }}
                    >
                      <InlineStack gap={'300'} blockAlign="start" wrap={false}>
                        <img
                          width={20}
                          height={20}
                          src={
                            Object.values(WIDGETS).find(
                              ({ value }) => value === widget,
                            ).icon
                          }
                        />
                        <Text as="strong">{t(`Widgets.${widget}.label`)}</Text>
                      </InlineStack>
                      <Text as="p">{t(`Widgets.${widget}.description`)}</Text>
                    </div>
                  </div>
                </div>
              ),
            )}
          </InlineGrid>
          <DropZone
            disabled={!widgetType}
            allowMultiple={false}
            type="file"
            onDrop={handleDropZoneDrop}
            accept='.csv'
          >
            {uploadedFiles}
            {fileUpload}
          </DropZone>
        </BlockStack>
      </Box>
    </CustomModal>
  )
}

export default BulkUploadFormModal
