import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomModal from '../CustomModal'
import { BlockStack, Button, Divider, InlineGrid, Text } from '@shopify/polaris'
import { updateShopConfig } from '../../apis/dashbboard'
import queryClient from '../../utils/query'

function ProductTour({ initiallyOpen = false, onClose }) {
  const open = initiallyOpen
  const [activeIndex, setActiveIndex] = useState(0)
  const { t } = useTranslation()
  const steps = getSteps(t)
  const activeStep = steps[activeIndex]

  const completeOnboardingHandler = async () => {
    updateShopConfig({
      onboarding: {
        showTutorial: true,
        initInfoDone: true,
      },
    })
    await queryClient.invalidateQueries(['getDashboardDetails',window.shopify.data.shopPlan])
    onClose()
  }

  const closeHandler = async (val) => {
    if (confirm('Are you sure you want to close the product tour?')) {
      updateShopConfig({
        onboarding: {
          showTutorial: true,
          initInfoDone: true,
        },
      })
      await queryClient.invalidateQueries(['getDashboardDetails',window.shopify.data.shopPlan])
      onClose()
    }
  }

  return (
    <CustomModal
      variant="large"
      show={open}
      setShow={closeHandler}
      title={t('ProductTour.title')}
      overlayZIndex={9999}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'max-content',
          padding: 'var(--p-space-400)',
          gap: 'var(--p-space-300)',
          zIndex: 1000,
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <div
            style={{
              border:
                'var(--p-border-width-025) solid var(--p-color-border-secondary)',
              height: '100%',
              width: '100%',
              borderRadius: 'var(--p-border-radius-300)',
              display: 'flex',
              flexDirection: 'column',
              gap: 'var(--p-space-100)',
            }}
          >
            <div
              style={{
                padding: 'var(--p-space-400)',
                height: '60vh',
              }}
            >
              {activeStep.type === 'image' ? (
                <img
                  alt=""
                  width="100%"
                  height={'100%'}
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  src={activeStep.src}
                />
              ) : (
                <VideoFrame />
              )}
            </div>
            <Divider />
            <div
              style={{
                padding:
                  'var(--p-space-200) var(--p-space-200) var(--p-space-200) var(--p-space-200)',
              }}
            >
              <BlockStack gap={'100'}>
                <Text as="h3" variant="headingMd">
                  {activeStep.title}
                </Text>
                <Text as="p">{activeStep.description}</Text>
              </BlockStack>
            </div>
          </div>
        </div>
        <InlineGrid columns={'auto 1fr auto'} alignItems="center">
          <Button
            onClick={() => {
              setActiveIndex(activeIndex - 1)
            }}
            disabled={activeIndex === 0}
          >
            {t('ProductTour.previousCTA')}
          </Button>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 'var(--p-space-300)',
            }}
          >
            {steps.map((_, index) => (
              <span
                key={index}
                style={{
                  display: 'inline-block',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor:
                    activeIndex >= index
                      ? 'var(--p-color-bg-inverse)'
                      : 'var(--p-color-bg-surface-selected)',
                  transition:
                    'all var(--p-motion-duration-100) var(--p-motion-ease)',
                }}
              />
            ))}
          </div>
          <Button
            onClick={() => {
              if (activeIndex === steps.length - 1) {
                completeOnboardingHandler()
              } else {
                setActiveIndex(activeIndex + 1)
              }
            }}
            variant="primary"
          >
            {activeIndex === steps.length - 1
              ? t('ProductTour.completeCTA')
              : t('ProductTour.nextCTA')}
          </Button>
        </InlineGrid>
      </div>
    </CustomModal>
  )
}

export default ProductTour

// type can be 'image' or 'video'
const getSteps = (t) => [
  {
    title: t('ProductTour.steps.step1.title'),
    description: t('ProductTour.steps.step1.description'),
    type: 'image',
    src: 'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-lifestyle-2_large.png?v=1530129263',
  },
  {
    title: t('ProductTour.steps.step1.title'),
    description: t('ProductTour.steps.step1.description'),
    type: 'image',
    src: 'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-lifestyle-2_large.png?v=1530129263',
  },
  {
    title: t('ProductTour.steps.step1.title'),
    description: t('ProductTour.steps.step1.description'),
    type: 'image',
    src: 'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-lifestyle-2_large.png?v=1530129263',
  },
  {
    title: t('ProductTour.steps.step1.title'),
    description: t('ProductTour.steps.step1.description'),
    type: 'image',
    src: 'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-lifestyle-2_large.png?v=1530129263',
  },
  {
    title: t('ProductTour.steps.step1.title'),
    description: t('ProductTour.steps.step1.description'),
    type: 'image',
    src: 'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-lifestyle-2_large.png?v=1530129263',
  },
]
