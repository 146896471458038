import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Collapsible,
  Divider,
  InlineGrid,
  Layout,
  Link,
  Text,
  useBreakpoints,
} from '@shopify/polaris'
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ProductTagList from '../../../../components/ProductsList'
import RecommendationSettingsModal from '../../../../components/RecommendationSettingModal'
import {
  AJAX_CART,
  BLOG,
  CART,
  CHECKOUT,
  COLLECTION,
  ORDER_CONFIRM,
  OTHER,
  PRODUCT,
} from '../../../../constants/page'
import * as WIDGETS from '../../../../constants/widgets'
import CollectionSelection from '../components/CollectionSelection'
import EnabledUrls from '../components/EnabledUrls'
import ObjectRecommendation from '../components/ObjectRecommendation'
import RecommendationSettingCard from '../../components/RecommendationSettingCard'
import SlotsRecommendation from '../components/SlotsRecommendation'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'

export default function Recommendation({
  form,
  sectionId,
  dashboardData,
  pricing,
}) {
  const { t } = useTranslation()

  const [openedSetting, setOpenedSetting] = useState(null)
  const [advanceConfigCollapsed, setAdvancedConfigCollapsed] = useState(true)

  const [disabledTags, setDisabledTags] = [
    form.fields.disabledTags.value,
    form.fields.disabledTags.onChange,
  ]

  const [excludedTags, setExcludedTags] = [
    form.fields.excludedTags.value,
    form.fields.excludedTags.onChange,
  ]

  const [whitelistedTags, setWhitelistedTags] = [
    form.fields.whitelistedTags.value,
    form.fields.whitelistedTags.onChange,
  ]

  const [disabledProducts, setDisabledProducts] = [
    form.fields.disabledProducts.value,
    (value) => {
      form.fields.disabledProducts.onChange(value ?? [])
    },
  ]

  const [excludedProducts, setExcludedProducts] = [
    form.fields.excludedProducts.value,
    (value) => {
      form.fields.excludedProducts.onChange(value ?? [])
    },
  ]

  const [whitelistedProducts, setWhitelistedProducts] = [
    form.fields.whitelistedProducts.value,
    (value) => {
      form.fields.whitelistedProducts.onChange(value ?? [])
    },
  ]

  const supportedSettings = Object.values(WIDGETS).find(
    ({ value }) => value === form.fields.widgetType.value,
  ).supportedSettings

  const widgetType = form.fields.widgetType.value
  const page = form.fields.page.value

  const recommendationOptions = (() => {
    const arr = []
    if (supportedSettings.includes('automaticEnabled')) {
      arr.push({
        field: form.fields.automaticEnabled,
        slug: 'automaticEnabled',
        title: t('WidgetConfig.automaticEnabled.label'),
        description: t('WidgetConfig.automaticEnabled.description'),
      })
    }
    if (supportedSettings.includes('enableRandom')) {
      arr.push({
        field: form.fields.enableRandom,
        slug: 'enableRandom',
        title: t('WidgetConfig.enableRandom.label'),
        description: t('WidgetConfig.enableRandom.description'),
      })
    }
    if (supportedSettings.includes('allowIfUnavailable')) {
      arr.push({
        field: form.fields.allowIfUnavailable,
        slug: 'allowIfUnavailable',
        title: t('WidgetConfig.allowIfUnavailable.label'),
        description: t('WidgetConfig.allowIfUnavailable.description'),
      })
    }
    return arr
  })()

  const advancedSettingsVisible = Object.values(RecommendationsVisiblMap)
    .map((fn) => fn(widgetType, page))
    .some(Boolean)

  return (
    <>
      <Layout.AnnotatedSection
        id="recommendation"
        variant="oneThird"
        title={t('Section.Edit.Sections.Recommendation.title')}
        description={t('Section.Edit.Sections.Recommendation.description')}
      >
        <BlockStack gap={'600'}>
          <Card padding={'0'}>
            <BlockStack>
              <Box padding={'400'} id="recommendation-config">
                <BlockStack gap="200">
                  <div>
                    <Text as="strong" variant="headingSm">
                      {t(
                        'Section.Edit.Sections.Recommendation.ConfigSelection.title',
                      )}
                    </Text>
                    <Text as="p" variant="bodyMd">
                      {t(
                        'Section.Edit.Sections.Recommendation.ConfigSelection.description',
                      )}
                    </Text>
                  </div>
                  <InlineGrid columns={'1fr'} gap={'200'}>
                    {recommendationOptions.map(
                      ({ title, description, slug, field }) => (
                        <div
                          style={{
                            cursor: 'pointer',
                            height: '100%',
                            borderRadius: 'var(--p-border-radius-300)',
                            border:
                              'var(--p-border-width-025) solid var(--p-color-border)',
                            overflow: 'hidden',
                            boxShadow: 'var(--p-shadow-200)',
                          }}
                          onClick={() => {
                            field.onChange(!field?.checked)
                          }}
                        >
                          <div
                            key={slug}
                            style={{
                              backgroundColor: 'var(--p-bg-fill)',
                              height: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                              }}
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 'var(--p-space-button-group-gap)',
                                  right: 'var(--p-space-button-group-gap)',
                                  zIndex: 1000,
                                }}
                              >
                                <Checkbox {...field} checked={field?.checked} />
                              </div>
                              <div
                                style={{
                                  padding: 'var(--p-space-card-padding)',
                                  backgroundColor: 'var(--p-color-bg-surface)',
                                }}
                              >
                                <Text as="strong">{title}</Text>
                                <Text as="p">{description}</Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    )}
                  </InlineGrid>
                </BlockStack>
              </Box>
              <Divider />
              {widgetType !== WIDGETS.SLOTS_WIDGET.value &&
                widgetType !== WIDGETS.PROMOTED_PRODUCTS.value &&
                widgetType !== WIDGETS.COLLECTIONS_WIDGET.value &&
                advancedSettingsVisible && (
                  <Box padding={'400'}>
                    <BlockStack gap={'400'}>
                      <InlineGrid columns={'1fr auto'}>
                        <div>
                          <Text as="strong" variant="headingSm">
                            {t('Section.Edit.Sections.AdvanceConfig.title')}
                          </Text>
                          <Text as="p" variant="bodyMd">
                            {t(
                              'Section.Edit.Sections.AdvanceConfig.description',
                            )}
                          </Text>
                        </div>
                        <Button
                          variant="plain"
                          icon={
                            advanceConfigCollapsed
                              ? ChevronDownIcon
                              : ChevronUpIcon
                          }
                          onClick={() => {
                            setAdvancedConfigCollapsed(!advanceConfigCollapsed)
                          }}
                        >
                          {t(
                            `Button.${advanceConfigCollapsed ? 'show' : 'hide'}`,
                            {
                              text: t(
                                'Section.Edit.Sections.AdvanceConfig.title',
                              ),
                            },
                          )}
                        </Button>
                      </InlineGrid>
                      <Collapsible open={!advanceConfigCollapsed}>
                        <BlockStack gap={'300'}>
                          {ALL_SETTINGS.sort((a, b) => {
                            const isFirstEnabled = RecommendationsVisiblMap[a](
                              widgetType,
                              page,
                            )
                            const isSecondEnabled = RecommendationsVisiblMap[b](
                              widgetType,
                              page,
                            )
                            if (isFirstEnabled && !isSecondEnabled) {
                              return 1
                            }
                            if (!isFirstEnabled && isSecondEnabled) {
                              return -1
                            }
                            return 0
                          }).map((setting) => {
                            const upgradeRequired =
                              setting === 'ruleBasedRecommendation' &&
                              !areFeaturesEnabled(
                                [FEATURES.RECOMMENDATION_RULES],
                                window.shopify.data,
                              )
                            return (
                              <RecommendationSettingCard
                                title={t(`Recommendation.${setting}.title`)}
                                description={t(
                                  `Recommendation.${setting}.description`,
                                )}
                                onClick={() => {
                                  if (upgradeRequired) {
                                    pricing.open({
                                      features: [FEATURES.RECOMMENDATION_RULES],
                                    })
                                    return
                                  }
                                  setOpenedSetting(setting)
                                }}
                                btnText={t(`Recommendation.btnText`)}
                                disabled={
                                  !RecommendationsVisiblMap[setting](
                                    widgetType,
                                    page,
                                  )
                                }
                                upgradeRequired={upgradeRequired}
                              />
                            )
                          })}
                        </BlockStack>
                      </Collapsible>
                    </BlockStack>
                  </Box>
                )}
            </BlockStack>
          </Card>
          {[
            WIDGETS.PERSONALIZED.value,
            WIDGETS.SIMILAR_PRODUCTS.value,
            WIDGETS.BOUGHT_TOGETHER.value,
            WIDGETS.CROSS_SELL.value,
          ].includes(widgetType) && (
            <>
              <ProductTagList
                id={'recommendation-excluded'}
                helpText={t(
                  'Section.Edit.Sections.Recommendation.ExcludedProduct.helpText',
                  {
                    link: (
                      <Link
                        monochrome
                        url="/recommendation?recSection=excluded-products"
                      >
                        this
                      </Link>
                    ),
                  },
                )}
                productSelectionTitle={t(
                  'Section.Edit.Sections.Recommendation.ExcludedProduct.title',
                )}
                productSelectionButtonCta={t(
                  'Section.Edit.Sections.Recommendation.ExcludedProduct.addProductCTA',
                )}
                productSelectionDescription={t(
                  'Section.Edit.Sections.Recommendation.ExcludedProduct.description',
                )}
                products={excludedProducts}
                onProductSelectionChange={setExcludedProducts}
                tagsTitle={t(
                  'Section.Edit.Sections.Recommendation.ExcludedProduct.tagsTitle',
                )}
                tagsDescription={t(
                  'Section.Edit.Sections.Recommendation.ExcludedProduct.tagsDescription',
                )}
                tags={excludedTags}
                onTagChange={setExcludedTags}
                queryPlaceholder={t(
                  'Section.Edit.Sections.Recommendation.ExcludedProduct.tagQueryPlaceholder',
                )}
                tagBtnCta={t(
                  'Section.Edit.Sections.Recommendation.ExcludedProduct.addTagBtnText',
                )}
              />
              {page === PRODUCT.value && (
                <>
                  <ProductTagList
                    id={'recommendation-disabled'}
                    helpText={t(
                      'Section.Edit.Sections.Recommendation.DisabledProduct.helpText',
                      {
                        link: (
                          <Link
                            monochrome
                            url="/recommendation?recSection=disabled-products"
                          >
                            this
                          </Link>
                        ),
                      },
                    )}
                    productSelectionTitle={t(
                      'Section.Edit.Sections.Recommendation.DisabledProduct.title',
                    )}
                    productSelectionButtonCta={t(
                      'Section.Edit.Sections.Recommendation.DisabledProduct.addProductCTA',
                    )}
                    productSelectionDescription={t(
                      'Section.Edit.Sections.Recommendation.DisabledProduct.description',
                    )}
                    products={disabledProducts}
                    onProductSelectionChange={setDisabledProducts}
                    tagsTitle={t(
                      'Section.Edit.Sections.Recommendation.DisabledProduct.tagsTitle',
                    )}
                    tagsDescription={t(
                      'Section.Edit.Sections.Recommendation.DisabledProduct.tagsDescription',
                    )}
                    tags={disabledTags}
                    onTagChange={setDisabledTags}
                    queryPlaceholder={t(
                      'Section.Edit.Sections.Recommendation.DisabledProduct.tagQueryPlaceholder',
                    )}
                    tagBtnCta={t(
                      'Section.Edit.Sections.Recommendation.DisabledProduct.addTagBtnText',
                    )}
                  />
                  <ProductTagList
                    id="recommendation-whitelisted"
                    productSelectionTitle={t(
                      'Section.Edit.Sections.Recommendation.WhitelistedProduct.title',
                    )}
                    productSelectionButtonCta={t(
                      'Section.Edit.Sections.Recommendation.WhitelistedProduct.addProductCTA',
                    )}
                    productSelectionDescription={t(
                      'Section.Edit.Sections.Recommendation.WhitelistedProduct.description',
                    )}
                    products={whitelistedProducts}
                    onProductSelectionChange={setWhitelistedProducts}
                    tagsTitle={t(
                      'Section.Edit.Sections.Recommendation.WhitelistedProduct.tagsTitle',
                    )}
                    tagsDescription={t(
                      'Section.Edit.Sections.Recommendation.WhitelistedProduct.tagsDescription',
                    )}
                    tags={whitelistedTags}
                    onTagChange={setWhitelistedTags}
                    queryPlaceholder={t(
                      'Section.Edit.Sections.Recommendation.WhitelistedProduct.tagQueryPlaceholder',
                    )}
                    tagBtnCta={t(
                      'Section.Edit.Sections.Recommendation.WhitelistedProduct.addTagBtnText',
                    )}
                  />
                </>
              )}
            </>
          )}
          <EnabledUrls
            page={page}
            widgetType={widgetType}
            errors={form.fields.enabledUrls?.error}
            urls={form.fields.enabledUrls?.value}
            onChange={form.fields.enabledUrls?.onChange}
          />
          {widgetType === WIDGETS.COLLECTIONS_WIDGET.value && (
            <CollectionSelection
              collections={form.fields.collections.value}
              onChange={form.fields.collections.onChange}
            />
          )}
          {widgetType === WIDGETS.SLOTS_WIDGET.value && (
            <SlotsRecommendation
              slots={form.fields.slots.value}
              onChange={form.fields.slots.onChange}
            />
          )}
          {widgetType === WIDGETS.PROMOTED_PRODUCTS.value && (
            <ObjectRecommendation
              objects={form.fields.objects.value}
              setObjects={form.fields.objects.onChange}
              error={form.fields.objects.error}
              setError={form.fields.objects.setError}
            />
          )}
        </BlockStack>
      </Layout.AnnotatedSection>
      <RecModal
        sectionId={sectionId}
        setting={openedSetting}
        dispatcher={setOpenedSetting}
        widgetType={widgetType}
        currency={dashboardData?.config?.currencyFormat}
      />
    </>
  )
}

const RecModal = memo(function (props) {
  return <RecommendationSettingsModal {...props} />
})

const ALL_SETTINGS = [
  'manualRecommendation',
  'ruleBasedRecommendation',
  'globalRecommendation',
]

const RecommendationsVisiblMap = {
  manualRecommendation: (widgetType, pageType, accountData) =>
    [
      WIDGETS.SIMILAR_PRODUCTS.value,
      WIDGETS.CROSS_SELL.value,
      WIDGETS.BOUGHT_TOGETHER.value,
    ].includes(widgetType),
  ruleBasedRecommendation: (widgetType, pageType, accountData) =>
    [WIDGETS.BOUGHT_TOGETHER.value, WIDGETS.CROSS_SELL.value].includes(
      widgetType,
    ) &&
    [
      COLLECTION.value,
      PRODUCT.value,
      CART.value,
      ORDER_CONFIRM.value,
      AJAX_CART.value,
      CHECKOUT.value,
    ].includes(pageType),
  globalRecommendation: (widgetType, pageType, accountData) =>
    [
      WIDGETS.BOUGHT_TOGETHER.value,
      WIDGETS.SIMILAR_PRODUCTS.value,
      WIDGETS.TRENDING.value,
      WIDGETS.PURCHASES.value,
      WIDGETS.BESTSELLERS.value,
      WIDGETS.RECENTLY_LAUNCHED.value,
    ].includes(widgetType),
}
