import humps from 'humps'
import getApiClient from '.'

export async function getOrders(data) {
  const client = await getApiClient()

  try {
    const res = await client.post('/api/attribution', data)
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data.message || e.message,
      data: null,
    }
  }
}

export async function downloadAttributions(data) {
  try {
    const client = await getApiClient()
    const response = await client.post('/api/attribution/download', data, {
      responseType: 'blob',
    })

    return {
      data: response.data,
      error: null,
    }
  } catch (e) {
    return {
      error: e.response.data.message || e.message,
      data: null,
    }
  }
}

export async function getStats(data) {
  try {
    const client = await getApiClient()
    const dateGte = new Date(data.dateGte * 1000 + 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0]
    const dateLte = new Date(data.dateLte * 1000 + 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0]
    const response = await client.post('/api/analytics/stats', {
      ...data,
      dateGte,
      dateLte,
    })
    return {
      data: response.data,
      error: null,
    }
  } catch (e) {
    return {
      error: e.response.data.message || e.message,
      data: null,
    }
  }
}

export async function getCharts(data) {
  try {
    const client = await getApiClient()
    const dateGte = new Date(data.dateGte + 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0]
    const dateLte = new Date(data.dateLte + 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0]
    const response = await client.post('/api/analytics/charts', {
      ...data,
      dateGte,
      dateLte,
    })
    return {
      data: response.data,
      error: null,
    }
  } catch (e) {
    return {
      error: e.response.data.message || e.message,
      data: null,
    }
  }
}

export async function getSectionsStats(data) {
  try {
    const client = await getApiClient()
    const response = await client.post('/api/analytics/stats/sections', data)
    return {
      data: response.data,
      error: null,
    }
  } catch (e) {
    return {
      error: e.response.data.message || e.message,
      data: null,
    }
  }
}

export async function getRecommendationStats(data) {
  try {
    const client = await getApiClient()
    const response = await client.post(
      '/api/analytics/stats/recommendations',
      data,
    )
    return {
      data: response.data,
      error: null,
    }
  } catch (e) {
    return {
      error: e.response.data.message || e.message,
      data: null,
    }
  }
}

export async function getDashboardStats(data) {
  try {
    const client = await getApiClient()

    const response = await client.post('/api/analytics/stats/dashboard', data)
    return {
      data: humps.camelizeKeys(response.data),
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e,
    }
  }
}

export async function getCheckoutAnalytics(data) {
  try {
    const client = await getApiClient()
    const response = await client.post('/api/analytics/stats/checkout', data)
    return {
      data: humps.camelizeKeys(response.data),
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e,
    }
  }
}

export async function getCheckoutCampaignStats(data) {
  try {
    const client = await getApiClient()
    const response = await client.post('/api/analytics/stats/checkout/campaigns', data)
    return {
      data: humps.camelizeKeys(response.data),
      error: null,
    }
  } catch (e) {
    return {
      data: null,
      error: e,
    }
  }
}
