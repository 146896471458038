import {
  Banner,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  InlineError,
  InlineGrid,
  Select,
  Text,
  TextField,
} from '@shopify/polaris'
import React, { Fragment, useEffect } from 'react'
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import {
  CAMPAIGN_CONDITION_CARD_TYPE,
  CAMPAIGN_CONDITION_TYPE,
  CAMPAIGN_TRIGGER_CONDITION_OPTIONS,
} from '../../../../../../../../../../constants/checkout'
import { getDefaultValues } from '../../utils'

const MAX_PRODS = 10,
  MAX_NESTING = 2
export default function TriggerConditionCard({
  condition,
  onChange,
  deleteCondition,
  dashboardData,
  error,
  onGroupAdd,
  onConditionAdd,
  isPartOfGroup = false,
  deleteGroup,
  nesting,
}) {
  const { t } = useTranslation()
  const Wrapper = isPartOfGroup ? Box : Card

  if (condition.type === CAMPAIGN_CONDITION_CARD_TYPE.GROUP) {
    return (
      <Box padding={isPartOfGroup ? '300' : '0'}>
        <InlineGrid columns={!isPartOfGroup ? '1fr' : 'auto 1fr'}>
          {isPartOfGroup && <Box padding={'300'} />}
          <Box borderRadius="300" borderColor="border" borderWidth="0165">
            <Box padding={'300'}>
              <InlineGrid gap={'300'} columns={' 1fr auto'} alignItems="center">
                <Text as="strong">Group</Text>
                <ButtonGroup>
                  <Select
                    label={t(
                      'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.condtion',
                    )}
                    labelInline
                    options={Object.values(CAMPAIGN_CONDITION_TYPE).map(
                      (val) => ({
                        label: t(
                          `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.conditionType.${val}`,
                        ),
                        value: val,
                      }),
                    )}
                    value={condition.operator}
                    onChange={(val) => {
                      onChange({
                        ...condition,
                        operator: val,
                      })
                    }}
                  />
                  <Button
                    onClick={() => {
                      onConditionAdd(condition.id)
                    }}
                    icon={PlusIcon}
                  >
                    Condition
                  </Button>
                  {nesting < MAX_NESTING && (
                    <Button
                      onClick={() => {
                        onGroupAdd(condition.id)
                      }}
                      icon={PlusIcon}
                    >
                      Group
                    </Button>
                  )}
                  {isPartOfGroup && (
                    <Button
                      icon={DeleteIcon}
                      onClick={() => {
                        deleteGroup(condition)
                      }}
                      size="slim"
                    />
                  )}
                </ButtonGroup>
              </InlineGrid>
            </Box>
            <Divider />
            <BlockStack>
              {error?.[condition.id] && (
                <Box padding={'300'}>
                  <Banner tone="critical">{error[condition.id]}</Banner>
                </Box>
              )}
              {condition.conditions.map((group, index) => (
                <Fragment key={index}>
                  <TriggerConditionCard
                    condition={group}
                    onChange={onChange}
                    deleteCondition={deleteCondition}
                    dashboardData={dashboardData}
                    onGroupAdd={onGroupAdd}
                    onConditionAdd={onConditionAdd}
                    deleteGroup={deleteGroup}
                    isPartOfGroup
                    nesting={nesting + 1}
                    error={error}
                  />
                  {index < condition.conditions.length - 1 && <Divider />}
                </Fragment>
              ))}
              {condition.conditions.length === 0 && (
                <Box padding={'300'}>
                  <Text tone="subdued">No conditions added</Text>
                </Box>
              )}
            </BlockStack>
          </Box>
        </InlineGrid>
      </Box>
    )
  }

  return (
    <Wrapper padding={'400'}>
      <BlockStack gap={'300'}>
        <InlineGrid columns={'1fr auto'} alignItems="start">
          <Box>
            <BlockStack gap={'300'}>
              <InlineGrid columns={'auto 1fr auto'} alignItems="center">
                <div
                  style={{
                    display: 'flex',
                    gap: 'var(--p-space-200)',
                    alignItems: 'center',
                  }}
                >
                  <Text as="strong" variant="headingXs">
                    {t(
                      'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.if',
                    )}
                  </Text>
                  <Text as="span" variant="bodyXs">
                    {t(
                      'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.subtitle',
                    )}
                  </Text>
                </div>
                <div />
                <Button
                  icon={DeleteIcon}
                  onClick={() => {
                    deleteCondition(condition)
                  }}
                  size="slim"
                />
              </InlineGrid>
              <ConditionSelector
                condition={condition}
                dashboardData={dashboardData}
                onChange={onChange}
              />
            </BlockStack>
          </Box>
        </InlineGrid>
        {error?.[condition.id] && (
          <Banner tone="critical">
            <Text>{error[condition.id]}</Text>
          </Banner>
        )}
      </BlockStack>
    </Wrapper>
  )
}

function ConditionSelector({ condition, onChange, dashboardData }) {
  const { t } = useTranslation()
  const subType = condition.operator
  const selector = (
    <div
      style={{
        maxWidth: '120px',
      }}
    >
      <Select
        options={Object.values(CAMPAIGN_TRIGGER_CONDITION_OPTIONS).map(
          (option) => ({
            label: t(
              `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.${option}.title`,
            ),
            value: option,
          }),
        )}
        value={condition.attribute}
        onChange={(val) => {
          const copy = { ...condition }
          copy.attribute = val
          onChange(refreshObject(copy))
        }}
      />
    </div>
  )

  const subTypeSelector = (
    <Select
      options={Object.keys(schema[condition.attribute]).map((key) => ({
        label: t(
          `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.${condition.attribute}.${key}.label`,
        ),
        value: key,
      }))}
      value={subType}
      onChange={(val) => {
        const copy = { ...condition }
        copy.operator = val
        onChange(copy)
      }}
    />
  )

  return (
    <BlockStack>
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: 'var(--p-space-200)',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {selector}
        <Text>
          {t(
            'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.in',
          )}
        </Text>
        {subTypeSelector}
        <TriggerAction
          condition={condition}
          dashboardData={dashboardData}
          onChange={onChange}
        />
      </div>
    </BlockStack>
  )
}

function TriggerAction({ condition, dashboardData, onChange }) {
  const { t } = useTranslation()

  const { actionSchema, type, subType } = getSchema(
    condition.attribute,
    condition.operator,
  )

  const handleSelectorOnChange = (val) => {
    const copy = { ...condition }
    copy.subOperator = val
    onChange(copy)
  }

  const selector = actionSchema?.options ? (
    <Select
      options={actionSchema?.options.map((op) => ({
        value: op,
        label: t(
          `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.${type}.${subType}.options.${op}`,
        ),
      }))}
      value={condition.subOperator}
      onChange={handleSelectorOnChange}
    />
  ) : null

  const productSelect = (
    <Button
      size="large"
      onClick={async () => {
        try {
          const selected = await shopify.resourcePicker({
            type: 'product',
            multiple: MAX_PRODS,
            selectionIds: condition.values.map((id) => ({
              id: 'gid://shopify/Product/' + id,
            })),
            filter: {
              variants: false,
              archived: false,
            },
          })
          if (selected) {
            const productIds = selected.map((item) =>
              parseInt(item.id.replace('gid://shopify/Product/', '')),
            )
            onChange({
              ...condition,
              values: productIds,
            })
          }
        } catch (e) {
          window.shopify.toast.show(t('noProductUpdate'), {
            isError: true,
          })
        }
      }}
    >
      {t(
        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.products.select',
        {
          count: condition?.values?.length,
        },
      )}
    </Button>
  )

  const collectionSelect = (
    <Button
      size="large"
      onClick={async () => {
        try {
          const selected = await shopify.resourcePicker({
            type: 'collection',
            multiple: MAX_PRODS,
            selectionIds: condition.values.map((id) => ({
              id: 'gid://shopify/Collection/' + id,
            })),
          })
          if (selected) {
            onChange({
              ...condition,
              values: selected.map((item) =>
                parseInt(item.id.replace('gid://shopify/Collection/', '')),
              ),
            })
          }
        } catch (e) {
          window.shopify.toast.show(t('noProductUpdate'), {
            isError: true,
          })
        }
      }}
    >
      {t(
        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.products.collection.select',
        {
          count: condition?.values?.length,
        },
      )}
    </Button>
  )

  const getInputValue = () => {
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS) {
      return condition.quantity.toString()
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.CART) {
      return condition.total.toString()
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.DISCOUNT) {
      return condition.discount.toString()
    }
  }

  const handleInputChange = (val) => {
    let number = 0
    if (val) {
      number = parseInt(val)
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS) {
      onChange({
        ...condition,
        quantity: number,
      })
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.CART) {
      onChange({
        ...condition,
        total: number,
      })
    }
    if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.DISCOUNT) {
      onChange({
        ...condition,
        discount: number,
      })
    }
  }

  const input = (
    <TextField
      prefix={
        actionSchema?.prefix ? actionSchema.prefix(dashboardData) : undefined
      }
      value={getInputValue()}
      onChange={handleInputChange}
      max={actionSchema.max}
      min={actionSchema.min}
    />
  )

  const localisedText = t(
    `CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.${type}.${subType}.text`,
    {
      selector,
      productSelect,
      collectionSelect,
      input,
    },
  )

  return (
    <div
      style={{
        display: 'flex',
        gap: 'var(--p-space-200)',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {localisedText}
    </div>
  )
}

function getSchema(type, subType) {
  let res = schema[type][subType]

  if (!res) {
    subType = Object.keys(schema[type])[0]
    res = schema[type][subType]
  }

  return {
    actionSchema: res,
    subType,
    type,
  }
}

const schema = {
  products: {
    specific_products: {
      type: 'product',
      max: 5,
      options: ['atleast', 'excluded'],
    },
    collection: {
      type: 'collection',
      max: 5,
      options: ['atleast', 'excluded'],
    },
    quantity: {
      type: 'number',
      max: 20,
      options: ['greater', 'lesser'],
    },
  },
  cart: {
    subtotal: {
      type: 'number',
      options: ['greater', 'lesser'],
      max: 999999,
      prefix: (dashboardResponse) => dashboardResponse.shop.currency,
      bannerText: (t, dashboardResponse) => {
        return t(
          'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.cart.subtotal.bannerText',
          {
            currency: dashboardResponse.shop.currency,
          },
        )
      },
    },
    quantity: {
      type: 'number',
      max: 20,
      min: 1,
      options: ['greater', 'lesser'],
    },
    // subscription: {
    //   label: (t) =>
    //     t(
    //       'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.cart.subscription.label',
    //     ),
    //   type: 'boolean',
    //   options: ['true', 'false'],
    // },
  },
  discount: {
    value: {
      type: 'number',
      options: ['greater', 'lesser'],
      max: 999999,
      min: 10,
      prefix: (dashboardResponse) => dashboardResponse.shop.currency,
      bannerText: (t, dashboardResponse) =>
        t(
          'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.options.cart.subtotal.bannerText',
          {
            currency: dashboardResponse.shop.currency,
          },
        ),
    },
  },
}

const refreshObject = (condition) => {
  const defaultValue = getDefaultValues(condition.attribute)
  const obj = {
    ...condition,
    total: defaultValue.total || 0,
    quantity: defaultValue.quantity || 0,
    discount: defaultValue.discount || 0,
    subOperator: defaultValue.selected,
    operator: defaultValue.subType,
  }
  return obj
}