import React from 'react'
import { useChoiceField, useField, useForm } from '@shopify/react-form'

import { CHECKOUT_UPSELL_RECOMMENDATION } from '../../../../constants/checkout'

function useConfigForm(config, onSubmit) {
  const products = useField(config.products ?? [])
  const excludedProducts = useField(config.excludedProducts ?? [])
  const excludedTags = useField(config.excludedTags ?? [])
  const placement = useField(config.placement ?? [])
  const overrideWithManualRec = useChoiceField(
    config.overrideWithManualRec ?? false,
  )
  const postCheckoutEnable = useField(
    config.postCheckoutEnable
  )
  return useForm({
    fields: {
      products,
      excludedProducts,
      excludedTags,
      placement,
      overrideWithManualRec,
      postCheckoutEnable
    },
    onSubmit,
  })
}

export default useConfigForm
