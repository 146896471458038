import { DISCOUNT_TYPES } from '../../../../../../../../constants/promotions'

export function getDiscountedAmount({ original, discount }) {
  const originalAmount = parseFloat(original)
  if (!discount || !discount.type) {
    return original
  }

  let discountAmount = originalAmount
  if (discount.type === DISCOUNT_TYPES.PERCENTAGE.value) {
    discountAmount = originalAmount - (originalAmount / 100) * discount.value
  } else if (discount.type === DISCOUNT_TYPES.FLAT.value) {
    discountAmount = Math.max(0, originalAmount - parseFloat(discount.value))
  }
  return discountAmount
}

export function buildAllowedQuantities({ min = 1, max = 1 }) {
  const arr = []

  let i = min
  while (i <= max) {
    arr.push(i)
    i++
  }

  return arr
}
