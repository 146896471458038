import { useTranslation } from 'react-i18next'
import { Card, EmptyState, Page } from '@shopify/polaris'
import React from 'react'

import Loader from '../Loader'

export default function ShopStatusChecker({ children, shopStatus }) {
  const { isLoading, error, refetch } = shopStatus

  if (error) {
    return <ReloadScreen />
  }

  if (isLoading) {
    return <Loader />
  }

  return children
}

function ReloadScreen() {
  const { t } = useTranslation()
  return (
    <Page>
      <Card>
        <EmptyState
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          heading={t('ReloadScreen.heading')}
          action={{
            content: t('ReloadScreen.action'),
            onAction: () => window.location.reload(),
          }}
        >
          <p>{t('ReloadScreen.description')}</p>
        </EmptyState>
      </Card>
    </Page>
  )
}
