import {
  Avatar, Badge, Banner, BlockStack, Box, Button, Card, Divider, 
  InlineGrid, ResourceItem, ResourceList, Select, Text, Tooltip,
} from '@shopify/polaris'
import { EditIcon, LockIcon, ProductIcon } from '@shopify/polaris-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { currencyFormatter } from '../../../../../../../../utils/formater'
import productPlaceholder from '../../../../../../../../assets/collection-placeholder.webp'
import { areFeaturesEnabled } from '../../../../../../../../utils/features'
import { FEATURES } from '../../../../../../../../constants/features'
import { PricingModal, usePricing } from '../../../../../../../../components/PricingModal'
import { updateCheckoutUpsellConfig } from '../../../../../../../../apis/checkoutUpsell'


export default function PostCheckoutSectionConfig({
  mode = 'create',
  form,
  config: checkoutConfig,
  campaignId,
  refreshData,
}) {
  const postCheckoutAvailable = areFeaturesEnabled(
    [FEATURES.POST_PURCHASE],
    window.shopify.data,
  )
  const pricing = usePricing()
  const postCheckoutData = form.fields.postCheckoutData.value
  const promotionType = postCheckoutData?.settings?.promotionType || 'single_product'
  const slots = postCheckoutData?.settings?.slots || []
  const { t } = useTranslation(),
    options = [
      {
        label: t('DefaultText.enabled'),
        value: 'true',
      },
      {
        label: t('DefaultText.disabled'),
        value: 'false',
      },
    ]

  const setPostCheckoutData = (obj) => {
    const copy = { ...postCheckoutData }
    form.fields.postCheckoutData.onChange({
      ...copy,
      ...obj,
    })
  }

  useEffect(() => {
    function handleEvent(ev) {
      if (ev?.data === 'GAI-close-post-checkout-editor') {
        const modal = document.getElementById('post-checkout-modal')
        modal.hide()
        if (typeof refreshData === 'function') refreshData()
      }
    }
    window.addEventListener('message', handleEvent)

    return () => window.removeEventListener('message', handleEvent)
  }, [])

  return (
    <Card padding={'0'}>
      <Box padding={'400'}>
        <BlockStack gap={'400'}>
          <PostCheckoutDisableBanner checkoutConfig={checkoutConfig} refreshData={refreshData}/>
          <InlineGrid columns={'1fr auto'} gap={'200'}>
            <BlockStack gap={'100'}>
              <InlineGrid columns={'auto 1fr'} gap={'200'}>
                <Text variant="headingMd">
                  {t('CheckoutUpsell.CampaignDetail.postCheckout.title')}
                </Text>
                <Box>
                  {(() => {
                    let text = t('PostCheckoutEditor.not_set')
                    if (promotionType == 'single_product') {
                      text = t(
                        'PostCheckoutEditor.sideBar.promotionType.option.single_product',
                      )
                    } else if (promotionType == 'multiple_products') {
                      text = t(
                        'PostCheckoutEditor.sideBar.promotionType.option.multiple_products',
                      )
                    }

                    return (
                      <Badge tone={promotionType ? 'info' : undefined}>
                        {text}
                      </Badge>
                    )
                  })()}
                </Box>
              </InlineGrid>
              <Text>
                {t('CheckoutUpsell.CampaignDetail.postCheckout.description')}
              </Text>
            </BlockStack>
            <Box>
              {(() => {
                const btn = (
                  <Select
                    options={options}
                    onChange={(value) => {
                      if (!postCheckoutAvailable) {
                        pricing.open({
                          features: [FEATURES.POST_PURCHASE],
                        })
                        return
                      }
                      setPostCheckoutData({ enabled: value == 'true' })
                    }}
                    value={postCheckoutData.enabled ? 'true' : 'false'}
                  />
                )
                if (!postCheckoutAvailable) {
                  return (
                    <Tooltip content={t('postCheckoutNotAvailable')}>
                      {btn}
                    </Tooltip>
                  )
                }

                return btn
              })()}
            </Box>
          </InlineGrid>
          {!postCheckoutAvailable && (
            <Banner
              tone="warning"
              title={t('postCheckoutNotAvailable')}
              action={{
                content: t('DefaultText.showPricing'),
                onAction: () => {
                  pricing.open({
                    features: [FEATURES.POST_PURCHASE],
                  })
                },
              }}
            ></Banner>
          )}
        </BlockStack>
      </Box>
      <BottomChild />
      <PricingModal modal={pricing} />
    </Card>
  )

  function BottomChild() {
    if (mode == 'create') return null
    return (
      <>
        <Divider />
        <Box padding={'400'}>
          <InlineGrid columns={'1fr auto'} gap={'200'}>
            <BlockStack gap={'100'}>
              <Text variant="headingMd">
                {t(
                  'CheckoutUpsell.CampaignDetail.postCheckout.visualEditor.title',
                )}
              </Text>
              <Text>
                {t(
                  'CheckoutUpsell.CampaignDetail.postCheckout.visualEditor.description',
                )}
              </Text>
            </BlockStack>
            <Box>
              <Button
                variant="primary"
                onClick={() => {
                  if(!postCheckoutAvailable) {
                    pricing.open({
                      features: [FEATURES.POST_PURCHASE],
                    })
                    return
                  }
                  const modal = document.getElementById('post-checkout-modal')
                  modal.show()
                }}
                icon={!postCheckoutAvailable ? LockIcon : EditIcon}
              >
                {t(
                  'CheckoutUpsell.CampaignDetail.postCheckout.visualEditor.cta',
                )}
              </Button>
            </Box>
          </InlineGrid>
        </Box>
        <Divider />
        <div
          style={{
            padding: 'var(--p-space-200) var(--p-space-400)',
          }}
        >
          <Text variant="headingMd">
            {promotionType === 'multiple_products'
              ? t('PostCheckoutEditor.slots')
              : t('PostCheckoutEditor.configuredProduct')}{' '}
          </Text>
        </div>
        <ResourceList
          resourceName={{ singular: 'slot', plural: 'slots' }}
          items={slots.slice(
            0,
            promotionType == 'single_product' ? 1 : slots.length,
          )}
          renderItem={(slot, id, index) => <SlotCard slot={slot} id={index} />}
          emptyState={
            <Box padding={'400'}>
              <InlineGrid columns={'1fr auto 1fr'}>
                <div/>
              <Text variant='bodyMd'>{t('DefaultText.noProduct')}</Text>
              <div/>
              </InlineGrid>
            </Box>
          }
        />
        <ui-modal
          variant="max"
          id="post-checkout-modal"
          src={'/checkout-upsell/post-checkout/edit/' + campaignId}
        ></ui-modal>
      </>
    )

    function SlotCard({ slot, id }) {
      const isAutomatic = slot.recommendation === 'automatic'
      const imageUrl =
          slot.recommendation === 'automatic'
            ? ProductIcon
            : slot.product?.images[0]?.originalSrc || productPlaceholder,
        title = isAutomatic
          ? 'Automatic Recommendation'
          : slot.translations['primary_locale']?.title || slot.product.title
      let productPrice = parseFloat(slot.product?.variants[0]?.price || '0.00')
      productPrice = currencyFormatter(
        window.shopify.data.shop.currency,
        productPrice.toFixed(2),
      )
      const discount =
        slot.discount?.type === 'percentage'
          ? slot.discount.value + '%'
          : currencyFormatter(
              window.shopify.data.shop.currency,
              slot.discount?.value,
            )
      return (
        <ResourceItem
          id={id}
          media={<Avatar customer size="md" source={imageUrl} />}
        >
          <InlineGrid columns={'1fr auto'} gap={'300'} alignItems="start">
            <Text variant="bodyMd" fontWeight="bold" as="h3">
              {title}
            </Text>
            {isAutomatic && (
              <div>
                <Badge tone="info">
                  {t(
                    'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.card.aiGenerateBadge',
                  )}
                </Badge>
              </div>
            )}
          </InlineGrid>
          <div
            style={{
              paddingTop: 'var(--p-space-50)',
            }}
          >
            <Text>
              {isAutomatic
                ? `Discount: ${discount}`
                : `ID: ${slot.product.id?.replace('gid://shopify/Product/', '')} | Handle: ${slot.product.handle} | Price: ${productPrice} | Discount: ${discount}`}
            </Text>
          </div>
        </ResourceItem>
      )
    }
  }
}

function PostCheckoutDisableBanner({checkoutConfig, refreshData}){
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  if(checkoutConfig?.postCheckoutData?.enable) return null

  return <Banner title={t('PostCheckoutDisableBanner.title')} action={{
    content: t('PostCheckoutDisableBanner.cta'),
    loading: loading,
    onAction: async () => {
      try{
        setLoading(true)
        await updateCheckoutUpsellConfig({
          postCheckoutEnable: true
        })
        shopify.toast.show(t('PostCheckoutDisableBanner.success'))
        refreshData()
      } catch(e){
        console.error('error while updating error',e)
      } finally{
        setLoading(false)
      }
    }
  }}/>
}
