const NONE = {
  value: '',
  label: 'Default system ranking',
}

const PERSONALISED = {
  value: 'personalised',
  label: 'Personalized for viewer',
}

const BESTSELLERS = {
  value: 'bestsellers',
  label: 'Bestsellers first',
}

const NEW_ARRIVALS = {
  value: 'new_arrivals',
  label: 'New products first',
}

const HIGHEST_PRICED = {
  value: 'highest_priced',
  label: 'Price (High To Low)',
}

const LOWEST_PRICED = {
  value: 'lowest_priced',
  label: 'Price (Low To High)',
}

const RANDOM = {
  value: 'random',
  label: 'Random',
}

const NO_OPTION = {
  value: 'null',
  label: 'No criteria'
}

export {
  NONE,
  PERSONALISED,
  BESTSELLERS,
  NEW_ARRIVALS,
  LOWEST_PRICED,
  HIGHEST_PRICED,
  RANDOM,
  NO_OPTION
}
