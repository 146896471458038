import { BlockStack, Box, Grid, Text } from '@shopify/polaris'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'

import {
  currencyFormatter,
  numberFormater,
  percentageFormatter,
} from '../../../../utils/formater'
import { getRecommendationStats } from '../../../../apis/analytics'
import { AnalyticsCard } from '../../common/AnalyticsCard'

export default function StoreAnalytics({ durationFilter: _durationFilter }) {
  const [searchParams] = useSearchParams()
  const durationFilter = {
    start: searchParams.get('start')
      ? new Date(searchParams.get('start'))
      : _durationFilter.start,
    end: searchParams.get('end')
      ? new Date(searchParams.get('end'))
      : _durationFilter.end,
  }

  const { t } = useTranslation()
  const analyticsQuery = useQuery({
    queryKey: [
      'store-analytics',
      {
        start: new Date(durationFilter.start).toISOString().split('T')[0],
        end: new Date(durationFilter.end.setHours(23, 59, 59, 999))
          .toISOString()
          .split('T')[0],
      },
    ],
    queryFn: (queryKeys) => {
      return getRecommendationStats({
        dateGte: new Date(durationFilter.start.setHours(0, 0, 0, 0)+24*60*60*1000)
          .toISOString()
          .split('T')[0],
        dateLte: new Date(durationFilter.end.setHours(23, 59, 59, 999))
          .toISOString()
          .split('T')[0],
      })
    },
    enabled: durationFilter.end - durationFilter.start > 24 * 60 * 60 * 1000,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 5,
    keepPreviousData: true,
  })

  const cards = getCards(analyticsQuery.data?.data, t, analyticsQuery.isLoading)

  return (
    <Wrapper>
      {cards.map((card, index) => (
        <AnalyticsCard key={index} {...card} />
      ))}
    </Wrapper>
  )

  function Wrapper({ children }) {
    return (
      <BlockStack gap={'200'}>
        <Grid
          gap={{
            xs: 'var(--p-space-400)',
            sm: 'var(--p-space-400)',
            md: 'var(--p-space-400)',
            lg: 'var(--p-space-400)',
            xl: 'var(--p-space-400)',
          }}
          columns={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
          }}
        >
          {children}
        </Grid>
        <Box padding={'400'} />
      </BlockStack>
    )
  }
}

function getCards(data, t, loading) {
  const graphLabels = data?.dailyMetrics?.map((d) =>
    format(new Date(d.date), 'MMM dd'),
  )

  return [
    {
      label: t('StoreAnalytics.purchaseRate.title'),
      value: percentageFormatter(data?.metrics?.recommendationConversionRate),
      total: null,
      type: 'stats',
      loading,
      tableRows: [
        [
          {
            label: t('StoreAnalytics.purchaseRate.tableRows.label1'),
            value:
              numberFormater(data?.metrics?.sessionsWithRecommendations) +
              (data?.metrics?.sessionsWithRecommendations < 2
                ? ' session'
                : ' sessions'),
          },
          {
            label: null,
            value: percentageFormatter(data?.metrics?.recommendationViewRate),
          },
        ],
        [
          {
            label: t('StoreAnalytics.purchaseRate.tableRows.label2'),
            value:
              numberFormater(data?.metrics?.sessionsWithInteractions) +
              (data?.metrics?.sessionsWithInteractions < 2
                ? ' session'
                : ' sessions'),
          },
          {
            label: null,
            value: percentageFormatter(data?.metrics?.recommendationCtr),
          },
        ],
        [
          {
            label: t('StoreAnalytics.purchaseRate.tableRows.label3'),
            value:
              numberFormater(
                data?.metrics?.sessionsWithRecommendationsAndPurchase,
              ) +
              (data?.metrics?.sessionsWithRecommendationsAndPurchase < 2
                ? ' session'
                : ' sessions'),
          },
          {
            label: null,
            value: percentageFormatter(
              data?.metrics?.recommendationConversionRate,
            ),
          },
        ],
      ],
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.purchaseRate.title')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.purchaseRate.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
    },
    {
      label: t('StoreAnalytics.purchaseRateOverTime.label'),
      value: percentageFormatter(data?.metrics?.recommendationConversionRate),
      total: null,
      type: 'daily',
      graphLabels,
      graphDataSets: [
        {
          label: t('StoreAnalytics.purchaseRateOverTime.label1'),
          data: data?.dailyMetrics?.map((d) => d.recommendationCtr),
        },
        {
          label: t('StoreAnalytics.purchaseRateOverTime.label3'),
          data: data?.dailyMetrics?.map((d) => d.conversionRate),
        },
        {
          label: t('StoreAnalytics.purchaseRateOverTime.label2'),
          data: data?.dailyMetrics?.map((d) => d.recommendationConversionRate),
        },
      ],
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.purchaseRateOverTime.label')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.purchaseRateOverTime.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      formatter: percentageFormatter,
      loading,
    },
    {
      label: t('StoreAnalytics.attributedRevenue.title'),
      description: t('StoreAnalytics.attributedRevenue.description'),
      value: currencyFormatter(
        window.shopify.data.shop.currency,
        data?.metrics?.totalAmountWithRecommendationPurchases,
      ),
      formatter: (val) =>
        currencyFormatter(window.shopify.data.shop.currency, val),
      type: 'daily',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.attributedRevenue.title')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.attributedRevenue.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      graphLabels,
      graphDataSets: [
        {
          label: t('StoreAnalytics.attributedRevenue.title'),
          data: data?.dailyMetrics?.map(
            (d) => d.totalAmountWithRecommendationPurchases,
          ),
        },
      ],
      loading,
    },
    {
      label: t('StoreAnalytics.attributedRevenueTable.label'),
      description: t('StoreAnalytics.attributedRevenueTable.description'),
      value: percentageFormatter(
        data?.metrics?.recommendationRevenueContribution,
      ),
      type: 'stats',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.attributedRevenueTable.label')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.attributedRevenueTable.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      tableRows: [
        [
          {
            label: t('StoreAnalytics.attributedRevenue.tableRows.label1'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.totalAmount,
            ),
          },
        ],
        [
          {
            label: t('StoreAnalytics.attributedRevenue.tableRows.label2'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.totalAmountWithRecommendations,
            ),
          },
        ],
        [
          {
            label: t('StoreAnalytics.attributedRevenue.tableRows.label3'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.totalAmountWithRecommendationPurchases,
            ),
          },
        ],
      ],
      loading,
    },
    {
      label: t('StoreAnalytics.aov.title'),
      value: currencyFormatter(
        window.shopify.data.shop.currency,
        data?.metrics?.aovWithRecommendationPurchases,
      ),
      graphDataSets: [
        {
          label: t('StoreAnalytics.aovTable.tableRows.label3'),
          data: data?.dailyMetrics?.map(
            (d) => d.aovWithRecommendationPurchases,
          ),
        },
        {
          label: t('StoreAnalytics.aovTable.tableRows.label2'),
          data: data?.dailyMetrics?.map((d) => d.aovWithRecommendations),
        },

        {
          label: t('StoreAnalytics.aovTable.tableRows.label1'),
          data: data?.dailyMetrics?.map((d) => d.aov),
        },
      ],
      total: null,
      formatter: (val) =>
        currencyFormatter(window.shopify.data.shop.currency, val),
      type: 'daily',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">{t('StoreAnalytics.aov.title')}</Text>
            <Text variant="bodySm">{t('StoreAnalytics.aov.description')}</Text>
          </BlockStack>
        </Box>
      ),
      graphLabels,
      loading,
    },
    {
      label: t('StoreAnalytics.aovTable.title'),
      value: percentageFormatter(
        data?.metrics?.aovIncreaseDueToRecommendations,
      ),
      tableRows: [
        [
          {
            label: t('StoreAnalytics.aovTable.tableRows.label1'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.aov,
            ),
          },
        ],
        [
          {
            label: t('StoreAnalytics.aovTable.tableRows.label2'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.aovWithRecommendations,
            ),
          },
        ],
        [
          {
            label: t('StoreAnalytics.aovTable.tableRows.label3'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.aovWithRecommendationPurchases,
            ),
          },
        ],
      ],
      type: 'stats',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.aovTable.title')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.aovTable.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      loading,
    },
    {
      label: t('StoreAnalytics.checkout.label'),
      value: numberFormater(data?.metrics?.checkoutCount),
      graphDataSets: [
        {
          label: t('StoreAnalytics.checkout.label1'),
          data: data?.dailyMetrics?.map((d) => d.checkoutCount),
        },
        {
          label: t('StoreAnalytics.checkout.label2'),
          data: data?.dailyMetrics?.map(
            (d) => d.checkoutCountWithRecommendations,
          ),
        },
        {
          label: t('StoreAnalytics.checkout.label3'),
          data: data?.dailyMetrics?.map(
            (d) => d.checkoutCountWithRecommendationPurchases,
          ),
        },
      ],
      formatter: numberFormater,
      type: 'daily',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.checkout.label')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.checkout.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      graphLabels,
      loading,
    },
  ]
}
