import {
  BlockStack,
  Card,
  Icon,
  InlineStack,
  Page,
  Spinner,
  Text,
} from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { confirmPlan } from '../../apis/plans'
import { useRedirect } from '../../hooks/useRedirect'
import { getQueryParams } from '../../utils/router'

function ConfirmCharge({refetchShopStatus}) {
  const { t } = useTranslation()
  const { charge_id } = getQueryParams()
  const { redirectToLink } = useRedirect()
  const [loading, setLoading] = useState(true)
  const [timer, setTimer] = useState(5) // 5 seconds

  const confirmChargeHandler = async () => {
    const res = await confirmPlan(charge_id)
    if (res.error) {
      redirectToLink({ url: '/error', external: false })
    }
    setLoading(false)
    await refetchShopStatus()
  }

  useEffect(() => {
    confirmChargeHandler()
  }, [])

  useEffect(() => {
    if (loading || timer <= 0) return
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [loading])

  useEffect(() => {
    if (timer == 0) {
      setTimeout(() => {
        redirectToLink({ url: '/', external: false })
      }, 500)
    }
  }, [timer])

  if (!loading) {
    return (
      <Page>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 'var(--p-space-400)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <InlineStack align="center">
              <Text as='h1' variant='heading2xl'>{timer}</Text>
            </InlineStack>
            <Text>{t('DefaultText.chargeSuccess')}</Text>
          </div>
        </Card>
      </Page>
    )
  }

  return (
    <Page>
      <Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 'var(--p-space-400)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InlineStack align="center">
            <Spinner accessibilityLabel="Spinner example" size="large" />
          </InlineStack>
          <Text>{t('DefaultText.chargeConfirm')}</Text>
        </div>
      </Card>
    </Page>
  )
}

export default ConfirmCharge
