import { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { changePlan, getPlans } from '../../../apis/plans'
import queryClient from '../../../utils/query'
import { getFeaturesPlans } from '../../../utils/features'

export function usePricing({ plan = null } = {}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [plans, setPlans] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const cb = () => {
      setModalOpen(false)
      queryClient.invalidateQueries([
        'getDashboardDetails',
        window.shopify.data.shopPlan,
      ])
    }
    document.addEventListener('visibilitychange', cb)
    return () => {
      document.removeEventListener('visibilitychange', cb)
    }
  })

  const query = useQuery({
    queryKey: ['plans', {}],
    queryFn: async ({ queryKey }) => {
      const filter = queryKey[1] || {}
      const { data, error } = await getPlans(filter)
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  })

  const fetchPlans = async (
    filter = {
      features: [],
    },
  ) => {
    setLoading(true)
    if (filter.showContactCTA) {
      setPlans([])
      return
    }
    let plans = query.data || []
    if (filter.promo || filter.plan) {
      const { data } = await getPlans(filter)
      plans = data
    }
    plans = plans.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
    if (filter.page) {
      plans = plans.filter((plan) => plan.pagesEnabled.includes(filter.page))
    }
    if (filter.recommendation) {
      plans = plans.filter((plan) =>
        plan.recommendationsEnabled.includes(filter.recommendation),
      )
    }
    if (filter.currentPlan) {
      plans = plans.filter(
        (plan) =>
          parseFloat(plan.price) > parseFloat(filter.currentPlan.price),
      )
    }
    if (filter.slug) {
      plans = plans.filter((plan) => plan.slug === filter.slug)
    }
    if (filter.id) {
      plans = plans.filter((plan) => plan.id === filter.id)
    }
    if (filter.abovePlans) {
      plans = plans.filter(
        (plan) =>
          parseFloat(plan.price) > parseFloat(filter.abovePlans.price),
      )
    }
    if (filter.plans) {
      plans = plans.filter((plan) =>
        filter.plans.find((p) => p.id === plan.id),
      )
    }

    const filteredPlans = getFeaturesPlans(
      filter.features,
      plans,
      window.shopify.data,
    )
    setPlans((d) => [...filteredPlans])
    setLoading(false)
  }

  const modalOpenHandler = async (
    filter = {
      features: [],
      recommenadion: '',
      page: '',
      showContactCTA: false,
    },
  ) => {
    await fetchPlans(filter)
    setModalOpen(true)
  }

  useEffect(() => {
    if(query.status === 'success'){
      fetchPlans({
        plan: plan,
      })
    }
  }, [query.status])

  return {
    isLoading: loading,
    isOpen: modalOpen,
    close: () => {
      fetchPlans()
      setModalOpen(false)
    },
    plans,
    open: modalOpenHandler,
    addPromoCode: (promo) => {
      return fetchPlans({ promo, plans })
    },
    showPlans: (plan) => fetchPlans({ plan }),
    changePlan: async (payload) => {
      const url = (await changePlan(payload)).data.redirectUrl
      await queryClient.invalidateQueries(['dashboardBoot'])
      return url
    },
    error: query.error,
  }
}
