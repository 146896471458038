import { BlockStack, Box, Grid, Text } from '@shopify/polaris'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'

import {
  currencyFormatter,
  numberFormater,
  percentageFormatter,
} from '../../../../utils/formater'
import { getCheckoutAnalytics } from '../../../../apis/analytics'
import { AnalyticsCard } from '../../common/AnalyticsCard'

export default function CheckoutAnalytics({ durationFilter: _durationFilter }) {
  const [searchParams] = useSearchParams()
  const durationFilter = {
    start: searchParams.get('start')
      ? new Date(searchParams.get('start'))
      : _durationFilter.start,
    end: searchParams.get('end')
      ? new Date(searchParams.get('end'))
      : _durationFilter.end,
  }

  const { t } = useTranslation()
  const analyticsQuery = useQuery({
    queryKey: [
      'checkout-analytics',
      {
        start: durationFilter.start.toISOString().split('T')[0],
        end: new Date(durationFilter.end).toISOString().split('T')[0],
      },
    ],
    queryFn: async () => {
      const { data, error } = await getCheckoutAnalytics({
        dateGte: new Date(
          durationFilter.start.setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000,
        )
          .toISOString()
          .split('T')[0],
        dateLte: new Date(durationFilter.end.setHours(23, 59, 59, 999))
          .toISOString()
          .split('T')[0],
      })
      if (error) {
        console.log('error', error)
        return Promise.reject(error)
      }

      return data
    },
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    enabled: durationFilter.end - durationFilter.start > 12 * 60 * 60 * 1000,
    keepPreviousData: true,
  })

  const cards = getCards(
    analyticsQuery.data?.stats,
    t,
    analyticsQuery.isLoading || analyticsQuery.isRefetching,
  )

  if (analyticsQuery.error && !analyticsQuery.isLoading) {
    return (
      <>
        <Text variant="bodySm">Some error occured</Text>
        <pre>{JSON.stringify(analyticsQuery.error, null, 2)}</pre>
      </>
    )
  }

  return (
    <Wrapper>
      {cards.map((card, index) => (
        <AnalyticsCard key={index} {...card} />
      ))}
    </Wrapper>
  )

  function Wrapper({ children }) {
    return (
      <BlockStack gap={'200'}>
        <Grid
          gap={{
            xs: 'var(--p-space-400)',
            sm: 'var(--p-space-400)',
            md: 'var(--p-space-400)',
            lg: 'var(--p-space-400)',
            xl: 'var(--p-space-400)',
          }}
          columns={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
          }}
        >
          {children}
        </Grid>
        <Box padding={'400'} />
      </BlockStack>
    )
  }
}

function getCards(data = {}, t, loading) {
  const graphLabels =
    data?.daily?.map((d) => format(new Date(d.date), 'MMM dd')) || []
  return [
    {
      label: t('CheckoutAnalytics.checkout.title'),
      value: numberFormater(data.totalUpsellCheckouts),
      total: null,
      type: 'stats',
      loading,
      tableRows: [
        [
          {
            label: t('CheckoutAnalytics.checkout.started'),
            value: null,
          },
          {
            label: null,
            value: numberFormater(data.totalCheckoutsStarted),
          },
        ],
        [
          {
            label: t('CheckoutAnalytics.checkout.withUpsell'),
            value: null,
          },
          {
            label: null,
            value: numberFormater(data.totalUpsellCheckouts),
          },
        ],
        [
          {
            label: t('CheckoutAnalytics.checkout.views'),
            value: null,
          },
          {
            label: null,
            value: numberFormater(
              data.totalCheckoutsWithUpsellPurchases +
                data.totalCheckoutsWithPostCheckoutPurchases,
            ),
          },
        ],
        [
          {
            label: t('CheckoutAnalytics.checkout.completed'),
            value: null,
          },
          {
            label: null,
            value: numberFormater(data.totalCheckoutsCompleted),
          },
        ],
        [
          {
            label: t('CheckoutAnalytics.checkout.upsellConversions'),
            value: null,
          },
          {
            label: null,
            value: percentageFormatter(data.checkoutConversionRate),
          },
        ],
      ],
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('CheckoutAnalytics.checkout.title')}
            </Text>
            <Text variant="bodySm">
              {t('CheckoutAnalytics.checkout.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
    },
    {
      label: t('CheckoutAnalytics.purchaseRate.title'),
      value: percentageFormatter(data.checkoutConversionRate),
      total: null,
      type: 'daily',
      graphLabels,
      graphDataSets: [
        // {
        //   label: t('CheckoutAnalytics.purchaseRate.conversionRate'),
        //   data: [],
        // },
        {
          label: t('CheckoutAnalytics.purchaseRate.purchaseRate'),
          data: data.daily?.map((d) => d.checkoutConversionRate) || [],
        },
      ],
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('CheckoutAnalytics.purchaseRate.title')}
            </Text>
            <Text variant="bodySm">
              {t('CheckoutAnalytics.purchaseRate.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      formatter: percentageFormatter,
      loading,
    },
    {
      label: t('CheckoutAnalytics.revenue.title'),
      description: t('CheckoutAnalytics.revenue.description'),
      value: currencyFormatter(
        window.shopify.data.shop.currency,
        data.totalUpsellRevenue,
      ),
      formatter: (val) =>
        currencyFormatter(window.shopify.data.shop.currency, val),
      type: 'daily',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('CheckoutAnalytics.revenue.title')}
            </Text>
            <Text variant="bodySm">
              {t('CheckoutAnalytics.revenue.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      graphLabels,
      graphDataSets: [
        {
          label: t('CheckoutAnalytics.revenue.checkoutRevenue'),
          data: data.daily?.map((d) => d.totalCheckoutUpsellRevenue) || [],
        },
        {
          label: t('CheckoutAnalytics.revenue.postCheckoutRevenue'),
          data: data.daily?.map((d) => d.totalPostCheckoutUpsellRevenue) || [],
        },
      ],
      loading,
    },
    {
      label: t('CheckoutAnalytics.revenueAttr.title'),
      description: t('CheckoutAnalytics.revenueAttr.description'),
      value: currencyFormatter(
        window.shopify.data.shop.currency,
        data.totalPostCheckoutUpsellRevenue + data.totalCheckoutUpsellRevenue,
      ),
      type: 'stats',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('CheckoutAnalytics.revenueAttr.title')}
            </Text>
            <Text variant="bodySm">
              {t('CheckoutAnalytics.revenueAttr.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      tableRows: [
        [
          {
            label: t('CheckoutAnalytics.revenueAttr.totalRevenue'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data.totalRevenue,
            ),
          },
        ],
        [
          {
            label: t('CheckoutAnalytics.revenueAttr.upsellRevenue'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data.totalCheckoutUpsellRevenue,
            ),
          },
        ],
        [
          {
            label: t('CheckoutAnalytics.revenueAttr.postCheckoutRevenue'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data.totalPostCheckoutUpsellRevenue,
            ),
          },
        ],
      ],
      loading,
    },
    {
      label: t('CheckoutAnalytics.aovIncrease.title'),
      value: currencyFormatter(
        window.shopify.data.shop.currency,
        data.totalAovIncrease,
      ),
      graphDataSets: [
        {
          label: t('CheckoutAnalytics.aovIncrease.storeAov'),
          data: data.daily ? data.daily.map((d) => d.totalAov) : [],
        },
        {
          label: t('CheckoutAnalytics.aovIncrease.checkoutAov'),
          data: data.daily?.map((d) => d.aovIncreaseDueToCheckoutUpsells) || [],
        },
        {
          label: t('CheckoutAnalytics.aovIncrease.postCheckout'),
          data:
            data.daily?.map((d) => d.aovIncreaseDueToPostCheckoutUpsells) || [],
        },
      ],
      total: null,
      formatter: (val) =>
        currencyFormatter(window.shopify.data.shop.currency, val),
      type: 'daily',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('CheckoutAnalytics.aovIncrease.title')}
            </Text>
            <Text variant="bodySm">
              {t('CheckoutAnalytics.aovIncrease.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      graphLabels,
      loading,
    },
    {
      label: t('CheckoutAnalytics.aovIncrease.title'),
      value: currencyFormatter(
        window.shopify.data.shop.currency,
        data.totalAovIncrease,
      ),
      tableRows: [
        [
          {
            label: t('CheckoutAnalytics.aovIncrease.storeAov'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data.totalAov,
            ),
          },
        ],
        [
          {
            label: t('CheckoutAnalytics.aovIncrease.checkoutAov'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data.aovIncreaseDueToCheckoutUpsells,
            ),
          },
        ],
        [
          {
            label: t('CheckoutAnalytics.aovIncrease.postCheckout'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data.aovIncreaseDueToPostCheckoutUpsells,
            ),
          },
        ],
      ],
      type: 'stats',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('CheckoutAnalytics.aovIncrease.title')}
            </Text>
            <Text variant="bodySm">
              {t('CheckoutAnalytics.aovIncrease.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      loading,
    },
  ]
}
