import {
  BlockStack,
  Card,
  Grid,
  SkeletonDisplayText,
  InlineStack,
  Text,
  Tooltip,
  DataTable,
} from '@shopify/polaris'

import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'

Chart.register(CategoryScale)

export function AnalyticsCard({
  label,
  value,
  graphLabels,
  total,
  formatter,
  tooltip,
  type,
  tableRows,
  graphDataSets,
  loading,
}) {
  if (type === 'daily') {
    return (
      <Grid.Cell>
        <Card padding={'0'}>
          <BlockStack gap={'200'}>
            <Header />
            <div
              style={{
                height: '300px',
                padding: 'var(--p-space-200)',
              }}
            >
              {loading && (
                <div
                  style={{
                    padding:
                      '0 var(--p-space-200) var(--p-space-200) var(--p-space-200)',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Skeleton size="small" />
                </div>
              )}
              {!loading && (
                <Line
                  data={{
                    labels: graphLabels,
                    datasets: graphDataSets,
                  }}
                  width={'100%'}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    elements: {
                      line: {
                        tension: 0.3,
                        borderJoinStyle: 'round',
                        hoverBorderJoinStyle: 'round',
                      },
                    },
                    plugins: {
                      legend: {
                        position: 'bottom',
                        align: 'end',
                        labels: {
                          usePointStyle: true,
                          pointStyle: 'line',
                          sort: (a, b) => {
                            return a.datasetIndex - b.datasetIndex
                          },
                        },
                      },
                      tooltip: {
                        mode: 'x',
                        backgroundColor: 'white',
                        titleColor: 'rgba(26, 26, 26, 1)',
                        bodyColor: 'rgba(48, 48, 48, 1)',
                        borderWidth: 1,
                        borderColor: 'rgba(0, 0, 0, 0.1)',
                        intersect: false,
                        callbacks: {
                          label: (tooltipItem) => {
                            return (
                              tooltipItem.dataset.label +
                              ': ' +
                              formatter(tooltipItem.parsed.y)
                            )
                          },
                        },
                        usePointStyle: true,
                      },
                    },
                    hover: {
                      mode: 'nearest',
                      intersect: true,
                    },
                    tooltips: {
                      mode: 'x',
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          scaleLabel: {
                            display: true,
                            labelString: 'Month',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          display: true,
                          scaleLabel: {
                            display: true,
                          },
                        },
                      ],
                      y: {
                        ticks: {
                          callback: function (value, index, ticks) {
                            return formatter(value).replace(/\.\d+/, '')
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </BlockStack>
        </Card>
      </Grid.Cell>
    )
  }

  if (type === 'stats') {
    return (
      <Grid.Cell>
        <Card padding={'0'}>
          <BlockStack gap={'200'}>
            <Header />
            <div
              style={{
                height: '300px',
              }}
            >
              {loading ? (
                <div
                  style={{
                    padding:
                      'var(--p-space-200) var(--p-space-400) var(--p-space-400) var(--p-space-400)',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Skeleton size="small" />
                </div>
              ) : (
                <DataTable
                  columnContentTypes={['text', 'text']}
                  headings={[]}
                  rows={createRows()}
                />
              )}
            </div>
          </BlockStack>
        </Card>
      </Grid.Cell>
    )

    function createRows() {
      if (!tableRows) {
        new Error('Analytics:StoreAnalytics, Table rows not provided')
      }

      return tableRows.map((row) => {
        return row.map((cell, index) => {
          const wrapper = (content) =>
            index == 0 ? (
              <div
                style={{
                  paddingLeft: 'var(--p-space-200)',
                }}
              >
                {content}
              </div>
            ) : (
              content
            )
          if (cell.label) {
            return wrapper(
              <BlockStack>
                <Text truncate tone="base">
                  {cell.label}
                </Text>
                <Text truncate tone="subdued" variant="bodyMd">
                  {cell.value}
                </Text>
              </BlockStack>,
            )
          }

          return wrapper(<Text>{cell.value}</Text>)
        })
      })
    }
  }

  return null

  function Header() {
    return (
      <div>
        <div
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor =
              'var(--p-color-bg-surface-hover)'
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = 'transparent'
          }}
          style={{
            transition: 'all 0.1s var(--p-motion-ease-in-out)',
            cursor: 'pointer',
            padding:
              'var(--p-space-400) var(--p-space-400) var(--p-space-200)  var(--p-space-400)',
          }}
        >
          <Tooltip preferredPosition="below" width="wide" content={tooltip}>
            <Text as="h4" variant="headingMd">
              <span
                style={{
                  textDecoration: 'underline',
                  textDecorationStyle: 'dashed',
                  textDecorationColor: 'var(--p-color-bg-fill-selected)',
                  textDecorationThickness: '2px',
                }}
              >
                {label}
              </span>
            </Text>
          </Tooltip>
        </div>
        <div
          style={{
            padding: '0 var(--p-space-400)',
          }}
        >
          {loading ? (
            <SkeletonDisplayText />
          ) : (
            <InlineStack gap={'200'}>
              <Text variant="headingLg" as="strong">
                {value ?? 0}
              </Text>
              {total && (
                <Text variant="subdued" as="span">
                  / {total}
                </Text>
              )}
            </InlineStack>
          )}
        </div>
      </div>
    )
  }

  function Skeleton() {
    return (
      <div
        style={{
          backgroundColor: 'var(--p-color-bg-fill-tertiary)',
          height: '100%',
          width: '100%',
          borderRadius: 'var(--p-border-radius-100)',
        }}
      />
    )
  }
}
