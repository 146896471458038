import React, { useCallback, useEffect, useState } from 'react'
import {
  ActionList,
  Banner,
  BlockStack,
  Box,
  Card,
  Divider,
  EmptyState,
  Icon,
  InlineGrid,
  Popover,
  Tabs,
  Text,
  TextField,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import emptyPicture from '../../../../assets/empty-state.svg'
import ProductSequenceEdit from '../../../../components/ProductSequenceEdit'
import { WIDGET_TYPES } from '../../../../constants/widgets'
import { canAccessResourcePicker } from '../../../../utils/access'
import Button from '../../../../components/Button'

function ConfigureProductRecommendation({
  product,
  setSelectedProducts,
  selectedProducts,
  selectedTab,
  hideOtherWidgets = false,
  onProductTitleClick,
  loading,
  onReverseProductChange,
}) {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  const TABS = [
    {
      content: t('RecommendingProductType.bought_together.title'),
      id: WIDGET_TYPES.bought_together.value,
    },
    {
      content: t('RecommendingProductType.similar_products.title'),
      id: WIDGET_TYPES.similar_products.value,
    },
    {
      content: t('RecommendingProductType.cross_sell.title'),
      id: WIDGET_TYPES.cross_sell.value,
    },
  ]

  const productPicker = async (reverseProducts = false) => {
    try {
      const selected = await shopify.resourcePicker({
        type: 'product',
        selectionIds: selectedProducts
          .filter((product) => {
            return !product.aiGenerated
          })
          .map((product) => ({
            id: product.id,
          })),
        multiple: 20,
        filter: {
          variants: false,
          query: `-${product.id.replace('gid://shopify/Product/', '')}`,
          archived: false,
        },
      })
      if (!selected) {
        setSelectedProducts(selectedProducts)
      } else {
        const processed = new Array(...selected).map((product) => ({
          aiGenerated: false,
          handle: product.handle,
          id: product.id,
          images: product.images.map(({ originalSrc }) => originalSrc),
          title: product.title,
          vendor: product.vendor,
          tags: product.tags,
          productType: product.productType,
          variants: product.variants,
        }))
        if (reverseProducts) {
          onReverseProductChange(processed)
        } else {
          setSelectedProducts(processed)
        }
      }
    } catch (e) {
      window.shopify.toast.show(t('noProductUpdate'), {
        isError: true,
      })
    }
  }

  const aiGeneratedProducts = selectedProducts.filter(
    (product) => product.aiGenerated,
  )
  const manuallyConfiguredProducts = selectedProducts.filter(
    (product) => !product.aiGenerated,
  )

  return (
    <>
      <Card padding={'0'}>
        <Divider />
        <div
          style={{
            padding: 'var(--p-space-400)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 'var(--p-space-400)',
              width: '100%',
            }}
          >
            <div>
              <Text as="strong">
                {t(`RecommendingProductType.${TABS[selectedTab]?.id}.title`)}
              </Text>
              <InlineGrid columns={'1fr auto'} gap={'200'}>
                <Text>
                  {t(
                    `RecommendingProductType.${TABS[selectedTab]?.id}.description`,
                  )}
                </Text>
                <ProductSelectorPopover
                  onProductPick={() => {
                    productPicker(false)
                  }}
                  onReverseProductPick={() => {
                    productPicker(true)
                  }}
                />
              </InlineGrid>
            </div>
          </div>
        </div>
        {selectedProducts.length === 0 ? (
          <Box>
            <EmptyState
              heading={t(
                'RecommendationPage.NewProductRecommendation.emptyTitle',
              )}
              image={emptyPicture}
            >
              <p>
                {t(
                  'RecommendationPage.NewProductRecommendation.emptyDescription',
                )}
              </p>
            </EmptyState>
          </Box>
        ) : (
          <>
            {loading && (
              <Box padding={'200'}>
                <Banner tone="info">Saving.....</Banner>
              </Box>
            )}
            <Divider />
            <InlineGrid
              columns={{
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 2,
              }}
            >
              <div
                style={{
                  borderRight:
                    breakpoints.xlUp && '1px solid var(--p-color-border)',
                }}
              >
                <BlockStack>
                  <Box padding={'400'}>
                    <Text variant="headingSm">
                      {t('manuallyConfiguredRecTitle')}
                    </Text>
                    <Text tone="subdued">
                      {t('manuallyConfiguredRecDescription')}
                    </Text>
                  </Box>
                  <ProductSequenceEdit
                    loading={loading}
                    items={manuallyConfiguredProducts}
                    onChange={(array) => {
                      setSelectedProducts([...array, ...aiGeneratedProducts])
                    }}
                    onTitleClick={onProductTitleClick}
                    emptyText={t('emptyAiGeneratedRec')}
                    addDividerAtEnd={
                      aiGeneratedProducts.length <
                      manuallyConfiguredProducts.length
                    }
                  />
                </BlockStack>
              </div>
              <BlockStack>
                <Box padding={'400'}>
                  <Text variant="headingSm">{t('aiGeneratedRecTitle')}</Text>
                  <Text tone="subdued">{t('aiGeneratedRecDescription')}</Text>
                </Box>
                <ProductSequenceEdit
                  loading={loading}
                  items={aiGeneratedProducts}
                  onChange={(array) => {
                    setSelectedProducts([
                      ...manuallyConfiguredProducts,
                      ...array,
                    ])
                  }}
                  onTitleClick={onProductTitleClick}
                  emptyText={t('emptyAiGeneratedRec')}
                />
              </BlockStack>
            </InlineGrid>
          </>
        )}
      </Card>
    </>
  )
}

export default ConfigureProductRecommendation

function ProductSelectorPopover({ onProductPick, onReverseProductPick }) {
  const [active, setActive] = useState(false)
  const { t } = useTranslation()
  const canPickProducts = canAccessResourcePicker(window.shopify.data)
  return (
    <Popover
      onClose={() => {
        setActive(false)
      }}
      active={active}
      activator={
        <Button
          disabled={!canPickProducts}
          tooltipText={!canPickProducts && t('noProductPermission')}
          onClick={() => setActive(!active)}
          disclosure
        >
          {t('RecommendationPage.NewProductRecommendation.addProductTitle')}
        </Button>
      }
    >
      <Popover.Pane>
        <ActionList
          items={[
            {
              content: t('BundlesUpsert.ProductsSelector.selector.addProduct'),
              onAction: onProductPick,
            },
            {
              content: t(
                'BundlesUpsert.ProductsSelector.selector.reverseProduct',
              ),
              onAction: onReverseProductPick,
            },
          ]}
        />
      </Popover.Pane>
    </Popover>
  )
}