import { v4 as uuidV4 } from 'uuid'
import _find from 'lodash.find'

import {
  CAMPAIGN_CONDITION_CARD_TYPE,
  CAMPAIGN_CONDITION_TYPE,
  CAMPAIGN_TRIGGER_CONDITION_OPTIONS,
} from '../../../../../../../../constants/checkout'

export function getDefaultValues(type) {
  if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS) {
    return {
      items: [],
      subType: 'specific_products',
      quantity: 0,
      selected: 'atleast',
    }
  }
  if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.CART) {
    return {
      total: 0,
      items: [],
      subType: 'subtotal',
      subscription: true,
      selected: 'greater',
    }
  }
  if (type === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.DISCOUNT) {
    return {
      discount: 0,
      subType: 'value',
      selected: 'greater',
    }
  }
}

export function getDefaultGroup() {
  return {
    id: uuidV4(),
    type: CAMPAIGN_CONDITION_CARD_TYPE.GROUP,
    conditions: [getDefaultAttribute()],
    operator: CAMPAIGN_CONDITION_TYPE.AND,
  }
}

export function getDefaultAttribute() {
  return {
    type: CAMPAIGN_CONDITION_CARD_TYPE.ATTRIBUTE,
    attribute: CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS,
    operator: 'specific_products',
    values: [],
    id: uuidV4(),
    total: 0,
    quantity: 0,
    discount: 0,
    subOperator: 'atleast',
  }
}

export function insertIntoGroup(group, condition, targetGrpId) {
  if (group.id === targetGrpId) {
    if (!group.conditions) {
      group.conditions = []
    }
    group.conditions.push(condition)
    return group // Return the modified group
  }

  if (Array.isArray(group.conditions)) {
    group.conditions = group.conditions.map((c) => {
      if (c.type === CAMPAIGN_CONDITION_CARD_TYPE.GROUP) {
        return insertIntoGroup(c, condition, targetGrpId)
      }
      return c
    })
  }

  return group
}

export function deleteFromGroupById(conditionGroup, conditionId) {
  if (conditionGroup.id === conditionId) {
    return null
  }

  if (Array.isArray(conditionGroup.conditions)) {
    conditionGroup.conditions = conditionGroup.conditions.filter((c) => {
      return deleteFromGroupById(c, conditionId)
    }).filter(Boolean)
  }

  return conditionGroup
}

export function updateCondition(
  conditionGroup,
  conditionId,
  condition
) {
  if(conditionGroup.id === conditionId){
    return condition
  }

  if (Array.isArray(conditionGroup.conditions)) {
    conditionGroup.conditions = conditionGroup.conditions.map((c) => {
        return updateCondition(c, conditionId, condition)
    })
  }

  return conditionGroup
}

