import { Box, Button, InlineStack, Select, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import LANGS from '../../../../../../../constants/languages'

export default function TopBar({ form }) {
  const { t } = useTranslation()
  return (
    <Box padding={'300'}>
      <InlineStack align="space-between" blockAlign="center">
        <Text variant="headingMd">{t('PostCheckoutEditor.title')}</Text>
        <InlineStack blockAlign="center" gap={'200'}>
          <Select
            {...form.fields.translations}
            labelInline
            label={t('DefaultText.translations')}
            options={[
              ...LANGS.filter(
                (lang) =>
                  lang.value !== window.shopify.config.locale.split('-')[0],
              ).map((lang) => ({
                label: lang.label,
                value: lang.value,
              })),
              { label: 'Primary Locale', value: 'primary_locale' },
            ]}
          />
          <Button onClick={form.reset} variant="subdued">
            {t('DefaultText.discard')}
          </Button>
          <Button
            onClick={form.submit}
            loading={form.submitting}
            variant="primary"
          >
            {t('DefaultText.save')}
          </Button>
        </InlineStack>
      </InlineStack>
    </Box>
  )
}
