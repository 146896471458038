import {
  BlockStack,
  Box,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { getCampaignDetails } from '../../../../apis/checkoutUpsell'
import Editor from './Editor'
import { getRandomProduct } from '../../../../apis/shopify'

export function PostCheckoutEditor() {
  const { campaignId } = useParams()
  const campaignQuery = useQuery({
    queryKey: ['campaign', campaignId],
    queryFn: async () => {
      const { data, error } = await getCampaignDetails(parseInt(campaignId))
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
    enabled: Boolean(campaignId),
  })

  // Get a random product from Shopify to use as a default product
  const randomProductQuery = useQuery({
    queryKey: ['randomProduct'],
    queryFn: async () => getRandomProduct(),
  })

  if (!campaignId) return null

  if (campaignQuery.isLoading || randomProductQuery.isLoading)
    return <Skeleton />

  return (
    <Editor
      initData={{
        randomProductId: randomProductQuery.data,
      }}
      campaign={campaignQuery.data}
    />
  )
}

function Skeleton() {
  return (
    <Box padding={'400'}>
      <BlockStack gap={'300'}>
        <SkeletonDisplayText />
        <SkeletonBodyText lines={5} />
      </BlockStack>
    </Box>
  )
}
