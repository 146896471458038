import { useQuery } from 'react-query'

import { getDashboardStats } from '../../../apis/analytics'
import { useTranslation } from 'react-i18next'
import {
  currencyFormatter,
  numberFormater,
  percentageFormatter,
} from '../../../utils/formater'
import {
  BlockStack,
  Card,
  Grid,
  InlineGrid,
  InlineStack,
  SkeletonDisplayText,
  Text,
} from '@shopify/polaris'

export function Analytics() {
  const query = useQuery({
    queryKey: ['dashboardStats', {}],
    queryFn: () =>
      new Promise(async (resolve, reject) => {
        const endTime = new Date()
        const startTime = new Date(endTime.getTime() - 24 * 60 * 60 * 1000)

        const data = {
          dateGte: Math.floor(startTime.getTime()),
          dateLte: Math.floor(endTime.getTime()),
        }

        const response = await getDashboardStats(data)
        if (response.error) {
          reject(response.error)
        }
        setTimeout(() => {
          resolve(response.data)
        }, 3000)
      }),
    cacheTime: 60 * 1000, // 1 minute
  })

  return renderCells(query.data, query.isLoading)
}

function renderCells(data, loading) {
  const { t } = useTranslation()
  const checkoutCards = [],
    recoCards = []

  ;[
    {
      label: t('DashboardAnalytics.totalRecommendationRevenue'),
      value: data?.stats?.totalRecommendationRevenue ?? 0,
      group: 'recommendations',
      type: 'currency',
    },
    {
      label: t('DashboardAnalytics.recommendationPurchaseRate'),
      value: data?.stats?.recommendationPurchaseRate ?? 0,
      group: 'recommendations',
      type: 'percentage',
    },
    {
      label: t('DashboardAnalytics.totalAovWithRecommendationPurchases'),
      value: data?.stats?.totalAovWithRecommendationPurchases ?? 0,
      group: 'recommendations',
      type: 'currency',
    },
    {
      label: t('DashboardAnalytics.checkoutAttributedRevenue'),
      value:
        (data?.stats?.totalCheckoutUpsellRevenue || 0) +
        (data?.stats?.totalPostCheckoutUpsellRevenue || 0),
      group: 'checkout_upsell',
      type: 'currency',
    },
    {
      label: t('DashboardAnalytics.checkoutPurchaseRate'),
      value: data?.stats?.checkoutPurchaseRate ?? 0,
      group: 'checkout_upsell',
      type: 'percentage',
    },
    {
      label: t('DashboardAnalytics.checkoutUpsellAovIncrease'),
      value: data?.stats?.checkoutUpsellAovIncrease ?? 0,
      group: 'checkout_upsell',
      type: 'percentage',
    },
  ].forEach((option, index) => {
    const label = option.label
    let value = option.value
    switch (option.type) {
      case 'percentage':
        value = percentageFormatter(value)
        break
      case 'currency':
        value = currencyFormatter(window.shopify.data.shop.currency, value)
        break
      case 'number':
        value = numberFormater(value)
        break
    }

    const card = (
      <Grid.Cell key={index}>
        <Card>
          <BlockStack gap={'200'}>
            <Text variant="headingMd">
              <span
                style={{
                  textDecorationLine: 'underline',
                  textDecorationThickness: '2px',
                  textDecorationStyle: 'dashed',
                  textDecorationColor: 'var(--p-color-bg-fill-selected)',
                }}
              >
                {label}
              </span>
            </Text>
            <Text variant="headingLg">
              {loading ? <SkeletonDisplayText size="small" /> : value}
            </Text>
          </BlockStack>
        </Card>
      </Grid.Cell>
    )
    if (option.group === 'recommendations') {
      recoCards.push(card)
    } else {
      checkoutCards.push(card)
    }
  })

  return (
    <BlockStack gap={'300'}>
      <Grid
        columns={{
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
        }}
      >
        <Grid.Cell
          columnSpan={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          }}
        >
          <InlineStack align="start" gap={'200'}>
            <Text variant="headingMd">
              {t('DashboardAnalytics.recommendations')}
            </Text>
            <Text tone="subdued" variant="bodyMd">
              {t('DashboardAnalytics.last24Hours')}
            </Text>
          </InlineStack>
        </Grid.Cell>
        {recoCards}
      </Grid>
      <Grid
        columns={{
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
        }}
      >
        <Grid.Cell
          columnSpan={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          }}
        >
          <InlineStack align="start" gap={'200'}>
            <Text variant="headingMd">
              {t('DashboardAnalytics.checkoutUpsell')}
            </Text>
            <Text tone="subdued" variant="bodyMd">
              {t('DashboardAnalytics.last24Hours')}
            </Text>
          </InlineStack>
        </Grid.Cell>
        {checkoutCards}
      </Grid>
    </BlockStack>
  )
}
