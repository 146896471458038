import Modal from 'react-modal'
import React from 'react'
import {
  BlockStack,
  Box,
  Button,
  Divider,
  InlineGrid,
  InlineStack,
  Scrollable,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { XIcon } from '@shopify/polaris-icons'

export default function CustomModal({
  show,
  setShow,
  children,
  variant = 'max',
  title,
  onSave,
  onDiscard,
  maxHeight,
  maxWidth,
  overlayZIndex,
  saveButtonProps = {},
  height
}) {
  const { t } = useTranslation()
  return (
    <Modal
      ariaHideApp={false}
      isOpen={show}
      onAfterOpen={() => {}}
      onRequestClose={() => {
        setShow(false)
      }}
      style={{
        content: {
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          backgroundColor:
            variant === 'max'
              ? 'var(--p-color-bg)'
              : 'var(--p-color-bg-surface)',
          borderRadius:
            'var(--p-border-radius-300) var(--p-border-radius-300) 0 0',
          ...VariantToStyleMap[variant],
          maxHeight: maxHeight || VariantToStyleMap[variant].maxHeight,
          padding: 0,
          overflow: 'hidden',
          maxWidth: maxWidth || VariantToStyleMap[variant].maxWidth,
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(5px)',
          zIndex: overlayZIndex ?? 10,
        },
      }}
    >
      <div
        style={{
          borderBottom:
            'var(--p-border-width-025) solid var(--p-color-border-secondary)',
          backgroundColor:
            variant === 'max'
              ? 'var(--p-color-bg-surface)'
              : 'var(--p-color-bg)',
          borderTopLeftRadius: 'var(--p-border-radius-300)',
          borderTopRightRadius: 'var(--p-border-radius-300)',
          padding: '10px 12px 10px 12px',
        }}
      >
        <InlineGrid alignItems="center" columns={'auto 1fr auto'}>
          {title ? <Text as="strong">{title}</Text> : <div />}
          <div />
          <InlineStack blockAlign="center" gap={'300'}>
            {onDiscard && variant === 'max' && (
              <Button onClick={onDiscard}>{t('Button.discardText')}</Button>
            )}
            {onSave && variant === 'max' && (
              <Button {...saveButtonProps} onClick={onSave} variant="primary">
                {t('Button.saveText')}
              </Button>
            )}
            <Button
              icon={XIcon}
              size="slim"
              variant="plain"
              onClick={() => {
                setShow(false)
              }}
            />
          </InlineStack>
        </InlineGrid>
      </div>
      <Scrollable
        style={{
          height: height || VariantToStyleMap[variant].height,
          maxHeight: maxHeight || VariantToStyleMap[variant].maxHeight,
          maxWidth: maxWidth || VariantToStyleMap[variant].maxWidth,
        }}
      >
        <BlockStack>
          <Box padding={'0'}>{children}</Box>
        </BlockStack>
      </Scrollable>
      {variant !== 'max' && (onSave || onDiscard) && (
        <div
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'var(--p-color-bg-surface)',
          }}
        >
          <Divider />
          <Box padding={'300'}>
            <InlineGrid columns={'1fr auto'}>
              <div />
              <InlineStack blockAlign="center" gap={'300'}>
                {onDiscard && (
                  <Button onClick={onDiscard}>{t('Button.discardText')}</Button>
                )}
                {onSave && (
                  <Button
                    {...saveButtonProps}
                    onClick={onSave}
                    variant="primary"
                  >
                    {t('Button.saveText')}
                  </Button>
                )}
              </InlineStack>
            </InlineGrid>
          </Box>
        </div>
      )}
    </Modal>
  )
}

const VariantToStyleMap = {
  max: {
    width: 'calc(100vw - var(--p-space-400))',
    height: 'calc(100vh - var(--p-space-200))',
    transform: 'translateX(-50%) translateY(var(--p-space-200))',
    top: 0,
  },
  large: {
    width: '100%',
    height: 'max-content',
    maxHeight: '90vh',
    transform: 'translateX(-50%)',
    top: '5vh',
    maxWidth: '90vw',
    borderRadius: 'var(--p-border-radius-300)',
  },
  medium: {
    width: '70%',
    maxWidth: '500px',
    height: 'max-content',
    maxHeight: '50vh',
    transform: 'translateX(-50%)',
    top: '5vh',
    borderRadius: 'var(--p-border-radius-300)',
  },
}
