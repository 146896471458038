import React, { useEffect, useState } from 'react'
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Icon,
  InlineGrid,
  SkeletonBodyText,
  Text,
} from '@shopify/polaris'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  IconsFilledIcon,
  XIcon,
} from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import {
  onboardingSectionImage,
  onboardingDesignImage,
  onboardingRecommendationsImage,
  onboardingAppImage,
  onboardingPlanImage,
  onboardingFeedbackImage,
} from '../../assets'
import { updateShopConfig } from '../../apis/dashbboard.js'
import queryClient from '../../utils/query.js'
import { useRedirect } from '../../hooks/useRedirect.js'
import { getFromStorage, saveToStorage } from '../../utils/storage.js'
import { useLocalSettings } from '../LocalSettings/index.jsx'
import { PricingModal, usePricing } from '../PricingModal/index.js'

function OnboardingSteps({ props, onClose, loading: dashboardLoading }) {
  const { t } = useTranslation()
  const onboardingSteps = getOnboardingSteps({
    t,
  })
  const pricing = usePricing()
  let pendingStepIndex = -1
  const { redirectToLink } = useRedirect()
  const { settings, saveSettings } = useLocalSettings()

  const [loading, setLoading] = useState(
    onboardingSteps
      .map((step) => step.id)
      .reduce(
        (prev, curr) => ({
          ...prev,
          curr: false,
        }),
        {},
      ),
  )
  let shouldBlock = false

  Object.entries(props.onboarding.onboardingSteps ?? {})
    .sort((first, second) => {
      return first[0].localeCompare(second[0])
    })
    .forEach((step, index) => {
      if (shouldBlock) {
        return
      }
      if (step[1] === 'completed') {
        pendingStepIndex = index + 1
      } else if (step[1] === 'incomplete') {
        shouldBlock = true
      }
    })

  const completedSteps = pendingStepIndex
  pendingStepIndex = Math.max(pendingStepIndex, 0)
  const [collapsed, setCollapsed] = useState(
    completedSteps == onboardingSteps.length ||
      settings.onboardinStepsCollapsed,
  )

  useEffect(() => {
    if (completedSteps == onboardingSteps.length) {
      saveSettings({
        ...settings,
        onboardinStepsCollapsed: true,
      })
    } else {
      saveSettings({
        ...settings,
        onboardinStepsCollapsed: collapsed,
      })
    }
  }, [collapsed, pendingStepIndex])

  const content = (
    <>
      {' '}
      <Box>
        <div
          style={{
            padding: 'var(--p-space-050) var(--p-space-200)',
            borderRadius: 'var(--p-border-radius-200)',
            border: 'var(--p-border-width-050) solid var(--p-color-border)',
            width: 'max-content',
          }}
        >
          <Text>
            {Math.max(0, completedSteps)} / {onboardingSteps.length}{' '}
            {t('OnboardingSteps.completed')}
          </Text>
        </div>
      </Box>
      <Collapsible open={!collapsed}>
        <BlockStack>
          {onboardingSteps.map((step, index) =>
            props.onboarding.onboardingSteps?.[step.id] == 'completed' ? (
              <Box padding={'100'}>
                <InlineGrid
                  gap={'200'}
                  columns={'auto 1fr'}
                  alignItems="center"
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="2 2 20 20"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="10" fill="#303030"></circle>
                    <path d="M17.2738 8.52629C17.6643 8.91682 17.6643 9.54998 17.2738 9.94051L11.4405 15.7738C11.05 16.1644 10.4168 16.1644 10.0263 15.7738L7.3596 13.1072C6.96908 12.7166 6.96908 12.0835 7.3596 11.693C7.75013 11.3024 8.38329 11.3024 8.77382 11.693L10.7334 13.6525L15.8596 8.52629C16.2501 8.13577 16.8833 8.13577 17.2738 8.52629Z"></path>
                  </svg>
                  <Text>{step.title}</Text>
                </InlineGrid>
              </Box>
            ) : index === pendingStepIndex ? (
              <Bleed marginInline={'100'}>
                <Box
                  borderRadius="200"
                  background="bg-surface-brand-hover"
                  padding={'100'}
                >
                  <div
                    style={{
                      padding: '0 var(--p-space-100) 0 var(--p-space-100)',
                    }}
                  >
                    <Box padding={'050'} />
                    <InlineGrid
                      gap={'200'}
                      columns={'auto 1fr auto'}
                      alignItems="start"
                    >
                      <div
                        style={{
                          paddingTop: '3px',
                        }}
                      >
                        {PendingIcon}
                      </div>
                      <BlockStack gap={'50'}>
                        <Text as="strong">{step.title}</Text>
                        <Text>{step.description}</Text>
                        <Box padding={'100'} />
                        <Box>
                          <Button
                            loading={loading[step.id]}
                            onClick={async () => {
                              setLoading((obj) => ({
                                ...obj,
                                [step.id]: true,
                              }))
                              if (step.onClick) {
                                await step.onClick({
                                  ...props,
                                })
                                if (step.url) {
                                  setTimeout(() => {
                                    redirectToLink({
                                      url: step.url,
                                      external: step.external,
                                    })
                                  })
                                }
                              }
                              setLoading((obj) => ({
                                ...obj,
                                [step.id]: false,
                              }))
                            }}
                            variant="primary"
                          >
                            {step.btnCta}
                          </Button>
                        </Box>
                        <Box padding={'100'} />
                      </BlockStack>
                      <Box>
                        <img
                          style={{
                            width: 150,
                          }}
                          src={step.img}
                        />
                      </Box>
                    </InlineGrid>
                  </div>
                </Box>
              </Bleed>
            ) : (
              <Box padding={'100'}>
                <InlineGrid
                  gap={'150'}
                  columns={'auto 1fr'}
                  alignItems="center"
                >
                  {PendingIcon}
                  <Text>{step.title}</Text>
                </InlineGrid>
              </Box>
            ),
          )}
        </BlockStack>
      </Collapsible>
    </>
  )

  return (
    <>
      <Card>
        <BlockStack gap={'200'}>
          <InlineGrid columns={'1fr auto auto'} gap={'200'}>
            <Text as="strong">{t('OnboardingSteps.title')}</Text>
            <Button
              icon={XIcon}
              variant="plain"
              onClick={async () => {
                onClose()
                await updateShopConfig({
                  onboarding: {
                    ...props.onboarding,
                    hideOnboardingStepsBanner: true,
                  },
                })
                await queryClient.invalidateQueries(['getDashboardDetails', window.shopify.data.shopPlan])
              }}
            />
            <Button
              icon={collapsed ? ChevronDownIcon : ChevronUpIcon}
              variant="plain"
              onClick={() => setCollapsed(!collapsed)}
            />
          </InlineGrid>
          {dashboardLoading ? <SkeletonBodyText lines={2} /> : content }
        </BlockStack>
      </Card>
      <ui-modal variant="max" id="pricing-modal" src="/pricing">
        <ui-title-bar title="Pricing"></ui-title-bar>
      </ui-modal>
      <PricingModal modal={pricing} />
    </>
  )
}

export default OnboardingSteps

const getOnboardingSteps = ({ t }) => [
  {
    img: onboardingSectionImage,
    title: t('OnboardingSteps.step1.title'),
    description: t('OnboardingSteps.step1.description'),
    url: '/sections/create',
    external: false,
    btnCta: t('OnboardingSteps.step1.btnCta'),
    id: 'onboarding-step-1',
    onClick: async (props) => {
      await updateShopConfig({
        onboarding: {
          ...props.onboarding,
          onboardingSteps: {
            ...props.onboarding.onboardingSteps,
            'onboarding-step-1': 'completed',
          },
        },
      })
      await queryClient.invalidateQueries(['getDashboardDetails',window.shopify.data.shopPlan])
    },
  },
  {
    img: onboardingDesignImage,
    title: t('OnboardingSteps.step2.title'),
    description: t('OnboardingSteps.step2.description'),
    url: '/settings/templates',
    external: false,
    btnCta: t('OnboardingSteps.step2.btnCta'),
    id: 'onboarding-step-2',
    onClick: async (props) => {
      await updateShopConfig({
        onboarding: {
          ...props.onboarding,
          onboardingSteps: {
            ...props.onboarding.onboardingSteps,
            'onboarding-step-2': 'completed',
          },
        },
      })
      await queryClient.invalidateQueries(['getDashboardDetails',window.shopify.data.shopPlan])
    },
  },
  {
    img: onboardingRecommendationsImage,
    title: t('OnboardingSteps.step3.title'),
    description: t('OnboardingSteps.step3.description'),
    url: '/settings',
    external: false,
    btnCta: t('OnboardingSteps.step3.btnCta'),
    id: 'onboarding-step-3',
    onClick: async (props) => {
      await updateShopConfig({
        onboarding: {
          ...props.onboarding,
          onboardingSteps: {
            ...props.onboarding.onboardingSteps,
            'onboarding-step-3': 'completed',
          },
        },
      })
      await queryClient.invalidateQueries(['getDashboardDetails',window.shopify.data.shopPlan])
    },
  },
  {
    img: onboardingAppImage,
    title: t('OnboardingSteps.step4.title'),
    description: t('OnboardingSteps.step4.description'),
    external: false,
    btnCta: t('OnboardingSteps.step4.btnCta'),
    id: 'onboarding-step-4',
    onClick: async (props) => {
      await updateShopConfig({
        onboarding: {
          ...props.onboarding,
          onboardingSteps: {
            ...props.onboarding.onboardingSteps,
            'onboarding-step-4': 'completed',
          },
        },
      })
      document.getElementById('tut-modal').show()
      await queryClient.invalidateQueries(['getDashboardDetails',window.shopify.data.shopPlan])
    },
  },
  {
    img: onboardingPlanImage,
    title: t('OnboardingSteps.step5.title'),
    description: t('OnboardingSteps.step5.description'),
    url: '/pricing',
    external: false,
    btnCta: t('OnboardingSteps.step5.btnCta'),
    id: 'onboarding-step-5',
    onClick: async (props) => {
      await updateShopConfig({
        onboarding: {
          ...props.onboarding,
          onboardingSteps: {
            ...props.onboarding.onboardingSteps,
            'onboarding-step-5': 'completed',
          },
        },
      })
      await queryClient.invalidateQueries(['getDashboardDetails',window.shopify.data.shopPlan])
    },
  },
  {
    img: onboardingFeedbackImage,
    title: t('OnboardingSteps.step6.title'),
    description: t('OnboardingSteps.step6.description'),
    external: false,
    btnCta: t('OnboardingSteps.step6.btnCta'),
    id: 'onboarding-step-6',
    onClick: async (props) => {
      await updateShopConfig({
        onboarding: {
          ...props.onboarding,
          onboardingSteps: {
            ...props.onboarding.onboardingSteps,
            'onboarding-step-6': 'completed',
          },
        },
      })
      await queryClient.invalidateQueries(['getDashboardDetails',window.shopify.data.shopPlan])
      props.setFeedbackModalOpen(true)
    },
  },
]

const PendingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 18 18"
    fill="none"
  >
    <circle
      cx="9"
      cy="9"
      r="8.25"
      stroke="#898A8A"
      strokeWidth="1.5"
      stroke-dasharray="3 3"
    />
  </svg>
)
