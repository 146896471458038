import { BlockStack, Card, List, Text } from '@shopify/polaris'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

export default function CampaignSummary({ form }) {
  const { t } = useTranslation()
  const time = {
    start: form.fields.duration.value.start ? (
      <strong>
        {' '}
        {format(
          new Date(form.fields.duration.value.start),
          'dd MMM, yyyy',
        )}{' '}
      </strong>
    ) : null,
    end: form.fields.duration.value.end ? (
      <strong>
        {' '}
        {format(new Date(form.fields.duration.value.end), 'dd MMM, yyyy')}{' '}
      </strong>
    ) : null,
  }
  return (
    <Card>
      <BlockStack gap={'100'}>
        <Text variant="headingMd">{t('CheckoutUpsell.Summary.title')}</Text>
        <Text>
          {t('CheckoutUpsell.Summary.text', {
            status: <strong>{form.fields.status.value}</strong>,
            text:
              !time.start && !time.end
                ? t('CheckoutUpsell.Summary.infiniteText')
                : t('CheckoutUpsell.Summary.definiteTime', {
                  start: time.start || '-',
                  end: time.end || '-',
                }),
            count: (
              <strong>{`${form.fields.products.value.length}  product(s)`}</strong>
            ),
          })}
        </Text>
      </BlockStack>
    </Card>
  )
}
