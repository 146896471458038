import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  BlockStack,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris'

import ProductTagList from '../../../../components/ProductsList'
import queryClient from '../../../../utils/query'
import { useDashboardDetails } from '../../../../hooks/useDashboardDetails'
import { updateShopConfig } from '../../../../apis/dashbboard'
import { getProducts } from '../../../../apis/products'

function ExcludedProducts() {
  const { t } = useTranslation()
  const [excludedProducts, setExcludedProducts] = useState([])
  const [excludedTags, setExcludedTags] = useState([])

  const { data: dashboardData, isLoading: dashboardLoading, invalidate: invalidateDashboard } =
    useDashboardDetails()

  const { data: excludedProductsData, isLoading: excludedProductsLoading } =
    useQuery({
      queryKey: ['excludedProducts'],
      queryFn: async () => {
        const { config } = dashboardData
        const excludedProductIds = config.excludedProducts.map((id) =>
          typeof id === 'string'
            ? parseInt(id.replace('gid://shopify/Product/', ''))
            : id,
        )

        const excludedProducts = (
          await getProducts(excludedProductIds)
        ).data.products.map((p) => ({
          id: p.id,
          images: p.image ? [p.image?.src] : [],
          title: p.title,
          vendor: p.vendor,
          handle: p.handle,
        }))
        return excludedProducts
      },
      enabled: Boolean(dashboardData) && !dashboardLoading,
    })

  const updateHandler = async ({
    excludedProducts: newExcludedProducts,
    excludedTags: newExcludedTags,
  }) => {
    // have to send all data to backend as it is not a patch request
    const res = await updateShopConfig({
      excludedProducts: newExcludedProducts.map((product) =>
        typeof product.id === 'string' ? parseInt(product.id.replace('gid://shopify/Product/', '')) : product.id,
      ),
      excludedTags: newExcludedTags,
      onboarding: dashboardData.config.onboarding,
      disabledProducts: dashboardData.config.disabledProducts,
      disabledTags: dashboardData.config.disabledTags,
    })
    if (res.error) {
      console.log(res.error)
    } else {
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
      await invalidateDashboard()
      await queryClient.invalidateQueries(['excludedProducts'])
    }
  }

  useEffect(() => {
    if (dashboardData && !dashboardLoading) {
      setExcludedTags(dashboardData.config.excludedTags)
    }
  }, [dashboardData, dashboardLoading])

  useEffect(() => {
    if (excludedProductsData && !excludedProductsLoading) {
      setExcludedProducts(excludedProductsData)
    }
  }, [excludedProductsData, excludedProductsLoading])

  if (dashboardLoading || excludedProductsLoading) {
    return <Skeleton />
  }

  return (
    <ProductTagList
      separatedLayout
      productSelectionTitle={t(
        'Section.Edit.Sections.Recommendation.ExcludedProduct.title',
      )}
      productSelectionButtonCta={t(
        'Section.Edit.Sections.Recommendation.ExcludedProduct.addProductCTA',
      )}
      productSelectionDescription={t(
        'Section.Edit.Sections.Recommendation.ExcludedProduct.productDescription',
      )}
      products={excludedProducts}
      onProductSelectionChange={(products) => {
        setExcludedProducts(products)
        updateHandler({ excludedProducts: products, excludedTags })
      }}
      tagsTitle={t(
        'Section.Edit.Sections.Recommendation.ExcludedProduct.tagsTitle',
      )}
      tagsDescription={t(
        'Section.Edit.Sections.Recommendation.ExcludedProduct.tagsDescription',
      )}
      tags={excludedTags}
      onTagChange={(tags) => {
        setExcludedTags(tags)
        updateHandler({ excludedProducts, excludedTags: tags })
      }}
      queryPlaceholder={t(
        'Section.Edit.Sections.Recommendation.ExcludedProduct.tagQueryPlaceholder',
      )}
      tagBtnCta={t(
        'Section.Edit.Sections.Recommendation.ExcludedProduct.addTagBtnText',
      )}
    />
  )
}

export default ExcludedProducts

function Skeleton() {
  return (
    <BlockStack gap={'400'}>
      <Card>
        <BlockStack gap={'300'}>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </BlockStack>
      </Card>
      <Card>
        <BlockStack gap={'300'}>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </BlockStack>
      </Card>
    </BlockStack>
  )
}
