import {
  CAMPAIGN_CONDITION_CARD_TYPE,
  CAMPAIGN_TRIGGER_CONDITION_OPTIONS,
} from '../../../../../../../../constants/checkout'

export function checkTriggerGroup(triggerGroup, t) {
  if (triggerGroup.type === CAMPAIGN_CONDITION_CARD_TYPE.ATTRIBUTE) {
    let error = null
    if (
      triggerGroup.attribute === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.PRODUCTS &&
      triggerGroup.values.length === 0
    ) {
      error = t(
        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.errorText.emptyProduct',
      )
    } else if (
      triggerGroup.attribute === CAMPAIGN_TRIGGER_CONDITION_OPTIONS.DISCOUNT && triggerGroup.discount === 0
    ) {
      error = t(
        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.errorText.emptyField',
      )
    }
    return error ? {
      [triggerGroup.id] : error
    } : null
  }

  if(triggerGroup.conditions.length === 0){
    return {
      [triggerGroup.id]: t(
        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.errorText.emptyCondition',
      )
    }
  }

  let error = null

  triggerGroup.conditions.forEach((condition)=>{
    const err = checkTriggerGroup(condition, t)
    if(err){
      error = err
    }
  })

  return error
}
