import { useTranslation } from 'react-i18next'

import CustomModal from '../../CustomModal'
import Pricing from '../../../container/Pricing'

export function PricingModal({ modal, dashboardDetails }) {
  const { plans, isOpen, close, addPromoCode, changePlan } = modal
  const { t } = useTranslation()
  return (
    <CustomModal
      show={isOpen}
      setShow={() => {
        close()
      }}
      title={t('PricingModal.title')}
      variant={plans?.length === 0 ? 'medium' : 'large'}
      overlayZIndex={999}
    >
      <Pricing
        skipPage={true}
        addPromoCode={addPromoCode}
        changePlanCB={changePlan}
        plans={plans}
        dashboardDetails={dashboardDetails}
      />
    </CustomModal>
  )
}
