import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  EmptyState,
  Icon,
  InlineGrid,
  InlineStack,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonPage,
  Tag,
  Text,
  TextField,
  Tooltip,
  useBreakpoints,
} from '@shopify/polaris'
import { DeleteIcon, InfoIcon, PlusIcon, XIcon } from '@shopify/polaris-icons'
import { useField, useForm } from '@shopify/react-form'

import ProductImage from '../../../components/ProductImage/'
import { WIDGET_TYPES } from '../../../constants/widgets/'
import {
  createRule,
  deleteRule,
  getRules,
  updateRule,
} from '../../../apis/recommendation'
import { RULEBASED_OBJECT_TYPE } from '../../../constants/recommendations'
import queryClient from '../../../utils/query.js'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails.js'
import { getProducts } from '../../../apis/products.js'
import { getQueryParams } from '../../../utils/router.js'

import emptyPng from '../../../assets/empty.png'

export default function RuleBasedRecommendation({
  skipPage = false,
  widgetId,
  widgetType: wtFromProps,
}) {
  const { t } = useTranslation()
  const { widgetType: wtFromParams } = useParams()
  const widgetType = wtFromProps || wtFromParams
  const [ruleCreate, setRuleCreate] = useState(false)
  const breakpoints = useBreakpoints()
  const params = getQueryParams()

  const { data, isLoading, isError } = useQuery({
    queryFn: async () => {
      const res = await getRules({
        recommendationType: widgetType,
        section: typeof widgetId === 'string' ? parseInt(widgetId) : widgetId,
      })
      if (res.error) {
        return Promise.reject(res.error)
      }
      const { data } = res
      const populatedData = await Promise.all(
        data.rules.map(async (rule) => {
          const {
            data: { rules },
          } = rule
          const ifObject = rules[0].if
          const thenObject = rules[0].then
          if (ifObject.object_type === RULEBASED_OBJECT_TYPE.PRODUCT) {
            const { data } = await getProducts(ifObject.object_values)
            ifObject.object_values = data.products.map((prod) => ({
              id: prod.id,
              title: prod.title,
              images: prod.images.map((img) => ({
                originalSrc: img.src,
                id: img.id,
              })),
            }))
          }
          if (ifObject.object_type === RULEBASED_OBJECT_TYPE.COLLECTION) {
            ifObject.object_values = ifObject.object_values.filter(Boolean)
          }
          if (thenObject.object_type === RULEBASED_OBJECT_TYPE.PRODUCT) {
            const { data } = await getProducts(thenObject.object_values)
            thenObject.object_values = data.products.map((prod) => ({
              id: prod.id,
              title: prod.title,
              images: prod.images.map((img) => ({
                originalSrc: img.src,
                id: img.id,
              })),
            }))
          }
          if (thenObject.object_type === RULEBASED_OBJECT_TYPE.COLLECTION) {
            thenObject.object_values = thenObject.object_values.filter(Boolean)
          }
          const newRule = {
            ...rule,
            data: {
              rules: [
                {
                  if: ifObject,
                  then: thenObject,
                },
              ],
            },
          }
          return newRule
        }),
      )
      populatedData.sort((a, b) => {
        return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
      })
      return {
        rules: populatedData,
      }
    },
    queryKey: ['rules', widgetType, widgetId],
    refetchOnWindowFocus: false,
  })

  const addRuleHandler = () => {
    setRuleCreate(true)
  }

  const rules = data?.rules?.map(serialiseRule) ?? []

  if (isError) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Banner title={`Error in fetching rules`}>
          <p>Unable to fetch rules currently. Please try again later</p>
        </Banner>
      </div>
    )
  }

  if (isLoading) {
    return (
      <Skeleton title={skipPage ? null : t('RecommendationRulesPage.title')} />
    )
  }

  const Wrapper = ({ children }) =>
    !skipPage ? (
      <Page
        fullWidth
        backAction={{
          url: params.back ?? '/settings',
        }}
        title={t('RecommendationRulesPage.title')}
        titleMetadata={
          <InlineStack gap="300">
            <Badge tone="info">{WIDGET_TYPES[widgetType].label}</Badge>
          </InlineStack>
        }
        subtitle={t('RecommendationRulesPage.subtitle')}
        compactTitle
        primaryAction={
          <ButtonGroup>
            <Button variant="primary" icon={PlusIcon} onClick={addRuleHandler}>
              {t('RecommendationRulesPage.cta')}
            </Button>
          </ButtonGroup>
        }
      >
        {children}
      </Page>
    ) : (
      children
    )

  return (
    <Wrapper>
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        {skipPage && (
          <Box>
            <InlineGrid columns={'1fr auto'}>
              <div />
              <Button
                variant="primary"
                icon={PlusIcon}
                onClick={addRuleHandler}
              >
                Add New Rule
              </Button>
            </InlineGrid>
            <Box padding={'100'} />
          </Box>
        )}
        <div
          style={{
            width: '100%',
            height: '2rem',
          }}
        >
          <BlockStack gap="300">
            {rules.length === 0 && !ruleCreate && (
              <Card>
                <EmptyState
                  heading={t('RecommendationRulesPage.emptyState')}
                  action={{
                    content: t('RecommendationRulesPage.cta'),
                    onAction: () => {
                      setRuleCreate(true)
                    },
                  }}
                  image={emptyPng}
                ></EmptyState>
              </Card>
            )}
            {ruleCreate && (
              <RuleForm
                rule={{
                  conclusionType: 'tag',
                  conditionType: 'tag',
                  conclusionSelected: [],
                  conditionSelected: [],
                }}
                creation={true}
                onComplete={async (val) => {
                  const payload = {
                    ...val,
                    recommendationType: widgetType,
                    type: 'list',
                  }
                  const res = await createRule(payload)
                  if (res.error) {
                    return res.error
                  }
                  queryClient.invalidateQueries(['rules', widgetType, widgetId])
                  shopify.toast.show(
                    t('RecommendationRulesPage.createdSuccess'),
                  )
                  setRuleCreate(false)
                  return null
                }}
                onDelete={() => {
                  setRuleCreate(false)
                }}
                widgetId={widgetId}
              />
            )}
            {rules.map((rule, indx) => {
              return (
                <RuleForm
                  rule={rule}
                  key={rule.id}
                  onComplete={async (val) => {
                    const res = await updateRule(rule.id, {
                      data: val.data,
                      section: val.section,
                    })
                    if (res.error) {
                      shopify.toast.show('Smth went wrong', {
                        isError: true,
                      })
                      return res.error
                    }
                    shopify.toast.show(
                      t('RecommendationRulesPage.updateSuccess'),
                    )
                    await queryClient.invalidateQueries([
                      'rules',
                      widgetType,
                      widgetId,
                    ])
                    return null
                  }}
                  onDelete={async () => {
                    const res = await deleteRule(rule.id)
                    if (res.error) {
                      shopify.toast.show('Something went wrong', {
                        isError: true,
                      })
                    } else {
                      queryClient.invalidateQueries([
                        'rules',
                        widgetType,
                        widgetId,
                      ])
                    }
                  }}
                  widgetId={widgetId}
                />
              )
            })}
          </BlockStack>
        </div>
      </Box>
      <Box padding={'400'} />
    </Wrapper>
  )
}

function RuleForm({ rule, onDelete, onComplete, creation, widgetId }) {
  const { t } = useTranslation()
  const formFields = {
    conclusionType: useField(rule?.conclusionType ?? ''),
    conditionType: useField(rule.conditionType ?? ''),
    conclusionSelected: useField(rule.conclusionSelected ?? []),
    conditionSelected: useField(rule.conditionSelected ?? []),
    applyToWidget: useField(rule.section != null ? 'true' : 'false'),
    conditionVal: useField(rule.conditionVal ?? 'or'),
    conclusionVal: useField(rule.conclusionVal ?? 'or'),
  }

  const { fields, submit, dirty, submitting } = useForm({
    fields: formFields,
    onSubmit: async (fieldValues) => {
      const payload = deserializeRule(fieldValues, widgetId)
      const error = await onComplete(payload)
      if (error) {
        return { status: 'fail' }
      } else {
        return {
          status: 'success',
        }
      }
    },
  })
  const [conditionTagVal, setConditionTagVal] = useState([])
  const [conclusionTagVal, setConclusionTagVal] = useState([])
  const [showDelete, setShowDelete] = useState(false)

  const ConditionInputComponent =
    InputComponentMap[fields.conditionType.value] || ErrorLoadingComponent
  const ConditionSelectedComponent =
    SelectedComponentMap[fields.conditionType.value] || ErrorLoadingComponent
  const ConclusionInputComponent =
    InputComponentMap[fields.conclusionType.value] || ErrorLoadingComponent
  const ConclusionSelectedComponent =
    SelectedComponentMap[fields.conclusionType.value] || ErrorLoadingComponent

  return (
    <Card padding="400">
      <BlockStack gap="200">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Card padding="">
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                padding: 'var(--p-space-0) var(--p-space-200)',
              }}
            >
              <div
                style={{
                  flex: 1,
                  borderRight: '1px solid',
                  borderColor: 'var(--p-color-border-inverse-hover)',
                  padding: 'var(--p-space-300)',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '7px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <Text variant="headingMd" as="h5">
                      {t('RecommendationRulesPage.RuleForm.condition.title')}
                    </Text>
                    <Text variant="bodyXs" as="p" tone="subdued">
                      {t('RecommendationRulesPage.RuleForm.condition.subtitle')}
                    </Text>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '2px',
                    }}
                  >
                    <Select
                      label={t('RecommendationRulesPage.RuleForm.select.label')}
                      labelInline
                      {...formFields.conditionVal}
                      options={[
                        {
                          label: t(
                            'RecommendationRulesPage.RuleForm.select.and',
                          ),
                          value: 'and',
                        },
                        {
                          label: t(
                            'RecommendationRulesPage.RuleForm.select.or',
                          ),
                          value: 'or',
                        },
                      ]}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1.5rem',
                    alignItems: 'center',
                  }}
                >
                  <Select
                    options={[
                      {
                        label: 'Product',
                        value: 'product',
                      },
                      {
                        label: 'Tag',
                        value: 'tag',
                      },
                      {
                        label: 'Product Type',
                        value: 'product_type',
                      },
                      {
                        label: 'Collection',
                        value: 'collection',
                      },
                      {
                        label: 'Vendor',
                        value: 'vendor',
                      },
                      { label: 'Cart Price', value: 'cartPrice' },
                    ]}
                    value={fields.conditionType.value}
                    onChange={(val) => {
                      fields.conditionType.onChange(val)
                      fields.conditionSelected.onChange([])
                    }}
                  />
                  <Text as="p" fontWeight="bold">
                    {t('RecommendationRulesPage.RuleForm.condition.in')}
                  </Text>
                  <ConditionInputComponent
                    val={conditionTagVal}
                    setVal={setConditionTagVal}
                    selected={fields.conditionSelected.value}
                    onAdd={(val) => {
                      fields.conditionSelected.onChange([
                        ...fields.conditionSelected.value,
                        val,
                      ])
                    }}
                    onSubmit={(vals) => {
                      fields.conditionSelected.onChange(vals)
                    }}
                    onCartPriceAdd={(val) => {
                      fields.conditionSelected.onChange([val])
                    }}
                    cartPriceSelected={
                      fields.conditionSelected.value?.[0] ?? {
                        condition: 'gt',
                        price: null,
                      }
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  padding: '7px',
                }}
              >
                {fields.conditionSelected.value?.length === 0 ? (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text variant="headingMd" as="h6">
                      {t(
                        'RecommendationRulesPage.RuleForm.condition.emptyState',
                      )}
                    </Text>
                  </div>
                ) : (
                  <ConditionSelectedComponent
                    selected={fields.conditionSelected.value}
                    setSelected={(vals) => {
                      fields.conditionSelected.onChange(vals)
                    }}
                  />
                )}
              </div>
            </div>
          </Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: '15px',
            }}
          >
            <Arrow />
            <div style={{ width: '100%' }}>
              <Card padding="0">
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    padding: 'var(--p-space-0) var(--p-space-200)',
                    marginTop: 'var(--p-space-200)',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      borderRight: '1px solid',
                      borderColor: 'var(--p-color-border-inverse-hover)',
                      padding: 'var(--p-space-300)',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '7px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <Text variant="headingMd" as="h5">
                          {t(
                            'RecommendationRulesPage.RuleForm.conclusion.title',
                          )}
                        </Text>
                        <Text variant="bodyXs" as="p" tone="subdued">
                          {t(
                            'RecommendationRulesPage.RuleForm.conclusion.subtitle',
                          )}
                        </Text>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '2px',
                        }}
                      >
                        <Select
                          label={t(
                            'RecommendationRulesPage.RuleForm.select.label',
                          )}
                          labelInline
                          {...formFields.conclusionVal}
                          options={[
                            {
                              label: t(
                                'RecommendationRulesPage.RuleForm.select.and',
                              ),
                              value: 'and',
                            },
                            {
                              label: t(
                                'RecommendationRulesPage.RuleForm.select.or',
                              ),
                              value: 'or',
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1.5rem',
                        alignItems: 'center',
                      }}
                    >
                      <Select
                        options={[
                          {
                            label: 'Product',
                            value: 'product',
                          },
                          {
                            label: 'Tag',
                            value: 'tag',
                          },
                          {
                            label: 'Product Type',
                            value: 'product_type',
                          },
                          {
                            label: 'Collection',
                            value: 'collection',
                          },
                          {
                            label: 'Vendor',
                            value: 'vendor',
                          },
                        ]}
                        value={fields.conclusionType.value}
                        onChange={(val) => {
                          fields.conclusionType.onChange(val)
                          fields.conclusionSelected.onChange([])
                        }}
                      />
                      <Text as="p" fontWeight="bold">
                        {t('RecommendationRulesPage.RuleForm.conclusion.in')}
                      </Text>
                      <ConclusionInputComponent
                        val={conclusionTagVal}
                        setVal={setConclusionTagVal}
                        selected={fields.conclusionSelected.value}
                        onAdd={(val) => {
                          fields.conclusionSelected.onChange([
                            ...fields.conclusionSelected.value,
                            val,
                          ])
                        }}
                        onSubmit={(vals) => {
                          fields.conclusionSelected.onChange(vals)
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      padding: 'var(--p-space-200)',
                    }}
                  >
                    {fields.conclusionSelected.value?.length === 0 ? (
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text variant="headingMd" as="h6">
                          {t(
                            'RecommendationRulesPage.RuleForm.conclusion.emptyState',
                          )}
                        </Text>
                      </div>
                    ) : (
                      <ConclusionSelectedComponent
                        selected={fields.conclusionSelected.value}
                        setSelected={(vals) => {
                          fields.conclusionSelected.onChange(vals)
                        }}
                      />
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <InlineStack align="space-between">
          {widgetId ? (
            <Checkbox
              label="Apply to this section only."
              checked={formFields.applyToWidget.value === 'true'}
              onChange={(val) => {
                formFields.applyToWidget.onChange(val ? 'true' : 'false')
              }}
            />
          ) : (
            <div />
          )}
          <ButtonGroup gap="--p-space-200">
            <Button
              icon={DeleteIcon}
              onClick={() => {
                if (creation) {
                  onDelete()
                } else {
                  setShowDelete(true)
                }
              }}
            ></Button>
            <Button
              loading={submitting}
              variant="primary"
              disabled={
                !dirty ||
                fields.conditionSelected.value?.length == 0 ||
                fields.conclusionSelected.value?.length == 0
              }
              onClick={submit}
            >
              {t('RecommendationRulesPage.RuleForm.save')}
            </Button>
          </ButtonGroup>
        </InlineStack>
        {showDelete && !creation && (
          <Banner
            title={t('RecommendationRulesPage.RuleForm.DeleteBanner.title')}
            tone="critical"
          >
            <BlockStack gap="200">
              <Text>
                {t('RecommendationRulesPage.RuleForm.DeleteBanner.subtitle')}
              </Text>
              <InlineStack gap="200" align="start">
                <Button tone="critical" onClick={onDelete}>
                  {t('RecommendationRulesPage.RuleForm.DeleteBanner.delete')}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowDelete(false)
                  }}
                >
                  {t('RecommendationRulesPage.RuleForm.DeleteBanner.cancel')}
                </Button>
              </InlineStack>
            </BlockStack>
          </Banner>
        )}
      </BlockStack>
    </Card>
  )

  function ErrorLoadingComponent() {
    return (
      <Box>
        <Banner
          tone="critical"
          title="There's some error in this particular rule. Kindly contact support and don't forget to attach a screenshot of this rule."
        />
      </Box>
    )
  }
}

const InputComponentMap = {
  product: InputProduct,
  tag: InputText,
  collection: InputText,
  vendor: InputText,
  product_type: InputText,
  cartPrice: CartPriceInput,
}

const SelectedComponentMap = {
  product: ({ selected }) => <ProductList products={selected} />,
  tag: ({ selected, setSelected }) => (
    <TagsList tags={selected} setTags={setSelected} />
  ),
  collection: ({ selected, setSelected }) => (
    <TagsList tags={selected} setTags={setSelected} />
  ),
  vendor: ({ selected, setSelected }) => (
    <TagsList tags={selected} setTags={setSelected} />
  ),
  product_type: ({ selected, setSelected }) => (
    <TagsList tags={selected} setTags={setSelected} />
  ),
  cartPrice: ({ selected }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 'var(--p-space-400)',
      }}
    >
      <Text as="strong">Select cart price values</Text>
    </div>
  ),
}

function InputText({ val, setVal, onAdd }) {
  const { t } = useTranslation()
  return (
    <>
      <TextField
        value={val}
        onChange={(v) => setVal(v)}
        placeholder={t(
          'RecommendationRulesPage.RuleForm.Input.textPlaceholder',
        )}
      />
      <Button
        variant="secondary"
        icon={PlusIcon}
        onClick={() => {
          if (val.length > 0) {
            onAdd(val)
            setVal('')
          }
        }}
      >
        {t('RecommendationRulesPage.RuleForm.Input.textCTA')}
      </Button>
    </>
  )
}

function InputProduct({ selected: products, onSubmit }) {
  const { t } = useTranslation()
  return (
    <>
      <Button
        variant="secondary"
        onClick={async () => {
          try {
            const selected = await shopify.resourcePicker({
              type: 'product',
              selectionIds: products.map(({ id }) => ({ id })),
              multiple: 20,
              filter: {
                variants: false,
                archived: false,
              },
            })
            if (Array.isArray(selected)) {
              onSubmit(
                selected.map(({ id, title, images }) => ({
                  id,
                  title,
                  images,
                })),
              )
            }
          } catch (e) {
            window.shopify.toast.show(t('noProductUpdate'), {
              isError: true,
            })
          }
        }}
      >
        {t('RecommendationRulesPage.RuleForm.Input.productBtn')}
      </Button>
    </>
  )
}

function InputCollection({ selected: collections, onSubmit }) {
  const { t } = useTranslation()
  return (
    <>
      <Button
        variant="secondary"
        onClick={async () => {
          try {
            const selected = await shopify.resourcePicker({
              type: 'collection',
              selectionIds: collections.map(({ id }) => ({ id })),
              multiple: 20,
            })
            if (Array.isArray(selected)) {
              onSubmit(
                selected.map(({ id, title, handle }) => ({
                  id,
                  title,
                  value: handle,
                })),
              )
            }
          } catch (e) {
            window.shopify.toast.show(t('noProductUpdate'), {
              isError: true,
            })
          }
        }}
      >
        {t('RecommendationRulesPage.RuleForm.Input.collectionBtn')}
      </Button>
    </>
  )
}

function CartPriceInput({ onCartPriceAdd, cartPriceSelected }) {
  return (
    <InlineGrid columns={'auto auto'} gap={'200'}>
      <Select
        options={[
          {
            label: 'Greater than',
            value: 'gt',
          },
          {
            label: 'Less than',
            value: 'lt',
          },
        ]}
        value={cartPriceSelected?.condition ?? 'gt'}
        onChange={(val) => {
          onCartPriceAdd({
            condition: val,
            price: cartPriceSelected?.price ?? null,
          })
        }}
      />
      <TextField
        placeholder="Price"
        prefix={window.shopify.data.shop.currency}
        value={cartPriceSelected?.price ?? 0}
        type="number"
        onChange={(val) => {
          onCartPriceAdd({
            condition: cartPriceSelected?.condition ?? 'gt',
            price: isNaN(parseInt(val)) ? 0 : parseInt(val),
          })
        }}
      />
    </InlineGrid>
  )
}

function ProductList({ products }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        overflowX: 'auto',
        overflowY: 'clip',
        gap: 'var(--p-space-150)',
      }}
    >
      {products.map((val) => (
        <Tooltip key={val?.id} content={val?.title}>
          <ProductImage
            src={val?.images?.[0]?.originalSrc}
            style={{
              width: 50,
              height: 50,
              backgroundColor: 'var(--p-color-avatar-bg-fill)',
            }}
          />
        </Tooltip>
      ))}
    </div>
  )
}

function TagsList({ tags, setTags }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: '4rem',
        gap: '5px',
        columnGap: '5px',
        width: 'max-content',
        paddingBlockEnd: '5px',
      }}
    >
      {tags.map((tag, indx) => {
        const tagComponent = (
          <Tag
            onRemove={() => {
              setTags(tags.filter((val, ind) => ind !== indx))
            }}
          >
            {typeof tag === 'string' ? tag : tag.value}
          </Tag>
        )
        return (
          <div
            style={{
              flex: '2',
            }}
            key={indx}
          >
            {typeof tag === 'object' && tag?.title ? (
              <Tooltip content={tag.title}>{tagComponent}</Tooltip>
            ) : (
              tagComponent
            )}
          </div>
        )
      })}
    </div>
  )
}

function Arrow() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <svg
        width="2"
        height="40"
        viewBox="0 0 2 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0H1.5V40H0V0Z" fill="#B5B5B5" />
      </svg>
      <svg
        width="12"
        height="19"
        viewBox="0 0 12 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.01353e-07 0H1.5V9.95C1.5 10.8025 1.50058 11.3967 1.53838 11.8593C1.57547 12.3132 1.6446 12.574 1.74524 12.7715C1.96095 13.1948 2.30516 13.539 2.72852 13.7548C2.92604 13.8554 3.18681 13.9245 3.64068 13.9616C4.10331 13.9994 4.69755 14 5.55 14H8.93934L7.21967 12.2803C6.92678 11.9874 6.92678 11.5126 7.21967 11.2197C7.51256 10.9268 7.98744 10.9268 8.28033 11.2197L11.2803 14.2197C11.5732 14.5126 11.5732 14.9874 11.2803 15.2803L8.28033 18.2803C7.98744 18.5732 7.51256 18.5732 7.21967 18.2803C6.92678 17.9874 6.92678 17.5126 7.21967 17.2197L8.93934 15.5H5.51788C4.70505 15.5 4.04944 15.5 3.51853 15.4566C2.9719 15.412 2.49175 15.3176 2.04754 15.0913C1.34193 14.7317 0.768252 14.1581 0.408726 13.4525C0.182386 13.0082 0.0880253 12.5281 0.0433638 11.9815C-1.31503e-05 11.4506 -7.42804e-06 10.795 2.01353e-07 9.98212V0Z"
          fill="#B5B5B5"
        />
      </svg>
    </div>
  )
}

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage
      title={t('RecommendationRulesPage.title')}
      primaryAction
      fullWidth
    >
      <Card>
        <SkeletonBodyText lines={4} />
      </Card>
    </SkeletonPage>
  )
}

function serialiseRule(rule) {
  const conclusionSelected = rule.data.rules[0].then.object_values
  const conditionSelected = rule.data.rules[0].if.object_values
  const conclusionType = rule.data.rules[0].then.object_type
  const conditionType = rule.data.rules[0].if.object_type
  const conditionVal = rule.data.rules[0].if.condition
  const conclusionVal = rule.data.rules[0].then.condition

  return {
    id: rule.id,
    conclusionSelected,
    conditionSelected,
    conclusionType,
    conditionType,
    section: rule.section ?? null,
    conditionVal,
    conclusionVal,
  }
}

function deserializeRule(rule, sectionId) {
  if (rule.conditionType === RULEBASED_OBJECT_TYPE.PRODUCT) {
    rule.conditionSelected = rule.conditionSelected.map((val) =>
      typeof val.id === 'string'
        ? parseInt(val.id.replace('gid://shopify/Product/', ''))
        : val.id,
    )
  }
  if (rule.conditionType === RULEBASED_OBJECT_TYPE.COLLECTION) {
    rule.conditionSelected = rule.conditionSelected
  }
  if (rule.conclusionType === RULEBASED_OBJECT_TYPE.PRODUCT) {
    rule.conclusionSelected = rule.conclusionSelected.map((val) =>
      typeof val.id === 'string'
        ? parseInt(val.id.replace('gid://shopify/Product/', ''))
        : val.id,
    )
  }
  if (rule.conclusionType === RULEBASED_OBJECT_TYPE.COLLECTION) {
    rule.conclusionSelected = rule.conclusionSelected
  }

  return {
    id: rule.id,
    data: {
      rules: [
        {
          if: {
            object_type: rule.conditionType,
            object_values: rule.conditionSelected,
            condition: rule.conditionVal,
          },
          then: {
            object_type: rule.conclusionType,
            object_values: rule.conclusionSelected,
            condition: rule.conclusionVal,
          },
        },
      ],
    },
    section: rule.applyToWidget === 'true' ? sectionId : null,
  }
}
