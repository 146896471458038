import React, { useRef, useState } from 'react'

import WithProducts from '../components/WithProducts'
import { PROMOTION_RECOMMENDATION_TYPES } from '../../../../../../../../../constants/promotions'
import { _find, _get } from '../../../../../../../../../utils/lodash'

import CartItems from './CartItems'
import OrderUpdate from './OrderUpdate'
import ProductRow from './ProductRow'

export default function PromotionBuilderPreviewMultiProductUpsell({
  section,
  activeLocale,
  translations
}) {
  const {
    settings: { slots },
  } = section
  const [itemsAdded, setItemsAdded] = useState([])
  var automaticRecommendedCount = 0
  return (
    <div className="flex flex-row items-start justify-start max-w-[1024px] w-full py-5">
      <div className="grid w-3/4 gap-8 ">
        {slots.map((slot, index) => {
          const productIdForPreview = _get(
            slot.recommendationRules,
            'productId',
          )
          return (
            <WithProducts productIds={[productIdForPreview]} key={slot.id}>
              {({ products }) => {
                var product = products[0]

                return (
                  <ProductRow
                    activeLocale={activeLocale}
                    product={product}
                    slot={slot}
                    key={product.productId}
                    itemAdded={_find(
                      itemsAdded,
                      (item) => item.productId === product.productId,
                    )}
                    setItemsAdded={setItemsAdded}
                    index={index}
                  />
                )
              }}
            </WithProducts>
          )
        })}
      </div>
      <div className="w-1/4 pl-5">
        <CartItems itemsAdded={itemsAdded} />
        <OrderUpdate translations={translations} itemsAdded={itemsAdded} slots={section?.settings?.slots} />
      </div>
    </div>
  )
}
