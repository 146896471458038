export const CAMPAIGN_TRIGGER_OPTIONS = {
  ALL: 'all',
  CONDITIONAL: 'conditional',
}

export const CAMPAIGN_TRIGGER_CONDITION_OPTIONS = {
  PRODUCTS: 'products',
  CART: 'cart',
  DISCOUNT: 'discount',
}

export const CAMPAIGN_CONDITION_TYPE = {
  OR: 'or',
  AND: 'and',
}

export const CAMPAIGN_CONDITION_CARD_TYPE = {
  GROUP: 'group',
  ATTRIBUTE: 'attribute'
}

export const CHECKOUT_UPSELL_RECOMMENDATION = {
  DISABLED: 'disabled',
  SPECIFIC_PRODUCTS: 'specific_products',
}

export const CHECKOUT_UPSELL_SECTION_POSTIONS = [
  {
    value: 'purchase.checkout.actions.render-before',
  },
  {
    value: 'purchase.checkout.reductions.render-before',
  },
  {
    value: 'purchase.checkout.shipping-option-list.render-before',
  },
  {
    value: 'purchase.checkout.shipping-option-list.render-after',
  },
  {
    value: 'purchase.checkout.pickup-location-list.render-before',
  },
  {
    value: 'purchase.checkout.pickup-location-list.render-after',
  },
  {
    value: 'purchase.thank-you.block.render',
  },
  {
    value: 'purchase.thank-you.cart-line-item.render-after',
  },
  {
    value: 'purchase.thank-you.cart-line-list.render-after',
  },
  {
    value: 'purchase.thank-you.customer-information.render-after',
  },
  {
    value: 'customer-account.order-status.block.render',
  },
  {
    value: 'customer-account.order-status.cart-line-item.render-after',
  },
  {
    value: 'customer-account.order-status.cart-line-list.render-after',
  },
  {
    value: 'customer-account.order-status.customer-information.render-after',
  },
]

export const CAMPAIGN_STATUSES = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  PAUSED: 'paused',
  ARCHIVED: 'archived',
}

export const CHECKOUT_TYPE = {
  POST_CHECKOUT: 'post_checkout',
  CHECKOUT_UPSELL: 'checkout_upsell',
}
