import {
  BlockStack, Box, Banner, Card, InlineGrid, Layout, Select, Text, Tooltip
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import { PricingModal, usePricing } from '../../../../components/PricingModal'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'


export function PostCheckout({ form }) {
  const { t } = useTranslation()
  const pricing = usePricing()
  const postCheckoutAvailable = areFeaturesEnabled(
    [FEATURES.POST_PURCHASE],
    window.shopify.data,
  )
  return (
    <Layout.AnnotatedSection
      description={t(
        'CheckoutUpsell.CheckoutUpsellConfig.PostCheckout.description',
      )}
      title={t('CheckoutUpsell.CheckoutUpsellConfig.PostCheckout.title')}
    >
      <Card padding={'0'}>
        <Box padding={'400'}>
          <BlockStack gap={'100'}>
            <Text as="strong">
              {t('CheckoutUpsell.CheckoutUpsellConfig.PostCheckout.card.title')}
            </Text>
            <InlineGrid columns={'1fr auto'} gap={'400'}>
              <Text>
                {t(
                  'CheckoutUpsell.CheckoutUpsellConfig.PostCheckout.card.description',
                )}
              </Text>
              <Box>
                {(() => {
                  const select = (
                    <Select
                      label={t(
                        'CheckoutUpsell.CheckoutUpsellConfig.PostCheckout.card.form.enable.label',
                      )}
                      labelInline
                      onChange={(value) => {
                        if (!postCheckoutAvailable) {
                          pricing.open({
                            features: [FEATURES.POST_PURCHASE],
                          })
                          return
                        }
                        form.fields.postCheckoutEnable.onChange(value == 'true')
                      }}
                      value={form.fields.postCheckoutEnable.value ? 'true' : 'false'}
                      options={[
                        {
                          label: t(
                            'CheckoutUpsell.CheckoutUpsellConfig.PostCheckout.card.form.enable.options.enabled',
                          ),
                          value: 'true',
                        },
                        {
                          label: t(
                            'CheckoutUpsell.CheckoutUpsellConfig.PostCheckout.card.form.enable.options.disabled',
                          ),
                          value: 'false',
                        },
                      ]}
                    />
                  )

                  if (!postCheckoutAvailable) {
                    return (
                      <Tooltip content={t('postCheckoutNotAvailable')}>
                        {select}
                      </Tooltip>
                    )
                  }

                  return select
                })()}
              </Box>
            </InlineGrid>
            {!postCheckoutAvailable && (
              <Banner
                tone="warning"
                title={t('postCheckoutNotAvailable')}
                action={{
                  content: t('DefaultText.showPricing'),
                  onAction: () => {
                    pricing.open({
                      features: [FEATURES.POST_PURCHASE],
                    })
                  },
                }}
              ></Banner>
            )}
          </BlockStack>
        </Box>
        <PricingModal modal={pricing} />
      </Card>
    </Layout.AnnotatedSection>
  )
}
