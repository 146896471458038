import { useQuery } from "react-query"

import { getTemplates } from "../apis/template"

export function useTemplates() {
  const { data: { templates = [] } = {}, isLoading: templateLoading } =
    useQuery({
      queryFn: async () => {
        const response = await getTemplates()
        if (response.error) {
          return Promise.reject(response.error)
        }
        return response.data
      },
      queryKey: ['templates'],
      refetchOnWindowFocus: false,
    })

  return { templates, templateLoading }
}
