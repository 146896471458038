import React from 'react'
import {
  BlockStack,
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Select,
  TextField,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import * as RANKING_CRITERIA from '../../../../constants/widgets/rankingCriteria'
import * as FILTER_CRITERIA from '../../../../constants/widgets/filterCriteria'
import * as FALLBACK_CRITERIA from '../../../../constants/widgets/fallbackCriteria'
import { SIMILAR_PRODUCTS } from '../../../../constants/widgets'

function AdvanceConfig({ form, showPricePercentageThreshold }) {
  const { t } = useTranslation()

  return (
    <Layout.AnnotatedSection
      id="advance"
      title={t('Section.Edit.Sections.AdvanceConfig.title')}
      description={t('Section.Edit.Sections.AdvanceConfig.description')}
    >
      <Card>
        <BlockStack gap={'200'}>
          <FormLayout>
            <FormLayout.Group>
              {showPricePercentageThreshold && (
                <TextField
                  type="number"
                  min={0}
                  max={999}
                  label={t(
                    'Section.Edit.Sections.AdvanceConfig.FormFields.PPT.label',
                  )}
                  helpText={t(
                    'Section.Edit.Sections.AdvanceConfig.FormFields.PPT.helpText',
                  )}
                  {...form.fields.pricePercentageThreshold}
                />
              )}
              <Select
                label={t(
                  'Section.Edit.Sections.AdvanceConfig.FormFields.PRC.label',
                )}
                helpText={t(
                  'Section.Edit.Sections.AdvanceConfig.FormFields.PRC.helpText',
                )}
                {...form.fields.productRankingCriteria}
                options={Object.values(RANKING_CRITERIA)}
              />
            </FormLayout.Group>
          </FormLayout>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label={t(
                  'Section.Edit.Sections.AdvanceConfig.FormFields.PFC.label',
                )}
                helpText={form.fields.widgetType !== SIMILAR_PRODUCTS.value ? t('Section.Edit.Sections.AdvanceConfig.FormFields.PFC.available') :t(
                  'Section.Edit.Sections.AdvanceConfig.FormFields.PFC.helpText',
                )}
                {...form.fields.productFilterCriteria}
                options={Object.values(FILTER_CRITERIA)}
                disabled={form.fields.widgetType !== SIMILAR_PRODUCTS.value}
              />
              <Select
                label={t(
                  'Section.Edit.Sections.AdvanceConfig.FormFields.FC.label',
                )}
                helpText={t(
                  'Section.Edit.Sections.AdvanceConfig.FormFields.FC.helpText',
                )}
                {...form.fields.fallbackCriteria}
                options={Object.values(FALLBACK_CRITERIA)}
              />
            </FormLayout.Group>
          </FormLayout>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                type="number"
                min={0}
                max={99999}
                label={t(
                  'Section.Edit.Sections.AdvanceConfig.FormFields.MinPrice.label',
                )}
                {...form.fields.minPrice}
              />
              <TextField
                type="number"
                min={0}
                max={99999}
                label={t(
                  'Section.Edit.Sections.AdvanceConfig.FormFields.MaxPrice.label',
                )}
                {...form.fields.maxPrice}
              />
            </FormLayout.Group>
          </FormLayout>
        </BlockStack>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default AdvanceConfig
