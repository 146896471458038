import getApiClient from '.'

export async function getPlans(filter = {}) {
  const client = await getApiClient()
  try {
    const res = await client.get('/api/app/plans', {
      params: {
        promo: filter?.promo,
        plan: filter?.plan,
      },
    })
    return {
      error: null,
      data: res.data.plans,
    }
  } catch (e) {
    return {
      data: null,
      error: e,
    }
  }
}

export async function changePlan({ plan, promo = '', onAnnual } = {}) {
  const client = await getApiClient()
  try {
    const res = await client.post('/api/app/plans/change', {
      plan,
      promo,
      onAnnual,
    })
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}

export async function confirmPlan(chargeId) {
  const client = await getApiClient()
  try {
    const res = await client.post('/api/app/plans/confirm', {
      chargeId,
    })
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      data: null,
      error: e.response.data,
    }
  }
}
