import { useForm } from '@shopify/react-form'
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Grid,
  InlineStack,
  Link,
} from '@shopify/polaris'
import { useEffect, useMemo, useState } from 'react'
import { useContextualSaveBar } from '@shopify/app-bridge-react'

import useFormFields from './form/hooks/useFormFields'
import {
  GeneralInfo,
  TriggerCard,
  ProductSelector,
  CampaignStatusSelector,
  CampaignSummary,
  PostCheckoutSectionConfig,
} from './sections'
import { t } from 'i18next'
import { DeleteIcon } from '@shopify/polaris-icons'

import {
  PricingModal,
  usePricing,
} from '../../../../../../components/PricingModal'

export default function CampaignUpsertForm({
  currentValues = {},
  onSubmit,
  checkoutConfig,
  onDelete,
  mode = 'create',
  campaignId = null,
  refreshData,
}) {
  const [deleting, setDeleting] = useState(false)
  const savebar = useContextualSaveBar()
  const pricing = usePricing()
  const form = useForm({
    fields: useFormFields(currentValues),
    onSubmit: async (value) => {
      try {
        await onSubmit(value, savebar.hide)
        return {
          status: 'success',
        }
      } catch (error) {
        return {
          status: 'fail',
          errors: [
            {
              message: error.message,
            },
          ],
        }
      }
    },
  })

  const commonProps = useMemo(
    () => ({
      form,
      dashboardData: window.shopify.data,
      config: checkoutConfig,
      mode,
      refreshData,
    }),
    [form],
  )

  useEffect(() => {
    savebar.saveAction.setOptions({
      loading: form.submitting,
      disabled: form.submitting,
      onAction: form.submit,
    })
    savebar.discardAction.setOptions({
      onAction: form.reset,
    })
  }, [form.submitting])

  useEffect(() => {
    if (form.submitErrors.length > 0) {
      window.shopify.toast.show(form.submitErrors[0].message, {
        isError: true,
      })
    }
  }, [form.submitErrors])

  useEffect(() => {
    if (form.dirty) {
      savebar.show()
    } else {
      savebar.hide()
    }
  }, [form.dirty])

  return (
    <BlockStack gap={'300'}>
      <Grid
        columns={{
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
        }}
      >
        <Grid.Cell
          columnSpan={{
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
          }}
        >
          <BlockStack gap={'300'}>
            <GeneralInfo {...commonProps} />
            <TriggerCard {...commonProps} />
            <ProductSelector {...commonProps} />
            <PostCheckoutSectionConfig
              campaignId={campaignId}
              {...commonProps}
            />
          </BlockStack>
        </Grid.Cell>
        <Grid.Cell
          columnSpan={{
            xs: 2,
            sm: 2,
            md: 1,
            lg: 1,
            xl: 1,
          }}
        >
          <BlockStack gap={'300'}>
            <CampaignStatusSelector {...commonProps} />
            <CampaignSummary {...commonProps} />
          </BlockStack>
        </Grid.Cell>
        <Grid.Cell
          columnSpan={{
            sm: 2,
            xs: 2,
            md: 3,
            lg: 3,
            xl: 3,
          }}
        >
          <InlineStack align="end" gap={'100'}>
            {typeof onDelete === 'function' && (
              <Button
                loading={deleting}
                onClick={() => {
                  setDeleting(true)
                  onDelete().finally(() => {
                    setDeleting(false)
                  })
                }}
                tone="critical"
                variant="primary"
                icon={DeleteIcon}
              >
                {t('CheckoutUpsell.CampaignDetail.delete')}
              </Button>
            )}
            <Button
              loading={form.submitting}
              onClick={() => {
                form.submit()
              }}
              variant="primary"
              disabled={!form.dirty}
            >
              {t('DefaultText.save')}
            </Button>
          </InlineStack>
          <Box padding={'200'} />
        </Grid.Cell>
      </Grid>
      <PricingModal modal={pricing} />
    </BlockStack>
  )
}
