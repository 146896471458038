import React from 'react'
import { v4 as uuid } from 'uuid'

export const PROMOTION_RECOMMENDATION_TYPES = {
  AUTOMATIC: {
    label: 'Based on past orders',
    value: 'automatic',
    help: 'By default automatic recommendations are shown to users based on their usage',
  },
  MANUAL: {
    label: 'Manual',
    value: 'manual',
  },

  PR: {
    label: 'Personalized Recommendations',
    value: 'pr',
    help: 'Recommendations are fetched from Personalized Recommendations app',
    visible: () => false,
  },

  METAFIELDS: {
    label: 'Pick from Meta-fields',
    value: 'metafields',
    help: () => {},
    visible: () => false,
  },
}

export const DISCOUNT_TYPES = {
  PERCENTAGE: {
    label: '%',
    value: 'PERCENTAGE',
  },
  FLAT: {
    label: '$',
    value: 'AMOUNT',
  },
}