import { useQuery } from 'react-query'
import { useEffect } from 'react'
import { setUser } from '@sentry/react'

import { getShopStatus } from '../apis/status'
import queryClient from '../utils/query'

const QUERY_KEY = ['shop_status'],
  BLACKLISTED_PATHS = ['/charge/confirm']

export function useShopStatus() {
  const query = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => {
      const { data, error } = await getShopStatus()
      window.shopify.data = data
      if (error) {
        console.log('ERROR:ShopStatusChecker', error)
        return Promise.reject({
          reloadRequired: true,
        })
      } else if (!data?.shop || !data?.user || !data?.shop?.isEnabled) {
        return Promise.reject({ reloadRequired: true })
      }
      return data
    },
    refetchOnWindowFocus: false,
    enabled: !BLACKLISTED_PATHS.includes(window.location.pathname),
  })

  useEffect(() => {
    const data = query.data
    if (process.env.NODE_ENV !== 'development' && query.isSuccess && data) {
      setUser({
        username: data.shop.myshopifyDomain,
        email: data.user.email,
      })
    }
  }, [query.isSuccess, query.data])

  return {
    isLoading: query.isLoading || query.isRefetching || query.isFetching,
    error: query.error,
    refetch: query.refetch,
  }
}

export function invalidateShopStatus() {
  return queryClient.invalidateQueries(QUERY_KEY)
}
