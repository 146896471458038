import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Icon,
  InlineGrid,
  RadioButton,
  Select,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris'
import React, { useEffect } from 'react'
import { InfoIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import {
  CAMPAIGN_CONDITION_CARD_TYPE,
  CAMPAIGN_CONDITION_TYPE,
  CAMPAIGN_TRIGGER_CONDITION_OPTIONS,
  CAMPAIGN_TRIGGER_OPTIONS,
} from '../../../../../../../../constants/checkout'
import TriggerConditionCard from './components/TriggerconditionsCards'
import {
  deleteFromGroupById,
  getDefaultAttribute,
  getDefaultGroup,
  insertIntoGroup,
  updateCondition,
} from './utils'

function TriggerCard({ form, dashboardData }) {
  const { t } = useTranslation()
  const [trigger] = [form.fields.triggerType.value]
  const [triggerGroup, setTriggerGroup, triggerError] = [
    form.fields.triggerGroup?.value,
    form.fields.triggerGroup?.onChange,
    form.fields.triggerGroup?.error,
  ]

  const triggerOptions = [
    {
      label: t('CheckoutUpsell.CampaignDetail.TriggerCard.option.all'),
      value: CAMPAIGN_TRIGGER_OPTIONS.ALL,
      name: 'trigger',
    },
    {
      label: t('CheckoutUpsell.CampaignDetail.TriggerCard.option.conditional'),
      value: CAMPAIGN_TRIGGER_OPTIONS.CONDITIONAL,
      name: 'trigger',
    },
  ]

  useEffect(() => {
    if (trigger === CAMPAIGN_TRIGGER_OPTIONS.CONDITIONAL) {
      if (!triggerGroup.id) {
        const grp = getDefaultGroup()
        setTriggerGroup(grp)
      }
    }
  }, [form.fields.triggerType.value])

  return (
    <Card padding={'0'}>
      <BlockStack>
        <Box padding={'400'}>
          <BlockStack gap={'100'}>
            <Text variant="headingMd">
              {t('CheckoutUpsell.CampaignDetail.TriggerCard.title')}
            </Text>
            {triggerOptions.map((option) => (
              <RadioButton
                key={option.value}
                label={option.label}
                helpText={''}
                checked={option.value === trigger}
                name={option.name}
                onChange={(val) => {
                  if (val) {
                    form.fields.triggerType.onChange(option.value)
                  }
                }}
                id={'glood_' + option.value}
              />
            ))}
          </BlockStack>
        </Box>
        {trigger === CAMPAIGN_TRIGGER_OPTIONS.CONDITIONAL && (
          <>
            <Divider />
            <Box padding={'400'}>
              <BlockStack gap={'300'}>
                {triggerGroup.id && (
                  <TriggerConditionCard
                    error={triggerError} // change this later
                    condition={triggerGroup}
                    dashboardData={dashboardData}
                    onGroupAdd={(parentGroupId) => {
                      const newGroup = getDefaultGroup()
                      setTriggerGroup(
                        insertIntoGroup(triggerGroup, newGroup, parentGroupId),
                      )
                    }}
                    onConditionAdd={(parentGroupId) => {
                      const newCondition = getDefaultAttribute()
                      setTriggerGroup(
                        insertIntoGroup(
                          triggerGroup,
                          newCondition,
                          parentGroupId,
                        ),
                      )
                    }}
                    onChange={(condition) => {
                      const copy = { ...triggerGroup }
                      setTriggerGroup(
                        updateCondition(copy, condition.id, condition),
                      )
                    }}
                    deleteCondition={(condition) => {
                      const copy = { ...triggerGroup }
                      setTriggerGroup(
                        deleteFromGroupById(copy, condition.id),
                      )
                    }}
                    deleteGroup={(group) => {
                      const copy = { ...triggerGroup }
                      setTriggerGroup(
                        deleteFromGroupById(copy, group.id),
                      )
                    }}
                    nesting={1}
                  />
                )}
                {triggerGroup.conditions?.length === 0 && (
                  <Banner>
                    <p>
                      {t(
                        'CheckoutUpsell.CampaignDetail.TriggerCard.triggerCondition.selector.emptyText',
                      )}
                    </p>
                  </Banner>
                )}
              </BlockStack>
            </Box>
          </>
        )}
      </BlockStack>
    </Card>
  )
}

export default TriggerCard
