import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import _find from 'lodash.find'
import { useTranslation } from 'react-i18next'

import Banner from './Banner'
import SingleProductUpsell from './SingleProductUpsell'
import Text from './Text'
import Image from './Image'
import MultiProductUpsell from './MultiProductUpsell'

export default function PromotionBuilderLayoutPreview({
  layout,
  readOnly,
  activeLocale,
  translations
}) {
  const { data } = layout
  const { t } = useTranslation()

  return (
    <div
      className={classNames('relative flex flex-1 min-h-0 bg-white', {
        rounded: readOnly,
      })}
    >
      <div className="flex-1 overflow-y-auto">
        <div
          className={classNames('flex flex-col items-stretch flex-1', {
            'mb-20': !readOnly,
          })}
        >
          <header className="flex flex-col items-center mb-6">
            <div className="flex flex-row w-full max-w-[1024px] px-8 py-3 border-b border-gray-200">
              <span className="flex items-center justify-center w-6 h-6 mt-2 mr-3 text-blue-600 border-2 border-blue-600 rounded-full ">
                <TickIcon />
              </span>
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-800">
                  {t('PostCheckoutEditor.Previw.paid_for_order')}
                </span>
                <span className="flex flex-row text-xs font-semibold text-blue-600">
                  {t('PostCheckoutEditor.Previw.view_order_confirmation')}{' '}
                  <CheveronRight />
                </span>
              </div>
            </div>
          </header>
          <div className="flex flex-col items-stretch">
            {(data.sections || []).map((section, index) => {
              const SectionComponent =
                SECTION_TYPE_TO_COMPONENT_MAP[section.type]
              return SectionComponent ? (
                <div
                  key={index}
                  className={classNames('flex flex-col items-center', {
                    'mb-5': index < data.sections.length - 1,
                  })}
                >
                  <SectionComponent
                    section={section}
                    activeLocale={activeLocale}
                    translations={translations}
                  />
                </div>
              ) : null
            })}
          </div>
          <footer className="flex flex-col items-center my-8">
            <div className="flex flex-row w-full border-t border-gray-200 max-w-[1024px] pt-4 space-x-3 px-8">
              <span className="flex flex-row text-xs font-semibold text-blue-600">
                {t('PostCheckoutEditor.Previw.privacy_policy')}
              </span>
              <span className="flex flex-row text-xs font-semibold text-blue-600">
                {t('PostCheckoutEditor.Previw.terms_of_service')}
              </span>
              <span className="flex flex-row text-xs font-semibold text-blue-600">
                {t('PostCheckoutEditor.Previw.refund_policy')}
              </span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

const TickIcon = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5l3.93 3.93a.1.1 0 00.14 0L13 1"
      stroke-width="2"
      className="w-2.5 stroke-current"
      stroke-linecap="round"
    />
  </svg>
)

const CheveronRight = () => (
  <svg
    className="w-1.5 ml-2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
      clip-rule="evenodd"
    />
  </svg>
)

const SECTION_TYPE_TO_COMPONENT_MAP = {
  'banner': Banner,
  'single_product':
    SingleProductUpsell,
  'multiple_products':
    MultiProductUpsell,
}
