import React from 'react'
import classNames from 'classnames'

import Line from '../Line'

export default function PromotionBuilderPreviewBanner({ section }) {
  const {
    settings: { general, line },
  } = section
  const { background, alignment, border, layout, spacing } = general
  return (
    <div
      className={classNames('flex flex-col py-5 px-8', {
        'bg-gray-100': background === 'secondary',
        'border-b border-t border-gray-200': border === 'block',
        'self-center': layout === 'center',
        'self-stretch': layout === 'full_width',
      })}
    >
      <div
        className={classNames(
          'flex flex-col max-w-[1024px] w-full mx-auto',
          SPACING_TO_CLASS_MAP[spacing],
          {
            'items-center': alignment === 'center',
            'items-start': alignment === 'leading',
            'items-end': alignment === 'trailing',
          },
        )}
      >
        <Line line={line} standAlone={false} />
      </div>
    </div>
  )
}

const SPACING_TO_CLASS_MAP = {
  xtight: 'space-y-0.5',
  tight: 'space-y-2',
  loose: 'space-y-3.5',
  xloose: 'space-y-5',
}
