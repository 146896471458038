import {
  Banner,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormLayout,
  Layout,
  Select,
  TextField,
  BlockStack,
  List,
  Link,
  Text,
  Label,
  InlineError,
  SkeletonDisplayText,
  InlineStack,
  InlineGrid,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import { PAGE_TYPES, PRODUCT } from '../../../../constants/page'
import { AMAZON_BOUGHT_TOGETHER } from '../../../../constants/layouts'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'
import CodeEditor from '../../../../components/CodeEditor'
import { useSectionDiscount } from '../../../../hooks/useSectionDiscount'
import { openChat } from '../../../../components/ExternalScripts'
import TranslationModal from '../../../../components/TranslationsModal'
import { currencyFormatter, formatCurrency, getCurrencySymbol } from '../../../../utils/formater'

export default function SectionDiscountConfig({
  form,
  pricing,
  dashbaordData,
}) {
  const { t } = useTranslation()
  const featureAvalaible = areFeaturesEnabled(
    [FEATURES.DISCOUNTS],
    dashbaordData,
  )
  const automaticDiscount = useSectionDiscount(dashbaordData, featureAvalaible)

  form.fields.enableBundleDiscounts = form.fields.enableBundleDiscounts || {}
  form.fields.discountType = form.fields.discountType || {}
  form.fields.discountValue = form.fields.discountValue || {}
  form.fields.discountMessage = form.fields.discountMessage || {}

  const amazonAndPDPPage =
    form.fields.page.value === PRODUCT.value &&
    form.fields.layout.value === AMAZON_BOUGHT_TOGETHER.value

  return (
    <Layout.AnnotatedSection
      id="discount-config"
      description={t('Section.Edit.Sections.BundleConfig.description')}
      title={t('Section.Edit.Sections.BundleConfig.title')}
    >
      <Card padding={'0'}>
        <Box padding={'400'}>
          <BlockStack gap={'200'}>
            {!featureAvalaible && (
              <Banner
                title={t('Features.notAvailableInPlanText', {
                  feature: t('Features.labels.discounts'),
                  cta: (
                    <Button
                      variant="plain"
                      onClick={() => {
                        pricing.open({
                          features: [FEATURES.DISCOUNTS],
                        })
                      }}
                    >
                      {t('DefaultText.upgradeText')}
                    </Button>
                  ),
                })}
                status="info"
              />
            )}
            {automaticDiscount.loading ? (
              <SkeletonDisplayText size="medium" maxWidth="100%" />
            ) : (
              !automaticDiscount.active &&
              form.fields.discountVersion.value == 2 &&
              featureAvalaible && (
                <Banner
                                tone="warning"
                  title={t(
                    'Section.Edit.Sections.DiscountConfig.banner.title',
                    {
                      cta: (
                        <Link onClick={openChat}>
                          {t('DefaultText.contactSupport')}
                        </Link>
                      ),
                    },
                  )}
                  status="warning"
                />
              )
            )}
            {featureAvalaible && form.fields.discountVersion == 1 && (
              <Banner
                title={t('Section.Edit.Sections.DiscountConfig.olderVersion', {
                  cta: (
                    <Link onClick={openChat}>
                      {t('DefaultText.contactSupport')}
                    </Link>
                  ),
                })}
              />
            )}
          </BlockStack>
          <Box padding={'100'} />
          <BlockStack gap={'200'}>
            {amazonAndPDPPage && (
              <>
                <BlockStack gap={'200'}>
                  {(() => {
                    const items = []
                    const points = t(
                      'Section.Edit.Sections.BundleConfig.Implications.Points',
                    )
                    Object.values(points).forEach((point, index) => {
                      items.push(<Text key={index}>{point}</Text>)
                    })
                    return items
                  })()}
                  <Text>
                    {t('sectionDiscountHelpText', {
                      codes: (
                        <span>
                          <code>__glood_attr</code>,{' '}
                          <code>___glood_source</code>
                          {', '}
                          <code>__glood_section</code>,{' '}
                          <code>__glood_parent</code>
                          {' and '}
                          <code>__glood_recommended</code>
                        </span>
                      ),
                    })}
                  </Text>
                </BlockStack>
              </>
            )}
            <FormLayout>
              <FormLayout>
                <Checkbox
                  disabled={!featureAvalaible}
                  {...form.fields.enableBundleDiscounts}
                  label={t(
                    'Section.Edit.Sections.DiscountConfig.form.enabled.label',
                  )}
                />
              </FormLayout>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    options={DISCOUNT_TYPE}
                    {...form.fields.discountType}
                    label={t(
                      'Section.Edit.Sections.DiscountConfig.form.type.label',
                    )}
                    disabled={
                      !featureAvalaible ||
                      !form.fields.enableBundleDiscounts.checked
                    }
                  />
                  <TextField
                    disabled={
                      !form.fields.discountType.value ||
                      !form.fields.enableBundleDiscounts.checked ||
                      !featureAvalaible
                    }
                    type="number"
                    label={t(
                      'Section.Edit.Sections.DiscountConfig.form.value.label',
                    )}
                    {...form.fields.discountValue}
                    suffix={
                      form.fields.discountType.value === 'PERCENTAGE'
                        ? '%'
                        : getCurrencySymbol(window.shopify.data.shop.currency)
                    }
                    placeholder={t(
                      'Section.Edit.Sections.DiscountConfig.form.value.placeholder',
                    )}
                  />
                </FormLayout.Group>
              </FormLayout>
              {amazonAndPDPPage && (
                <FormLayout>
                  <Checkbox
                    disabled={
                      !featureAvalaible ||
                      !form.fields.enableBundleDiscounts.checked
                    }
                    {...form.fields.applyDiscountOnlyToRec}
                    label={t(
                      'Section.Edit.Sections.DiscountConfig.form.applyDiscountOnlyToRec.label',
                    )}
                  />
                </FormLayout>
              )}
              {featureAvalaible &&
                form.fields.enableBundleDiscounts.checked && (
                  <>
                    <FormLayout>
                      <InlineGrid
                        columns={'1fr auto'}
                        alignItems="center"
                        gap={'300'}
                      >
                        <TextField
                          label={t(
                            'Section.Edit.Sections.DiscountConfig.form.discountMessageObject.label',
                          )}
                          {...form.fields.discountMessage}
                          disabled={
                            !featureAvalaible ||
                            !form.fields.enableBundleDiscounts.checked
                          }
                          type="text"
                          placeholder={t(
                            'Section.Edit.Sections.DiscountConfig.form.discountMessageObject.placeholder',
                          )}
                          helpText={t(
                            'Section.Edit.Sections.DiscountConfig.form.discountMessageObject.helpText',
                            {
                              variables: (
                                <span>
                                  {'{amount}, {percent} and {product_title}'}
                                </span>
                              ),
                            },
                          )}
                        />
                        <Box>
                          <TranslationModal
                            disabled={!featureAvalaible}
                            primaryTextField={form.fields.discountMessage}
                            translationField={form.fields.discountMessageObject}
                            pricing={pricing}
                            dashboardData={dashbaordData}
                          />
                        </Box>
                      </InlineGrid>
                    </FormLayout>
                  </>
                )}
              {featureAvalaible &&
                form.fields.enableBundleDiscounts.checked && (
                  <FormLayout>
                    <InlineGrid
                      columns={'1fr auto'}
                      alignItems="center"
                      gap={'300'}
                    >
                      <TextField
                        label={t(
                          'Section.Edit.Sections.DiscountConfig.form.widgetSubtitle.label',
                        )}
                        {...form.fields.discountSubtitle}
                        disabled={
                          !featureAvalaible ||
                          !form.fields.enableBundleDiscounts.checked
                        }
                        type="text"
                        placeholder={t(
                          'Section.Edit.Sections.DiscountConfig.form.widgetSubtitle.placeholder',
                        )}
                        helpText={t(
                          'Section.Edit.Sections.DiscountConfig.form.widgetSubtitle.helpText',
                          {
                            variables: <span>{'{amount} and {percent}'}</span>,
                          },
                        )}
                      />
                      <Box>
                        <TranslationModal
                          disabled={!featureAvalaible}
                          primaryTextField={form.fields.discountSubtitle}
                          translationField={
                            form.fields.discountSubtitleTranslated
                          }
                          pricing={pricing}
                          dashboardData={dashbaordData}
                        />
                      </Box>
                    </InlineGrid>
                  </FormLayout>
                )}
            </FormLayout>
          </BlockStack>
        </Box>
      </Card>
    </Layout.AnnotatedSection>
  )
}

const DISCOUNT_TYPE = [
  { label: 'Amount', value: 'AMOUNT' },
  { label: 'Percentage', value: 'PERCENTAGE' },
]
