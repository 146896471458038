import {
  BlockStack,
  Box,
  Card,
  FormLayout,
  IndexTable,
  InlineGrid,
  InlineStack,
  Layout,
  Select,
  Text,
  TextField,
  useBreakpoints,
  Avatar,
  Button,
} from '@shopify/polaris'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { currencyFormatter } from '../../../../../utils/formater'

const PAGE_SIZE = 10

export default function DiscountSection({ form }) {
  const [page, setPage] = useState(1)
  const breakpoints = useBreakpoints()
  const bundleVariants = form.fields.bundleVariants.value
  const pagedBundleVariants = useMemo(() => {
    return bundleVariants.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
  }, [page, bundleVariants])

  const { t } = useTranslation()

  const productIdMap = form.fields.products.value.reduce((acc, product) => {
    acc[product.id] = product
    return acc
  }, {})

  const columnHeading = [
    { title: t('BundlesUpsert.IndexTable.columns.0'), id: 'variants' },
    {
      title: t('BundlesUpsert.IndexTable.columns.4'),
      id: 'price',
    },
  ]

  if (form.fields.discountType.value !== DISCOUNT_OPTIONS.NO_DISCOUNT) {
    columnHeading.splice(2, 0, {
      title: t('BundlesUpsert.IndexTable.columns.5'),
      id: 'compareAtPrice',
    })
  }

  const variantDiscount = form.fields?.variantDiscount?.value ?? {}
  const rowMarkup = useMemo(() => {
    return createRowMarkup(
      groupRow(pagedBundleVariants),
      form.fields.products.value,
    )
  }, [
    pagedBundleVariants,
    form.fields.products.value,
    variantDiscount,
    form.fields.discountType.value,
    form.fields?.bundleDiscount?.value,
  ])

  return (
    <Card padding={'0'}>
      <Box padding={'400'}>
        <BlockStack gap={'300'}>
          <BlockStack gap={'50'}>
            <Text variant="headingSm">
              {t('BundlesUpsert.Discount.card.title')}
            </Text>
            <Text>{t('BundlesUpsert.Discount.card.description')}</Text>
          </BlockStack>
          <FormLayout>
            <FormLayout.Group>
              <Select
                {...form.fields.discountType}
                options={Object.values(DISCOUNT_OPTIONS).map((val) => ({
                  label: t(`BundlesUpsert.Discount.card.options.${val}`),
                  value: val,
                }))}
              />
            </FormLayout.Group>
          </FormLayout>
          <FormLayout>
            <TextField
              {...form.fields.bundleDiscount}
              suffix="%"
              disabled={
                form.fields.discountType.value !==
                DISCOUNT_OPTIONS.BUNDLE_DISCOUNT
              }
              type='number'
              label={t('BundlesUpsert.Discount.card.bundleDiscount.label')}
            />
          </FormLayout>
        </BlockStack>
      </Box>
      <IndexTable
        condensed={breakpoints.smDown}
        selectable={false}
        resourceName={{
          singular: t('BundlesUpsert.IndexTable.resourceName.singular'),
          plural: t('BundlesUpsert.IndexTable.resourceName.plural'),
        }}
        itemCount={bundleVariants.length}
        headings={columnHeading}
        pagination={{
          hasPrevious: page > 1,
          hasNext: page * PAGE_SIZE < bundleVariants.length,
          onPrevious: () => setPage(page - 1),
          onNext: () => setPage(page + 1),
        }}
        emptyState={
          <Box padding={'400'}>
            <InlineStack align="center">
              <Text as="strong">{t('BundlesUpsert.IndexTable.emptyText')}</Text>
            </InlineStack>
          </Box>
        }
      >
        {rowMarkup}
      </IndexTable>
    </Card>
  )

  function createRowMarkup(groupedBundleVariants, products) {
    return Object.keys(groupedBundleVariants).map((variantTitle, index) => {
      let { productVariants, position, ...bv } =
        groupedBundleVariants[variantTitle]
      productVariants = productVariants
        .map((variant) =>
          products
            .find(
              (product) =>
                parseInt(product.id.replace('gid://shopify/Product/', '')) ===
                variant.productId,
            )
            ?.variants?.find(
              (v) =>
                parseInt(v.id.replace('gid://shopify/ProductVariant/', '')) ===
                variant.id,
            ),
        )
        .filter(Boolean)
      return (
        <Fragment key={variantTitle}>
          <IndexTable.Row
            rowType="data"
            id={`Parent-${index}`}
            position={position}
          >
            <IndexTable.Cell scope="col" id={variantTitle}>
              <BlockStack gap={'50'}>
                <Text as="span" fontWeight="semibold">
                  {variantTitle}
                </Text>
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell />
            {form.fields.discountType.value !==
              DISCOUNT_OPTIONS.NO_DISCOUNT && <IndexTable.Cell />}
          </IndexTable.Row>

          {productVariants.map((variant, rowIndex) => {
            if (!variant) {
              return (
                <IndexTable.Row
                  rowType="child"
                  key={rowIndex}
                  id={id}
                  position={position}
                >
                  <IndexTable.Cell
                    scope="row"
                    headers={`${columnHeading[0].id} ${variantTitle}`}
                  >
                    <Text>
                      {t('BundlesUpsert.IndexTable.productNotAddedYet')}
                    </Text>
                  </IndexTable.Cell>
                  {form.fields.discountType.value !==
                    DISCOUNT_OPTIONS.NO_DISCOUNT && <IndexTable.Cell />}
                </IndexTable.Row>
              )
            }

            let {
              id,
              title,
              image,
              product: { id: productId } = {},
            } = variant ?? {}
            id = parseInt(id.replace('gid://shopify/ProductVariant/', ''))
            return (
              <IndexTable.Row
                rowType="child"
                key={rowIndex}
                id={id}
                position={position}
              >
                <IndexTable.Cell
                  scope="row"
                  headers={`${columnHeading[0].id} ${title}`}
                >
                  <BlockStack gap={'100'}>
                    <Text as="strong" variant="headingXs">
                      {productIdMap[productId]?.title}
                    </Text>
                    <Text variant="bodyXs" tone="subdued">
                      {title}
                    </Text>
                  </BlockStack>
                </IndexTable.Cell>
                {form.fields.discountType.value !==
                  DISCOUNT_OPTIONS.NO_DISCOUNT && (
                  <IndexTable.Cell>
                    <Text>
                      {currencyFormatter(
                        window.shopify.data.shop.currency,
                        variant.compareAtPrice || variant.price,
                      )}
                    </Text>
                  </IndexTable.Cell>
                )}
                <IndexTable.Cell>
                  {form.fields.discountType.value !==
                  DISCOUNT_OPTIONS.VARIANT_DISCOUNT ? (
                    <Text>
                      {form.fields.discountType.value ===
                      DISCOUNT_OPTIONS.BUNDLE_DISCOUNT
                        ? calcDiscountedPrice(
                            variant.price,
                            form.fields.bundleDiscount.value,
                          )
                        : variant.price}
                    </Text>
                  ) : (
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      style={{
                        maxWidth: '150px',
                      }}
                    >
                      <TextField
                        type="number"
                        value={
                          form.fields.variantDiscount.value?.[variantTitle]?.[
                            id
                          ] !== undefined
                            ? form.fields.variantDiscount.value?.[
                                variantTitle
                              ]?.[id]
                            : variant.price
                        }
                        min={0}
                        error={
                          form.fields.variantDiscount.error?.[variantTitle]?.[
                            id
                          ]
                        }
                        onChange={(val) => {
                          const copy = { ...form.fields.variantDiscount.value }
                          const newPrice = parseFloat(val)
                          copy[variantTitle] = {
                            ...(copy[variantTitle] ?? {}),
                            [id]: isNaN(newPrice) ? null : newPrice,
                          }
                          form.fields.variantDiscount.onChange(copy)
                        }}
                        suffix={window.shopify.data.shop.currency}
                      />
                    </div>
                  )}
                </IndexTable.Cell>
              </IndexTable.Row>
            )
          })}
        </Fragment>
      )
    })
  }

  function groupRow(bundleVariants) {
    let position = -1
    const groups = bundleVariants.reduce((grps, bundleVariant) => {
      const key = bundleVariant.name
      position += 1
      grps[key] = {
        id: key,
        position,
        ...bundleVariant,
      }
      return grps
    }, {})

    return groups
  }
}

const DISCOUNT_OPTIONS = {
  NO_DISCOUNT: 'noDiscount',
  BUNDLE_DISCOUNT: 'bundleDiscount',
  VARIANT_DISCOUNT: 'variantDiscount',
}

function calcDiscountedPrice(price, discount) {
  price = parseFloat(price)

  if (!discount) {
    return currencyFormatter(window.shopify.data.shop.currency, price)
  }

  const discountedPrice = price * (1 - discount / 100)
  if (discountedPrice < 0) {
    return currencyFormatter(window.shopify.data.shop.currency, 0)
  }
  return currencyFormatter(window.shopify.data.shop.currency, discountedPrice)
}
