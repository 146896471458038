const IMAGE_EXTENSIONS = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG']

export function optimisedImage({ baseUrl, size }) {
  if (!size || !baseUrl) {
    return baseUrl
  }

  let optimisedImageUrl = new URL(baseUrl)
  optimisedImageUrl.searchParams.set('width', size)
  return optimisedImageUrl.toString()
}
