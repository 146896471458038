import { useField } from '@shopify/react-form'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function useFields(settings) {
  const { t } = useTranslation()
  const array = [
    {
      name: 'promotionType',
      value: settings.promotionType || 'single_product',
      validates: [],
    },
    {
      name: 'slots',
      value: settings.slots ?? [defaultSlotSetting],
      validates: [
        (slots) => {
          if (slots.length === 0) {
            return t('PostCheckoutEditor.error.selectProduct')
          }
          let error = Array.from({ length: slots.length }).fill(null)
          slots.forEach((slot, index) => {
            if (slot.recommendation === 'manual' && slot.product === null) {
              error[index] = t('PostCheckoutEditor.error.selectProduct')
            }
            if (
              slot.discount.type === 'percentage' &&
              (slot.discount.value < 0 ||
                slot.discount.value > 100 ||
                isNaN(slot.discount.value))
            ) {
              error[index] = t('PostCheckoutEditor.error.discountPercent')
            }
            if (
              slot.discount.type === 'amount' &&
              (slot.discount.value < 0 || isNaN(slot.discount.value))
            ) {
              error[index] = t('PostCheckoutEditor.error.discountAmount')
            }
            if (isNaN(slot.minQuantity) || isNaN(slot.maxQuantity)) {
              error[index] = t('PostCheckoutEditor.error.quanityNotValid')
            }
            if (
              slot.allowQuantityChange &&
              slot.minQuantity > slot.maxQuantity
            ) {
              error[index] = t('PostCheckoutEditor.error.minMaxQuantity')
            }
            if (slot.allowQuantityChange && slot.minQuantity < 1) {
              error[index] = t('PostCheckoutEditor.error.minQuantity')
            }
          })
          const isError = error.some((e) => e !== null)
          return isError ? error : null
        },
      ],
    },
    {
      name: 'banner',
      value: settings.banner || {
        general: {
          layout: 'full_width',
          background: 'secondary',
          border: 'none',
          alignment: 'center',
          spacing: 'loose',
        },
        line: {
          text: "**It's not too late to add this in your order**",
          appearance: 'regular',
          size: 'medium',
        },
      }, // add settings here from old dashobard
      validates: [],
    },
    {
      name: 'translations',
      value: 'primary_locale',
      validates: [],
    },
    {
      name: 'texts',
      value: settings.translations || getLabelTranslations(),
      validates: [],
    },
  ]

  const fields = array.reduce((acc, curr) => {
    const key = curr.name
    return {
      ...acc,
      [key]: useField({
        value: curr.value,
        validates: curr.validates,
      }),
    }
  }, {})

  useEffect(() => {
    if (fields.promotionType.value === 'single_product') {
      fields.slots.onChange(
        settings.slots?.[0] ? [settings.slots?.[0]] : [defaultSlotSetting],
      )
    } else {
      const slots = settings.slots ?? [defaultSlotSetting, defaultSlotSetting]
      while (slots.length < 2) {
        slots.push(defaultSlotSetting)
      }
      fields.slots.onChange(slots)
    }
  }, [fields.promotionType.value])

  return fields
}

const defaultSlotSetting = {
  product: null,
  recommendation: 'automatic',
  translations: {},
  discount: {
    value: 0,
    type: 'percentage',
  },
  allowQuantityChange: true,
  minQuantity: 1,
  maxQuantity: 1,
}

function getLabelTranslations() {
  const defaultTranslations = {
    tr: {
      discount_text: '\u0130ndirim',
      free_text: '\u00dccretsiz',
      decline_button_text: 'Y\u00fckseltme teklifini reddet',
      buy_button_text: '\u015eimdi \u00f6de \u2022 {{toplam}}',
    },
    is: {
      free_text: 'Fr\u00edtt',
      discount_text: 'Afsl\u00e1ttur',
      decline_button_text:
        '"Hafna vi\u00f0b\u00f3tarvi\u00f0skiptatill\u00f6gu"',
      buy_button_text: 'Grei\u00f0a n\u00fana \u2022 {{total}}',
    },
    nl: {
      decline_button_text: '"Aanbieding voor upsell afwijzen"',
      buy_button_text: 'Nu betalen \u2022 {{total}}',
      discount_text: 'Korting',
      free_text: 'Vrij',
    },
    ja: {
      free_text: '\u7121\u6599',
      buy_button_text: '\u4eca\u3059\u3050\u652f\u6255\u3046 \u2022 {{total}}',
      discount_text: '\u30c7\u30a3\u30b9\u30ab\u30a6\u30f3\u30c8',
      decline_button_text:
        '\u30a2\u30c3\u30d7\u30bb\u30eb\u63d0\u6848\u3092\u65ad\u308b',
    },
    de: {
      discount_text: 'Rabatt',
      free_text: 'Frei',
      decline_button_text: 'Upsell-Angebot ablehnen',
      buy_button_text: '"Jetzt bezahlen \u2022 {{total}}"',
    },
    ru: {
      discount_text: '\u0421\u043a\u0438\u0434\u043a\u0430',
      free_text: '\u0411\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u043e',
      buy_button_text:
        '\u041e\u043f\u043b\u0430\u0442\u0438\u0442\u044c \u0441\u0435\u0439\u0447\u0430\u0441 \u2022 {{total}}',
      decline_button_text:
        '"\u041e\u0442\u043a\u043b\u043e\u043d\u0438\u0442\u044c \u043f\u0440\u0435\u0434\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u0434\u043e\u043f\u043e\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0445 \u0443\u0441\u043b\u0443\u0433"',
    },
    pl: {
      discount_text: 'Rabat',
      free_text: 'Darmowy',
      buy_button_text: 'Zap\u0142a\u0107 teraz \u2022 {{total}}',
      decline_button_text: 'Odrzu\u0107 ofert\u0119 upsell',
    },
    uk: {
      decline_button_text:
        '\u0412\u0456\u0434\u0445\u0438\u043b\u0438\u0442\u0438 \u043f\u0440\u043e\u043f\u043e\u0437\u0438\u0446\u0456\u044e \u0434\u043e\u0434\u0430\u0442\u043a\u043e\u0432\u043e\u0433\u043e \u043f\u0440\u043e\u0434\u0430\u0436\u0443.',
      buy_button_text:
        '\u0421\u043f\u043b\u0430\u0442\u0438\u0442\u0438 \u0437\u0430\u0440\u0430\u0437 \u2022 {{total}}',
      free_text: '\u0412\u0456\u043b\u044c\u043d\u043e',
      discount_text: '\u0417\u043d\u0438\u0436\u043a\u0430',
    },
    fi: {
      decline_button_text: '"Hylk\u00e4\u00e4 lis\u00e4myyntitarjous"',
      free_text: 'Vapaa',
      buy_button_text: 'Maksa nyt \u2022 {{total}}',
      discount_text: 'Alennus',
    },
    'zh-CN': {
      free_text: '\u514d\u8d39',
      decline_button_text: '\u62d2\u7edd\u8ffd\u52a0\u9500\u552e\u4f18\u60e0',
      buy_button_text: '\u7acb\u5373\u652f\u4ed8 \u2022 {{total}}',
      discount_text: '\u6298\u6263',
    },
    pt: {
      discount_text: 'Desconto',
      free_text: 'Gr\u00e1tis',
      decline_button_text: 'Recusar oferta de upsell.',
      buy_button_text: 'Pagar agora \u2022 {{total}}',
    },
    en: {
      buy_button_text: 'Pay now \u2022 {{total}}',
      decline_button_text: 'Decline offer',
      free_text: 'Free',
      discount_text: 'Discount',
    },
    'pt-PT': {
      buy_button_text: 'Pagar agora \u2022 {{total}}',
      free_text: 'Gr\u00e1tis',
      discount_text: 'Desconto',
      decline_button_text: 'Recusar oferta de upsell',
    },
    it: {
      discount_text: 'Sconto',
      free_text: 'Gratuito',
      decline_button_text: '"Rifiuta offerta di upsell"',
      buy_button_text: '"Paga ora \u2022 {{total}}"',
    },
    sr: {
      discount_text: '\u041f\u043e\u043f\u0443\u0441\u0442',
      free_text: 'Besplatno',
      buy_button_text:
        '\u041f\u043b\u0430\u0442\u0438\u0442\u0435 \u0441\u0430\u0434\u0430 \u2022 {{total}}',
      decline_button_text: '"Odustani od dodatne ponude"',
    },
    'ro-RO': {
      discount_text: 'Reducere',
      free_text: 'Gratuit',
      decline_button_text: '"Refuz\u0103 oferta de upsell"',
      buy_button_text: 'Pl\u0103ti\u021bi acum \u2022 {{total}}',
    },
    fr: {
      decline_button_text: "Refuser l'offre de vente incitative",
      free_text: 'Gratuit',
      discount_text: 'Remise',
      buy_button_text: 'Payer maintenant \u2022 {{total}}',
    },
    el: {
      discount_text: '\u0388\u03ba\u03c0\u03c4\u03c9\u03c3\u03b7',
      free_text: '\u0395\u03bb\u03b5\u03cd\u03b8\u03b5\u03c1\u03bf\u03c2',
      buy_button_text:
        '\u03a0\u03bb\u03b7\u03c1\u03ce\u03c3\u03c4\u03b5 \u03c4\u03ce\u03c1\u03b1 \u2022 {{\u03c3\u03cd\u03bd\u03bf\u03bb\u03bf}}',
      decline_button_text:
        '\u0391\u03c0\u03cc\u03c1\u03c1\u03b9\u03c8\u03b7 \u03c0\u03c1\u03cc\u03c4\u03b1\u03c3\u03b7\u03c2 \u03c0\u03ce\u03bb\u03b7\u03c3\u03b7\u03c2 \u03c0\u03c1\u03cc\u03c3\u03b8\u03b5\u03c4\u03c9\u03bd \u03c5\u03c0\u03b7\u03c1\u03b5\u03c3\u03b9\u03ce\u03bd',
    },
    ro: {
      discount_text: 'Reducere',
      free_text: 'Liber',
      decline_button_text: '"Refuz\u0103 oferta suplimentar\u0103"',
      buy_button_text: 'Pl\u0103te\u0219te acum \u2022 {{total}}',
    },
    hi: {
      discount_text: '\u091b\u0942\u091f',
      free_text: '\u092e\u0941\u092b\u093c\u094d\u0924',
      decline_button_text:
        '\u0911\u092b\u0930 \u0915\u094b \u0905\u0938\u094d\u0935\u0940\u0915\u093e\u0930 \u0915\u0930\u0947\u0902',
      buy_button_text:
        '\u0905\u092d\u0940 \u092d\u0941\u0917\u0924\u093e\u0928 \u0915\u0930\u0947\u0902 \u2022 {{total}}',
    },
    'pt-BR': {
      decline_button_text: 'Recusar oferta adicional.',
      free_text: 'Gr\u00e1tis',
      discount_text: 'Desconto',
      buy_button_text: 'Pague agora \u2022 {{total}}',
    },
    ko: {
      discount_text: '\ud560\uc778',
      decline_button_text: '\ub2e4\uc6b4\uc140 \uc81c\uc548 \uac70\uc808',
      free_text: '\ubb34\ub8cc',
      buy_button_text: '\uc9c0\uae08 \uacb0\uc81c \u2022 {{total}}',
    },
    he: {
      decline_button_text:
        '\u05dc\u05d3\u05d7\u05d5\u05ea \u05d4\u05e6\u05e2\u05ea \u05e9\u05d3\u05e8\u05d5\u05d2',
      discount_text: '\u05d4\u05e0\u05d7\u05d4',
      free_text: '\u05d7\u05d5\u05e4\u05e9\u05d9',
      buy_button_text:
        '\u05e9\u05dc\u05dd \u05e2\u05db\u05e9\u05d9\u05d5 \u2022 {{total}}',
    },
    'zh-TW': {
      free_text: '\u514d\u8cbb',
      discount_text: '\u6298\u6263',
      decline_button_text: '\u62d2\u7d55\u52a0\u8cfc\u512a\u60e0',
      buy_button_text: '\u7acb\u5373\u4ed8\u6b3e \u2022 {{total}}',
    },
    vi: {
      decline_button_text:
        '"T\u1eeb ch\u1ed1i \u0111\u1ec1 ngh\u1ecb mua th\u00eam"',
      buy_button_text: 'Thanh to\u00e1n ngay \u2022 {{total}}',
      free_text: 'Mi\u1ec5n ph\u00ed',
      discount_text: 'Gi\u1ea3m gi\u00e1',
    },
    fa: {
      free_text: '\u0631\u0627\u06cc\u06af\u0627\u0646',
      decline_button_text:
        '\u067e\u06cc\u0634\u0646\u0647\u0627\u062f \u0641\u0631\u0648\u0634 \u0628\u06cc\u0634\u062a\u0631 \u0631\u0627 \u0646\u067e\u0630\u06cc\u0631\u06cc\u062f',
      buy_button_text:
        '\u0627\u06a9\u0646\u0648\u0646 \u067e\u0631\u062f\u0627\u062e\u062a \u06a9\u0646\u06cc\u062f \u2022 {{total}}',
      discount_text: '\u062a\u062e\u0641\u06cc\u0641',
    },
    cs: {
      buy_button_text: 'Zaplatit nyn\u00ed \u2022 {{celkem}}',
      decline_button_text: '"Odm\u00edtnout nab\u00eddku upsell"',
      discount_text: 'Sleva',
      free_text: 'Zdarma',
    },
    'sl-SI': {
      discount_text: 'Popust',
      free_text: 'Brezpla\u010dno',
      decline_button_text: 'Zavrni ponudbo za nadgradnjo.',
      buy_button_text: '"Pla\u010daj zdaj \u2022 {{total}}"',
    },
    id: {
      decline_button_text: '"Tolak penawaran upsell"',
      discount_text: 'Diskon',
      buy_button_text: 'Bayar sekarang \u2022 {{total}}',
      free_text: 'Gratis',
    },
    da: {
      free_text: 'Gratis',
      buy_button_text: '"Betal nu \u2022 {{total}}"',
      discount_text: 'Rabat',
      decline_button_text: '"Afvise mersalgstilbud"',
    },
    th: {
      discount_text: '\u0e2a\u0e48\u0e27\u0e19\u0e25\u0e14',
      decline_button_text:
        '\u0e1b\u0e0f\u0e34\u0e40\u0e2a\u0e18\u0e02\u0e49\u0e2d\u0e40\u0e2a\u0e19\u0e2d\u0e01\u0e32\u0e23\u0e02\u0e32\u0e22\u0e40\u0e2a\u0e23\u0e34\u0e21',
      buy_button_text:
        '\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49 \u2022 {{total}}',
      free_text: '\u0e1f\u0e23\u0e35',
    },
    sv: {
      free_text: 'Gratis',
      discount_text: 'Rabatt',
      buy_button_text: 'Betala nu \u2022 {{total}}',
      decline_button_text: 'Avvisa merf\u00f6rs\u00e4ljningserbjudande',
    },
    nn: {
      free_text: 'Fri',
      discount_text: 'Rabatt',
      decline_button_text: '"Avsl\u00e5 oppsalgstilbod"',
      buy_button_text: 'Betal no \u2022 {{total}}',
    },
    es: {
      decline_button_text: 'Rechazar oferta adicional.',
      buy_button_text: 'Pagar ahora \u2022 {{total}}',
      discount_text: 'Descuento',
      free_text: 'Gratis',
    },
    ar: {
      free_text: '\u0645\u062c\u0627\u0646\u064a',
      discount_text: '\u062e\u0635\u0645',
      buy_button_text:
        '\u0627\u062f\u0641\u0639 \u0627\u0644\u0622\u0646 \u2022 {{total}}',
      decline_button_text:
        '\u0631\u0641\u0636 \u0639\u0631\u0636 \u0627\u0644\u0628\u064a\u0639 \u0627\u0644\u0625\u0636\u0627\u0641\u064a',
    },
    nb: {
      discount_text: 'Rabatt',
      free_text: 'Gratis',
      buy_button_text: 'Betal n\u00e5 \u2022 {{total}}',
      decline_button_text: 'Avsl\u00e5 mersalgstilbud',
    },
  }
  const shopLocale = window.shopify.config.locale.split('-').shift()
  defaultTranslations['primary_locale'] = defaultTranslations[shopLocale]
  return defaultTranslations
}
