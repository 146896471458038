import {
  ActionList,
  BlockStack,
  Box,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  List,
  Modal,
  Popover,
  Select,
  Tag,
  Text,
  Checkbox,
} from '@shopify/polaris'
import { MenuVerticalIcon } from '@shopify/polaris-icons'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutClick from 'react-outclick'
import CustomModal from '../../../../../components/CustomModal'

export default function Publications({ publications, form }) {
  const { t } = useTranslation()

  useEffect(() => {
    if (publications.length !== 0) {
      const onlineStore = publications.find(
        (pub) => pub.name === 'Online Store',
      )
      form.fields.publications.onChange(
        onlineStore ? [parseInt(onlineStore.id)] : [],
      )
    }
  }, [publications])

  const isStoreChannelAvailaible = publications.find((pub) =>
    form.fields.publications.value.includes(pub.id),
  )

  return (
    <Card>
      <BlockStack gap={'100'}>
        <InlineGrid columns={'1fr auto'}>
          <Text variant="headingSm">
            {t('BundlesUpsert.Publications.heading')}
          </Text>
          <Box>
            <MenuPopover publicationOptions={publications} form={form} />
          </Box>
        </InlineGrid>
        <Text variant="bodyMd">
          {t('BundlesUpsert.Publications.SalesChannel.title')}
        </Text>
        {isStoreChannelAvailaible ? (
          <List>
            <List.Item>
              {t('BundlesUpsert.Publications.SalesChannel.onlineStore')}
            </List.Item>
          </List>
        ) : (
          <Text variant="bodyMd" tone="subdued">
            {t('BundlesUpsert.Publications.SalesChannel.emptyText')}
          </Text>
        )}
      </BlockStack>
    </Card>
  )
}

function MenuPopover({ form, publicationOptions }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [salesChannelModal, setOpenSalesChannelModal] = useState(false)
  const activator = (
    <Button
      variant="plain"
      size="micro"
      icon={MenuVerticalIcon}
      onClick={() => {
        setOpen(!open)
      }}
      disabled={publicationOptions.length === 0}
    />
  )

  return (
    <OutClick onOutsideClick={() => setOpen(false)}>
      <Popover onClose={()=>{
        setOpen(false)
      }} activator={activator} active={open}>
        <ActionList
          items={[
            {
              content: t('BundlesUpsert.Publications.SalesChannel.manage'),
              onAction: () => setOpenSalesChannelModal(true),
            },
          ]}
        />
      </Popover>
      <SelectionModal
        open={salesChannelModal}
        setOpen={setOpenSalesChannelModal}
        form={form}
        publicationOptions={publicationOptions}
      />
    </OutClick>
  )

  function SelectionModal({ open, setOpen, form, publicationOptions }) {
    const [publications, setPublications] = useState(
      form.fields.publications.value,
    )

    return (
      <CustomModal
        variant="medium"
        show={open}
        setShow={setOpen}
        onSave={() => {
          form.fields.publications.onChange(publications)
          setOpen(false)
        }}
        onDiscard={() => {
          setPublications([])
          setOpen(false)
        }}
        title={t('BundlesUpsert.Publications.SalesChannel.manage')}
      >
        <div style={{
          padding: 'var(--p-space-200) var(--p-space-400)',
        }}>
        <BlockStack gap={'100'}>
          {publicationOptions.map((option) => (
            <Checkbox
              key={option.id}
              label={option.name}
              checked={publications.find((id) => id === option.id)}
              onChange={(checked) => {
                if (checked) {
                  setPublications([...publications, option.id])
                } else {
                  setPublications(
                    publications.filter((id) => id !== option.id),
                  )
                }
              }}
            />
          ))}
        </BlockStack>
        </div>
      </CustomModal>
    )
  }
}
