import {
  Banner,
  BlockStack,
  Box,
  Button,
  Divider,
  Icon,
  InlineGrid,
  Page,
  SkeletonDisplayText,
  Text,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { ChevronRightIcon, PlusIcon } from '@shopify/polaris-icons'
import { useSearchParams } from 'react-router-dom'

import WidgetTabChart from '../../components/WidgetTabChart'
import CampaignManager from './components/CampaignManager'
import { PricingModal, usePricing } from '../../components/PricingModal'
import DateRangePopover from '../../components/DateTimeRangePopover'
import {
  currencyFormatter,
  numberFormater,
  percentageFormatter,
} from '../../utils/formater'
import CustomModal from '../../components/CustomModal'
import { useRedirect } from '../../hooks'
import { fetchAppDetails } from '../../apis/shopify'
import { getCheckoutAnalytics } from '../../apis/analytics'

export default function CheckoutUpsell() {
  const { t } = useTranslation()
  const [_durationFilter, setDurationFilter] = useSearchParams({
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
    end: new Date(),
  })

  const durationFilter = {
    start: new Date(_durationFilter.get('start')),
    end: new Date(_durationFilter.get('end')),
  }

  const handleDurationFilterChange = useCallback(
    (value) => setDurationFilter({
      start: value.start.toISOString(),
      end: value.end.toISOString(),
    }),
    [],
  )
  const pricing = usePricing()

  const analyticsQuery = useQuery({
    queryKey: ['checkoutUpsellAnalytics', durationFilter],
    queryFn: async () => {
      const { data, error } = await getCheckoutAnalytics({
        dateGte: new Date(
          durationFilter.start.setHours(0, 0, 0, 0) + 24 * 3600 * 1000,
        )
          .toISOString()
          .split('T')[0],
        dateLte: new Date(durationFilter.end.setHours(23, 59, 59, 999))
          .toISOString()
          .split('T')[0],
      })

      if (error) {
        return Promise.reject(error)
      }

      return data
    },
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    enabled: durationFilter.start != durationFilter.end,
  })

  const appQuery = useQuery({
    queryKey: ['app'],
    queryFn: async () => {
      try {
        const data = await fetchAppDetails()
        return data
      } catch (error) {
        return Promise.reject
      }
    },
    refetchOnWindowFocus: true,
  })
  const breakpoints = useBreakpoints()
  const isPostPurchaseAppInUse = appQuery.data?.isPostPurchaseAppInUse ?? false

  const analyticsData = {
    options: [
      {
        label: t(`CheckoutUpsell.Chart.atributedRevenue.title`),
        value: currencyFormatter(
          window.shopify.data.shop.currency,
          analyticsQuery.data?.stats?.totalCheckoutUpsellRevenue || 0,
        ),
      },
      {
        label: t(`CheckoutUpsell.Chart.conversionRate.title`),
        value: percentageFormatter(
          analyticsQuery.data?.stats?.checkoutConversionRate || 0,
        ),
      },
      {
        label: t(`CheckoutUpsell.Chart.aovIncrease.title`),
        value: currencyFormatter(
          window.shopify.data.shop.currency,
          analyticsQuery.data?.stats?.totalAovIncrease || 0,
        ),
      },
      {
        label: t(`CheckoutUpsell.Chart.total.title`),
        value: numberFormater(
          analyticsQuery.data?.stats?.totalCheckoutsCompleted || 0,
        ),
      },
    ],
    stats: [
      {
        data:
          analyticsQuery.data?.stats?.daily?.map((agg) => ({
            key: format(new Date(agg.date), 'MMM dd'),
            value: agg.totalCheckoutUpsellRevenue,
          })) || [],
        name: t(`CheckoutUpsell.Chart.atributedRevenue.title`),
        yAxisFormatter: (val) =>
          currencyFormatter(window.shopify.data.shop.currency, val),
        tooltipFormatter: (val) =>
          currencyFormatter(window.shopify.data.shop.currency, val),
      },
      {
        data:
          analyticsQuery.data?.stats?.daily?.map((agg) => ({
            key: format(new Date(agg.date), 'MMM dd'),
            value: agg.checkoutConversionRate,
          })) || [],
        name: t(`CheckoutUpsell.Chart.conversionRate.title`),
        yAxisFormatter: (val) => percentageFormatter(val),
        tooltipFormatter: (val) => percentageFormatter(val),
      },
      {
        data:
          analyticsQuery.data?.stats?.daily?.map((agg) => ({
            key: format(new Date(agg.date), 'MMM dd'),
            value: agg.totalAovIncrease,
          })) || [],
        name: t(`CheckoutUpsell.Chart.aovIncrease.title`),
        yAxisFormatter: (val) =>
          currencyFormatter(window.shopify.data.shop.currency, val),
        tooltipFormatter: (val) =>
          currencyFormatter(window.shopify.data.shop.currency, val),
      },
      {
        data:
          analyticsQuery.data?.stats?.daily?.map((agg) => ({
            key: format(new Date(agg.date), 'MMM dd'),
            value: agg.totalCheckoutsCompleted,
          })) || [],
        name: t(`CheckoutUpsell.Chart.total.title`),
        yAxisFormatter: (val) => numberFormater(val),
        tooltipFormatter: (val) => numberFormater(val),
      },
    ],
  }

  return (
    <Page
      backAction={{
        url: '/',
      }}
      title={t('CheckoutUpsell.title')}
      additionalMetadata={t('CheckoutUpsell.description')}
      primaryAction={(() => {
        const btn = (
          <Button
            variant="primary"
            icon={PlusIcon}
            url={'/checkout-upsell/create'}
          >
            {t('CheckoutUpsell.CampaignManager.btnCta')}
          </Button>
        )
        return btn
      })()}
      secondaryActions={[
        {
          content: t('CheckoutUpsell.CalloutCard.btnCta'),
          url: '/checkout-upsell/configure',
        },
      ]}
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <BlockStack gap={'400'}>
          <BlockStack>
            {appQuery.isLoading ? (
              <>
                <SkeletonDisplayText size="medium" maxWidth="100%" />
                <Box padding={'200'} />
              </>
            ) : isPostPurchaseAppInUse ? null : (
              <Banner
                action={{
                  content: t('PostPurchaseBanner.hereCta'),
                  url: `https://admin.shopify.com/store/${window.shopify.config.shop.replace('.myshopify.com', '')}/settings/checkout#additionalscripts`,
                  target: '_self',
                }}
                title={t('PostPurchaseBanner.notInUseTitle')}
                tone={'warning'}
              >
                <Text>{t('PostPurchaseBanner.notInUseDescription')}</Text>
              </Banner>
            )}
          </BlockStack>
          <WidgetTabChart
            dateFilter={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {' '}
                <DateRangePopover
                  durationFilter={durationFilter}
                  handleDurationFilterChange={handleDurationFilterChange}
                />
              </div>
            }
            loading={analyticsQuery.isLoading}
            loadingBlocks={4}
            stats={analyticsData.options}
            data={analyticsData.stats}
            columns={{
              xs: 3,
              sm: 4,
              md: 5,
              lg: 5,
            }}
          />
          <CampaignManager
            refresh={analyticsQuery.refetch}
            timeFilter={{
              gtle: durationFilter.start.valueOf(),
              lte: durationFilter.end.valueOf(),
            }}
          />
        </BlockStack>
      </Box>
      <PricingModal modal={pricing} />
    </Page>
  )
}

// Not in use but can be used for future reference
function CreateModal({ open, setOpen }) {
  const { t } = useTranslation()
  const options = ['post_checkout', 'checkout_upsell']
  const baseUrl = '/checkout-upsell/create'

  const { redirectToLink } = useRedirect()

  return (
    <CustomModal
      variant="medium"
      title={t('CheckoutUpsell.CreateModal.title')}
      show={open}
      setShow={setOpen}
    >
      <BlockStack>
        {options.map((option, index) => (
          <>
            <OptionCard
              onClick={() => {
                redirectToLink({
                  external: false,
                  url: `${baseUrl}/${option}`,
                })
              }}
              option={option}
            />
            {index < options.length - 1 && <Divider />}
          </>
        ))}
      </BlockStack>
    </CustomModal>
  )

  function OptionCard({ option, onClick }) {
    const [hovered, setHovered] = useState(false)
    return (
      <div
        style={{
          padding: 'var(--p-space-400)',
          cursor: 'pointer',
          backgroundColor: hovered
            ? 'var(--p-color-bg)'
            : 'var(--p-color-bg-surface-hover)',
          width: '100%',
          transition: 'background-color 0.2s',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClick}
      >
        <InlineGrid columns={'1fr auto'}>
          <BlockStack gap={'100'}>
            <Text variant="headingMd">
              {t(`CheckoutUpsell.CreateModal.options.${option}.label`)}
            </Text>
            <Text variant="bodySm">
              {t(`CheckoutUpsell.CreateModal.options.${option}.description`)}
            </Text>
          </BlockStack>
          <Icon source={ChevronRightIcon} />
        </InlineGrid>
      </div>
    )
  }
}
