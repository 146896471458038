import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BlockStack,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris'
import { useQuery } from 'react-query'

import ProductTagList from '../../../../components/ProductsList'
import { useDashboardDetails } from '../../../../hooks/useDashboardDetails'
import { updateShopConfig } from '../../../../apis/dashbboard'
import queryClient from '../../../../utils/query'
import { getProducts } from '../../../../apis/products'

function DisabledProducts() {
  const { t } = useTranslation()

  const { data: dashboardData, isLoading: dashboardLoading, invalidate: invalidateDashboard } =
    useDashboardDetails()

  const { data: disabledProductsData, isLoading: disabledProductsLoading } =
    useQuery({
      queryKey: ['disabledProducts'],
      queryFn: async () => {
        const { config } = dashboardData
        const disableProductIds = config.disabledProducts.map((id) =>
          typeof id === 'string'
            ? parseInt(id.replace('gid://shopify/Product/', ''))
            : id,
        )
        const disabledProducts = (
          await getProducts(disableProductIds)
        ).data.products.map((p) => ({
          id: p.id,
          images: p.image ? [p.image?.src] : [],
          title: p.title,
          vendor: p.vendor,
          handle: p.handle,
        }))
        return disabledProducts
      },
      enabled: Boolean(dashboardData) && !dashboardLoading,
    })

  const [disabledProducts, setDisabledProducts] = useState([])
  const [disabledTags, setDisabledTags] = useState([])

  const updateHandler = async ({
    disabledProducts: newDisabledProducts,
    disabledTags: newDisabledTags,
  }) => {
    // have to send all data to backend as it is not a patch request
    const res = await updateShopConfig({
      disabledProducts: newDisabledProducts.map((product) =>
        typeof product.id === 'string' ? parseInt(product.id.replace('gid://shopify/Product/', '')) : product.id,
      ),
      disabledTags: newDisabledTags,
      excludedProducts: dashboardData.config.excludedProducts,
      excludedTags: dashboardData.config.excludedTags,
      onboarding: dashboardData.config.onboarding,
    })
    if (res.error) {
      console.log(res.error)
    } else {
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
      await invalidateDashboard()
      await queryClient.invalidateQueries(['disabledProducts'])
    }
  }

  useEffect(() => {
    if (dashboardData && !dashboardLoading) {
      setDisabledTags(dashboardData.config.disabledTags)
    }
  }, [dashboardData, dashboardLoading])

  useEffect(() => {
    if (disabledProductsData && !disabledProductsLoading) {
      setDisabledProducts(disabledProductsData)
    }
  }, [disabledProductsData, disabledProductsLoading])

  if (dashboardLoading || disabledProductsLoading) {
    return <Skeleton />
  }

  return (
    <ProductTagList
      separatedLayout
      productSelectionTitle={t(
        'Section.Edit.Sections.Recommendation.DisabledProduct.title',
      )}
      productSelectionButtonCta={t(
        'Section.Edit.Sections.Recommendation.DisabledProduct.addProductCTA',
      )}
      productSelectionDescription={t(
        'Section.Edit.Sections.Recommendation.DisabledProduct.productDescription',
      )}
      products={disabledProducts}
      onProductSelectionChange={(products) => {
        updateHandler({ disabledProducts: products, disabledTags })
        setDisabledProducts(products)
      }}
      tagsTitle={t(
        'Section.Edit.Sections.Recommendation.DisabledProduct.tagsTitle',
      )}
      tagsDescription={t(
        'Section.Edit.Sections.Recommendation.DisabledProduct.tagsDescription',
      )}
      tags={disabledTags}
      onTagChange={(tags) => {
        updateHandler({ disabledProducts, disabledTags: tags })
        setDisabledTags(tags)
      }}
      queryPlaceholder={t(
        'Section.Edit.Sections.Recommendation.DisabledProduct.tagQueryPlaceholder',
      )}
      tagBtnCta={t(
        'Section.Edit.Sections.Recommendation.DisabledProduct.addTagBtnText',
      )}
    />
  )
}

export default DisabledProducts

function Skeleton() {
  return (
    <BlockStack gap={'400'}>
      <Card>
        <BlockStack gap={'300'}>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </BlockStack>
      </Card>
      <Card>
        <BlockStack gap={'300'}>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </BlockStack>
      </Card>
    </BlockStack>
  )
}
