import {
  BlockStack,
  Button,
  Card,
  InlineGrid,
  Text,
  TextField,
} from '@shopify/polaris'
import { DeleteIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { BLOG, OTHER } from '../../../../../constants/page'
import { PROMOTED_PRODUCTS } from '../../../../../constants/widgets'

export default function EnabledUrls({
  urls,
  onChange,
  errors,
  page,
  widgetType,
}) {
  const { t } = useTranslation()

  if (
    !visible({
      pageType: page,
      type: widgetType,
    })
  ) {
    return null
  }

  return (
    <Card>
      <BlockStack id='recommendation-enabled-urls' gap={'300'}>
        <BlockStack gap={'100'}>
          <Text as="strong">
            {t('Section.Edit.Sections.Recommendation.EnabledUrls.title')}
          </Text>
          <Text as="p">
            {t('Section.Edit.Sections.Recommendation.description')}
          </Text>
        </BlockStack>
        {urls.map((url, index) => (
          <BlockStack gap={'100'}>
            <Text>
              {t(
                'Section.Edit.Sections.Recommendation.EnabledUrls.input.label',
              )}
            </Text>
            <InlineGrid columns={'1fr auto'} gap={'200'}>
              <TextField
                value={url}
                onChange={(value) => {
                  onChange(urls.map((u, i) => (i === index ? value : u)))
                }}
                error={errors?.[index]}
              />
              <div>
                <Button
                  onClick={() => {
                    onChange(urls.filter((_, i) => i !== index))
                  }}
                  icon={DeleteIcon}
                />
              </div>
            </InlineGrid>
          </BlockStack>
        ))}
        <div>
          <Button
            onClick={() => {
              onChange([...urls, ''])
            }}
          >
            Add Url
          </Button>
        </div>
      </BlockStack>
    </Card>
  )
}

const visible = (widgetData) => {
  const otherPage = widgetData.pageType === OTHER.value
  const blogPage = widgetData.pageType === BLOG.value
  const promotedProductsWidget = widgetData.type === PROMOTED_PRODUCTS.value

  return otherPage || (blogPage && !promotedProductsWidget)
}
