import { useQuery } from 'react-query'

import { getDashboardDetails } from '../apis/dashbboard'
import queryClient from '../utils/query'

const BLACKLISTED_PATHS = ['/charge/confirm']

export function useDashboardDetails({ token } = {}) {
  const queryKey = ['getDashboardDetails']

  if (window.shopify.data?.shopPlan) {
    queryKey.push(window.shopify.data.shopPlan)
  }

  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const res = await getDashboardDetails(token)
      if (res.error) {
        return Promise.reject(res.error)
      }
      if (res.data?.redirectUrl) {
        return {
          redirectUrl: res.data.redirectUrl,
        }
      }
      return res.data
    },
    enabled: !BLACKLISTED_PATHS.includes(window.location.pathname),
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 1, // 1 minutes
    staleTime: 1000 * 60 * 1, // 1 minute
  })

  return {
    data: query.data,
    isLoading: query.isLoading,
    invalidate: () => queryClient.invalidateQueries(queryKey),
    error: query.error,
  }
}
