import classNames from 'classnames'
import React from 'react'

export default function Line({ line }) {
  const { size, appearance, timer, text } = line
  const textBlockProps = { size, appearance }
  const blocks = parseTextIntoBlocks(text)

  return (
    <div>
      {blocks.map((block) => (
        <Block
          key={block.id}
          block={block}
          props={textBlockProps}
          customer={DUMMY_CUSTOMER}
          timer={timer}
        />
      ))}
    </div>
  )
}

function Timer({ duration, textBlockProps }) {

  if (duration) {
    const time = new Date(null)
    time.setSeconds(parseInt(duration))

    return <Text {...textBlockProps}>{time.toISOString().substr(14, 5)}</Text>
  } else {
    return null
  }
}

function Block({ block, customer, timer, props }) {
  if (block.type === 'timer') {
    return timer ? (
      <Timer key={block.id} textBlockProps={props} duration={timer} />
    ) : null
  }

  if (block.type === 'customer') {
    const val = customer[block.key]
    return val ? (
      <Text {...props} key={block.id}>
        {val}
      </Text>
    ) : null
  }

  if (block.type === 'regular' || block.type === 'bold') {
    const innerBlocks = parseTextIntoBlocks(block.content)
    const innerTextBlockProps = {
      ...props,
      ...(block.type === 'bold' ? { emphasized: true } : {}),
    }

    return (
      <React.Fragment key={block.id}>
        {innerBlocks.map((innerBlock) => {
          if (innerBlock.type === 'timer') {
            return (
              <Timer
                key={innerBlock.id}
                textBlockProps={innerTextBlockProps}
                duration={timer}
              />
            )
          }

          if (innerBlock.type === 'customer') {
            const val = customer[innerBlock.key]
            return val ? (
              <Text {...innerTextBlockProps} key={innerBlock.id}>
                {val}
              </Text>
            ) : null
          }

          return (
            <Text key={innerBlock.id} {...innerTextBlockProps}>
              {innerBlock.content}
            </Text>
          )
        })}
      </React.Fragment>
    )
  }
}

function Text({ children, emphasized, appearance, size }) {
  return (
    <span
      className={classNames(
        APPEARANCE_TO_CLASS_MAP[appearance],
        SIZE_TO_CLASS_MAP[size],
        { 'font-semibold': emphasized }
      )}
    >
      {children}
    </span>
  )
}

const APPEARANCE_TO_CLASS_MAP = {
  regular: 'text-gray-800',
  critical: 'text-red-600',
  warning: 'text-yellow-600',
  success: 'text-green-600',
}

const SIZE_TO_CLASS_MAP = {
  small: 'text-sm',
  medium: 'text-base',
  large: 'text-lg',
  xlarge: 'text-xl',
}

function parseTextIntoBlocks(text) {
  const blocks = []

  let remainingText = text
  while (remainingText.length) {
    const matches = [
      ...(remainingText.match(BOLD_REGEX) || []).map((m) => ({
        match: m,
        type: 'bold',
      })),
      ...(remainingText.match(TIMER_REGEX) || []).map((m) => ({
        match: m,
        type: 'timer',
      })),
      ...(remainingText.match(CUSTOMER_REGEX) || []).map((m) => ({
        match: m,
        type: 'customer',
      })),
    ]

    if (matches.length > 0) {
      const earliestMatch = matches.reduce((earliestMatch, currentMatch) => {
        if (
          remainingText.indexOf(earliestMatch.match) <=
          remainingText.indexOf(currentMatch.match)
        ) {
          return earliestMatch
        } else {
          return currentMatch
        }
      }, matches[0])

      const indexOfEarliestMatch = remainingText.indexOf(earliestMatch.match)
      const beforeMatch = remainingText.substr(0, indexOfEarliestMatch)

      if (beforeMatch.length) {
        blocks.push({
          id: getBlockId(),
          type: 'regular',
          content: beforeMatch,
        })
      }

      const matchBlock = { type: earliestMatch.type, id: getBlockId() }
      if (matchBlock.type === 'bold') {
        matchBlock.content = earliestMatch.match.replaceAll('**', '').trim()
      } else if (matchBlock.type === 'customer') {
        matchBlock.key = earliestMatch.match
          .replace('customer.', '')
          .replace('{{', '')
          .replace('}}', '')
          .trim()
      }
      blocks.push(matchBlock)

      remainingText = remainingText.substr(
        indexOfEarliestMatch + earliestMatch.match.length
      )
    } else {
      blocks.push({
        id: getBlockId(),
        type: 'regular',
        content: remainingText,
      })
      remainingText = ''
    }
  }

  return blocks
}

let blockId = 0
function getBlockId() {
  return blockId++
}

const TIMER_REGEX = /{{(\s+)?timer(\s+)?}}/g
const CUSTOMER_REGEX = /{{(\s+)?customer\.(.*?)(\s+)?}}/g
const BOLD_REGEX = /\*\*(.*?)\*\*/g
const DUMMY_CUSTOMER = {
  first_name: 'Joanna',
  last_name: 'Henderson',
  email: 'joanna@gloodupsells.ai',
}
