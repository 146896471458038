import React from 'react'
import { useParams } from 'react-router-dom'
import { Badge, InlineStack, Page, Text } from '@shopify/polaris'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import {
  getCampaignDetails,
  getCheckoutUpsellConfig,
  updateCampaign,
} from '../../../../apis/checkoutUpsell'
import { useRedirect } from '../../../../hooks/useRedirect'
import { CAMPAIGN_STATUSES } from '../../../../constants/checkout'

import UpsertForm from '../common/components/Upsert'
import CampaignSkeleton from '../common/components/Skeleton'

function CampaignDetails() {
  const { campaignId = null } = useParams()
  const [deleting, setDeleting] = React.useState(false)
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()
  const campaignQuery = useQuery({
    queryKey: ['campaign', campaignId],
    queryFn: async () => {
      let parsedCampaignId = -1
      try {
        parsedCampaignId = parseInt(campaignId)
      } catch (e) {
        redirectToLink({
          url: '/checkout-upsell',
        })
      }
      const { data, error } = await getCampaignDetails(parsedCampaignId)
      if (error) {
        return Promise.reject(error)
      }
      if (!data) {
        redirectToLink({
          url: '/checkout-upsell',
        })
      }
      return data
    },
    enabled: campaignId !== null,
  })

  const configQuery = useQuery({
    queryKey: ['checkoutUpsellConfig'],
    queryFn: async () => {
      const { data, error } = await getCheckoutUpsellConfig()
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })

  const campaign = campaignQuery.data ?? {}

  if (campaignQuery.isLoading || configQuery.isLoading) {
    return <CampaignSkeleton title={campaign.name} />
  }

  return (
    <Page
      title={campaign.name}
      titleMetadata={
        <InlineStack align="center" gap={'200'}>
          <Badge
            progress={campaign.enable ? 'complete' : 'incomplete'}
            tone={
              campaign.status === CAMPAIGN_STATUSES.ACTIVE
                ? 'success'
                : campaign.status === CAMPAIGN_STATUSES.DRAFT
                  ? 'info'
                  : 'default'
            }
          >
            {t(`CheckoutUpsell.CampaignDetail.status.${campaign.status}`)}
          </Badge>
        </InlineStack>
      }
      additionalMetadata={t('CheckoutUpsell.CampaignDetail.additionalMetadata')}
      backAction={{
        url: '/checkout-upsell',
      }}
    >
      <UpsertForm
        refreshData={() => {
          campaignQuery.refetch()
          configQuery.refetch()
        }}
        mode="update"
        currentValues={campaign}
        onSubmit={updateCampaignHandler}
        checkoutConfig={configQuery.data}
        onDelete={async () => {
          if (
            !confirm(
              t('CheckoutUpsell.CampaignDetail.deleteConfirm', {
                name: campaign.name,
              }),
            )
          ) {
            return
          }
          setDeleting(true)
          await updateCampaign(campaign.id, {
            status: CAMPAIGN_STATUSES.ARCHIVED,
          })
          redirectToLink({
            url: '/checkout-upsell',
          })
        }}
        campaignId={campaign.id}
      />
    </Page>
  )

  async function updateCampaignHandler(fieldValues, cb) {
    const { error } = await updateCampaign(campaign.id, {
      triggerType: fieldValues.triggerType,
      triggerGroup: fieldValues.triggerGroup,
      status: fieldValues.status,
      products: fieldValues.products,
      startDate: fieldValues.duration?.start
        ? fieldValues.duration.start.valueOf() / 1000
        : null,
      endDate: fieldValues.duration?.end
        ? fieldValues.duration.end.valueOf() / 1000
        : null,
      name: fieldValues.name,
      postCheckoutData: fieldValues.postCheckoutData,
    })
    if (error) {
      throw new Error(error.message)
    }
    await campaignQuery.refetch()
    shopify.toast.show(
      t('DefaultText.updateSuccess', {
        text: 'Campaign: ' + campaign.name,
      }),
    )
    cb()
  }
}

export default CampaignDetails
