import React from 'react'

import { currencyFormatter } from '../../../../../../../../../../utils/formater'

export default function RenderMoney({ price, className, style }) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: currencyFormatter(window.shopify.data.shop.currency, price),
      }}
      style={style}
      className={className}
    ></span>
  )
}
