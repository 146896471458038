import React, { useCallback, useEffect, useState } from 'react'
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineGrid,
  InlineStack,
  Link,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Tabs,
  Text,
  TextField,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import {
  ChevronRightIcon,
  ChevronUpIcon,
  StatusActiveIcon,
} from '@shopify/polaris-icons'

import { numberFormater } from '../../utils/formater'
import { useDashboardDetails } from '../../hooks/useDashboardDetails'
import PlanDowngradeFeedbackForm from '../../components/PlanDowngrafeFeedbackForm'
import { useRedirect } from '../../hooks'
import queryClient from '../../utils/query'
import { openChat } from '../../components/ExternalScripts'
import { areFeaturesEnabled } from '../../utils/features'
import { FEATURES } from '../../constants/features'
import { WIDGET_TYPES } from '../../constants/widgets'
import { HubspotClient } from '../../models/hubspot'
import * as PAGES from '../../constants/page'
import { invalidateShopStatus } from '../../hooks/useShopStatus'

function Pricing({
  plans,
  skipPage = false,
  isLoading = false,
  addPromoCode,
  changePlanCB,
  dashboardDetails
}) {
  const {
    data: dashboardData,
    isLoading: dashboardLoading,
    invalidate,
  } = dashboardDetails || useDashboardDetails()
  const { t } = useTranslation()
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isAnnual, setAnnual] = useState(
    dashboardData?.shopPlan?.onAnnual || false,
  )
  const breakpoints = useBreakpoints()
  const filteredPlans = plans.filter((plan) =>
    isAnnual ? plan.hasAnnualPrice === isAnnual : true,
  )
  const { redirectToLink } = useRedirect()
  const liveSupportEnabled = areFeaturesEnabled(
    [FEATURES.LIVE_SUPPORT],
    dashboardData,
  )

  const codeSubmitHandler = useCallback(async () => {
    if (!code) {
      setCodeError('Code Cannot be empty')
      return
    }
    setLoading(true)
    await addPromoCode(code)
    setLoading(false)
  }, [code])

  const updateCode = useCallback(
    (value) => {
      setCode(value)
      setCodeError(false)
    },
    [setCode, setCodeError],
  )

  const changePlan = useCallback(async ({ plan, promo, onAnnual }) => {
    const url = await changePlanCB({ plan, promo, onAnnual })
    if (url) {
      redirectToLink({
        url,
        external: true,
      })
    }
    await invalidateShopStatus()
    setTimeout(() => {
      invalidate()
    }, 200)
  }, [])

  const selectOptions = [
    {
      label: t('PricingModal.monthlyPlan'),
      value: 'monthly',
    },
    {
      label: t('PricingModal.annualPlan'),
      value: 'annual',
    },
  ]

  if (isLoading || dashboardLoading) {
    return <Skeleton />
  }

  if (plans.length === 0) {
    return (
      <div
        style={{
          height: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box padding={'300'}>
          <Text>
            {t('PricingModal.noPlans', {
              link: (
                <>
                  {liveSupportEnabled && (
                    <>
                      <Link onClick={openChat} monochrome>
                        {t('PricingModal.liveSupport')}
                      </Link>{' '}
                      or{' '}
                    </>
                  )}
                  <Link monochrome>{t('PricingModal.contactUs')}</Link>
                </>
              ),
            })}
          </Text>
        </Box>
      </div>
    )
  }

  const children = (
    <Box padding={'300'}>
      <BlockStack gap={'400'}>
        {skipPage && (
          <InlineGrid columns={'1fr auto'} alignItems="start">
            <Text as="p">
              {t('PricingModal.description', {
                selectionText: (
                  <Link monochrome url="/pricing">
                    {t('PricingModal.selectionText')}
                  </Link>
                ),
              })}
            </Text>
            <InlineStack gap={'300'} align="start">
              <InlineStack gap={'200'} align="start">
                <TextField
                  placeholder={t('PricingModal.promotionPlaceholder')}
                  value={code}
                  onChange={updateCode}
                  error={codeError}
                />
                <div>
                  <Button
                    variant="primary"
                    onClick={codeSubmitHandler}
                    loading={loading}
                  >
                    {t('PricingModal.promotionCta')}
                  </Button>
                </div>
              </InlineStack>
            </InlineStack>
          </InlineGrid>
        )}
        <InlineGrid
          alignItems="start"
          gap={'300'}
          columns={
            breakpoints.lgUp
              ? Array(filteredPlans.length).fill('1fr').join(' ')
              : '1fr'
          }
        >
          {filteredPlans.map((plan) => {
            const isCurrentPlan =
              plan.id == dashboardData.shopPlan.plan &&
              isAnnual === dashboardData.shopPlan.onAnnual
            const price = isAnnual ? plan.annualPrice : plan.price
            const isDowngrade =
              parseInt(price) <= parseInt(dashboardData?.shopPlan?.price)
            return (
              <PlanCard
                showAnnual={isAnnual}
                isDowngrade={isDowngrade}
                onPlanChange={() =>
                  changePlan({
                    plan: plan.slug,
                    promo: code,
                    onAnnual: isAnnual,
                  })
                }
                isCurrentPlan={isCurrentPlan}
                horizontal={filteredPlans.length === 1}
                plan={plan}
                key={plan.id}
                isPopular={plan.slug === 'pro'}
                promotionData={plan.promotionData ?? {}}
              />
            )
          })}
        </InlineGrid>
        <div
          style={{
            padding: 'var(--p-space-300)',
          }}
        />
      </BlockStack>
    </Box>
  )

  if (skipPage) {
    return children
  }

  const showSelector = plans.some((plan) => plan.hasAnnualPrice)

  return (
    <Page
      backAction={{
        url: '/',
      }}
      fullWidth
      title={t('PricingPage.title')}
      subtitle={
        <Text as="p">
          {t('PricingModal.description', {
            selectionText: (
              <Link url="/pricing" monochrome>
                {t('PricingModal.selectionText')}
              </Link>
            ),
          })}
        </Text>
      }
      primaryAction={
        <InlineGrid columns={'auto auto'} alignItems="center" gap={'200'}>
          {showSelector && (
            <Select
              options={selectOptions}
              value={isAnnual ? 'annual' : 'monthly'}
              onChange={(val) => {
                setAnnual(val === 'annual')
              }}
            />
          )}
          <InlineStack gap={'200'} align="start">
            <TextField
              placeholder={t('PricingModal.promotionPlaceholder')}
              value={code}
              onChange={updateCode}
              error={codeError}
            />
            <div>
              <Button
                variant="primary"
                onClick={codeSubmitHandler}
                loading={loading}
              >
                {t('PricingModal.promotionCta')}
              </Button>
            </div>
          </InlineStack>
        </InlineGrid>
      }
    >
      {children}
      <div
        style={{
          padding: 'var(--p-space-400)',
        }}
      />
    </Page>
  )
}

export default Pricing

function PlanCard({
  plan,
  horizontal = false,
  isPopular = false,
  isCurrentPlan,
  isDowngrade,
  promotionData,
  onPlanChange,
  showAnnual,
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [feedbackModalOpen, setFeebackModalOpen] = useState(false)
  let featureEnabled = plan.featuresEnabled.filter((feature) => {
    const ignoredFeatures = [FEATURES.FUNNELS]
    return !ignoredFeatures.includes(feature)
  })
  featureEnabled = FeaturesOrder.filter((feature) =>
    featureEnabled.some((f) => f === feature),
  )
  plan.pagesEnabled = PagesOrder.filter((page) =>
    plan.pagesEnabled.some((p) => p === page),
  )
  plan.recommendationsEnabled = RecommendationOrder.filter((widget) =>
    plan.recommendationsEnabled.some((w) => w === widget),
  )
  let prevPrice = null,
    currentPrice = showAnnual ? plan.annualPrice : plan.price,
    promoText = null

  if (promotionData?.price) {
    prevPrice = showAnnual ? plan.annualPrice : plan.price
    currentPrice =
      (showAnnual ? promotionData.annualPrice ?? null : promotionData.price) ??
      0
    promoText = t('PricingModal.PlanTemplate.discountPromoHeroTextTemplate', {
      price: (parseFloat(prevPrice) - parseFloat(currentPrice)).toFixed(2),
      promoCode: promotionData.code,
    })
  } else if (promotionData?.trialDays) {
    promoText = t('PricingModal.PlanTemplate.trialPromoHeroTextTemplate', {
      days: promotionData.trialDays,
      promoCode: promotionData.code,
    })
  }

  const feedbackHandler = async (values) => {
    const { error } = await sendToHubspot(values)
    if (error) {
      return {
        error,
      }
    }
    await onPlanChange()
    setFeebackModalOpen(false)
    return {
      data: 'success',
      error: null,
    }
  }

  const clickHandler = async () => {
    setLoading(true)
    if (isDowngrade) {
      setFeebackModalOpen(true)
    } else {
      await onPlanChange()
    }
    setLoading(false)
  }

  const content = (showBadge = false) => (
    <>
      <ListStack
        title={t('PricingModal.PlanTemplate.Features.title')}
        items={featureEnabled.map((feature) =>
          t(`PricingModal.PlanTemplate.Features.${feature}`),
        )}
        collapsed={false}
        badgeContent={
          !showBadge &&
          featureEnabled.length > 3 &&
          `+${featureEnabled.length - 3}`
        }
        darkTheme={!horizontal && isPopular}
      />
      <ListStack
        title={t('PricingModal.PlanTemplate.Widget.title')}
        items={plan.recommendationsEnabled.map((widget) =>
          t(`Widgets.${widget}.label`),
        )}
        collapsed={!showBadge}
        badgeContent={
          !showBadge && `+${plan.recommendationsEnabled.length - 3}`
        }
        darkTheme={!horizontal && isPopular}
      />
      <ListStack
        title={t('PricingModal.PlanTemplate.Pages.title')}
        items={plan.pagesEnabled.map((page) => t(`Pages.${page}.label`))}
        badgeContent={
          !showBadge &&
          plan.pagesEnabled.length > 3 &&
          `+${plan.pagesEnabled.length - 3}`
        }
        collapsed={!showBadge}
        darkTheme={!horizontal && isPopular}
      />
    </>
  )

  const maxPrice = parseFloat(
    (
      (parseFloat(plan.recommendationServesBatchPrice) *
        (parseInt(plan.recommendationsServedHardLimit) -
          parseInt(plan.maximumRecommendationsServed))) /
      parseInt(plan.recommendationServesBatchSize)
    ).toFixed(2),
  )

  const planNote = (
    <Text
      tone={!horizontal && isPopular ? 'text-inverse-secondary' : 'subdued'}
      as="p"
      variant="bodyMd"
    >
      <span
        style={{
          color: !horizontal && isPopular ? 'white' : undefined,
        }}
      >
        {plan.isUsageBased && !plan.isFree
          ? t('PricingModal.PlanTemplate.Widget.note', {
              pricePer1000Widgets: (
                <strong
                  style={{
                    color: !horizontal && isPopular ? '#C4FAD0' : undefined,
                  }}
                >
                  {numberFormater(plan.recommendationServesBatchSize)}
                </strong>
              ),
              rate: (
                <strong
                  style={{
                    color: !horizontal && isPopular ? '#C4FAD0' : undefined,
                  }}
                >
                  ${plan.recommendationServesBatchPrice} /{' '}
                  {numberFormater(plan.recommendationServesBatchSize)}
                </strong>
              ),
              maxWidgets: (
                <strong
                  style={{
                    color: !horizontal && isPopular ? '#C4FAD0' : undefined,
                  }}
                >
                  {numberFormater(plan.maximumRecommendationsServed)}
                </strong>
              ),
              maxPrice: (
                <strong>
                  $
                  {plan.maximumPrice
                    ? Math.min(parseFloat(plan.maximumPrice), maxPrice)
                    : maxPrice}
                </strong>
              ),
              maxWidgetsThreshold: (
                <strong
                  style={{
                    color: !horizontal && isPopular ? '#C4FAD0' : undefined,
                  }}
                >
                  {numberFormater(plan.recommendationsServedHardLimit)}
                </strong>
              ),
            })
          : t('PricingModal.PlanTemplate.Widget.notUsageBased')}
      </span>
    </Text>
  )

  if (horizontal) {
    return (
      <Box
        borderRadius="200"
        borderColor={'border'}
        borderWidth={'025'}
        padding={'0'}
        background="bg-fill"
      >
        <InlineGrid columns={'1fr 2fr'} alignItems="start" gap={'400'}>
          <div
            style={{
              borderRight:
                'var(--p-border-width-025) solid var(--p-color-border)',
              height: '100%',
            }}
          >
            <Box padding={'400'}>
              <BlockStack gap={'400'}>
                <BlockStack gap={'100'}>
                  <InlineGrid columns={'1fr auto'}>
                    <Text as="strong" variant="headingLg">
                      {plan.name}
                    </Text>
                    <InlineStack blockAlign="end">
                      <Text as="strong" variant="headingLg">
                        ${currentPrice}
                      </Text>
                      <Text>
                        {showAnnual
                          ? t('PricingModal.PlanTemplate.Widget.annualRate')
                          : t('PricingModal.PlanTemplate.Widget.rate')}
                      </Text>
                    </InlineStack>
                  </InlineGrid>
                  <InlineGrid columns={'1fr auto'}>
                    <Text>
                      {t('PricingModal.PlanTemplate.Widget.subtitle', {
                        widgetsQuantity: (
                          <strong>
                            {numberFormater(plan.maximumRecommendationsServed)}
                          </strong>
                        ),
                      })}
                    </Text>
                    <Text>
                      {prevPrice && (
                        <Text
                          variant="bodyLg"
                          textDecorationLine="line-through"
                        >
                          {prevPrice}
                          {showAnnual
                            ? t('PricingModal.PlanTemplate.Widget.annualRate')
                            : t('PricingModal.PlanTemplate.Widget.rate')}
                        </Text>
                      )}
                    </Text>
                  </InlineGrid>
                  {promoText && (
                    <div
                      style={{
                        background: isPopular ? '#63CE8E2B' : '#63CE8E33',
                        textAlign: 'center',
                        padding:
                          'var(--p-space-100) var(--p-space-200) var(--p-space-100) var(--p-space-200)',
                        borderRadius: 'var(--p-border-radius-200)',
                        margin: 'var(--p-space-200) 0 0 0',
                      }}
                    >
                      <Text as="h3" variant="headingSm">
                        <span
                          style={{
                            color: isPopular
                              ? 'white'
                              : 'var(--p-color-text-success)',
                          }}
                        >
                          {promoText}
                        </span>
                      </Text>
                    </div>
                  )}
                </BlockStack>
                {planNote}
                <Button
                  loading={loading}
                  onClick={clickHandler}
                  disabled={isCurrentPlan}
                  variant={(() => {
                    if (isDowngrade) {
                      return isPopular ? 'primary' : 'secondary'
                    }
                    return isPopular ? 'secondary' : 'primary'
                  })()}
                  size="large"
                >
                  {isCurrentPlan
                    ? t('PricingModal.PlanTemplate.currentPlan')
                    : isDowngrade
                      ? t('PricingModal.PlanTemplate.downgradeCta')
                      : t('PricingModal.PlanTemplate.buyCta')}
                </Button>
              </BlockStack>
            </Box>
          </div>
          <Box padding={'400'}>
            <InlineGrid columns={3} gap={'300'} align="start">
              {content(true)}
            </InlineGrid>
          </Box>
        </InlineGrid>
        <PlanDowngradeFeedbackForm
          submitCallback={feedbackHandler}
          open={feedbackModalOpen}
          setOpen={setFeebackModalOpen}
        />
      </Box>
    )
  }

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        padding: 'var(--p-space-300)',
        background: isPopular
          ? 'var(--p-color-bg-fill-inverse)'
          : 'var(--p-color-bg-fill)',
        borderRadius: 'var(--p-border-radius-200)',
        border: `solid var(--p-border-width-${
          isPopular ? '0' : '025'
        }) var(--p-color-${isPopular ? 'transparent' : 'border'})`,
      }}
    >
      <BlockStack gap={'300'}>
        <BlockStack gap={'100'}>
          <InlineGrid columns={'1fr auto'}>
            <Text
              as="strong"
              tone={isPopular ? 'caution' : 'base'}
              variant="headingLg"
            >
              <span
                style={{
                  color: isPopular
                    ? 'var(--p-color-bg-fill-warning)'
                    : undefined,
                }}
              >
                {plan.name}
              </span>
            </Text>
            <InlineStack blockAlign="end">
              <Text
                as="strong"
                tone={isPopular ? 'text-inverse' : 'base'}
                variant="headingLg"
              >
                <span
                  style={{
                    color: isPopular ? 'white' : undefined,
                  }}
                >
                  ${currentPrice}
                </span>
              </Text>
              <Text tone={isPopular ? 'text-inverse' : 'base'}>
                <span
                  style={{
                    color: isPopular ? 'white' : undefined,
                  }}
                >
                  {showAnnual
                    ? t('PricingModal.PlanTemplate.Widget.annualRate')
                    : t('PricingModal.PlanTemplate.Widget.rate')}
                </span>
              </Text>
            </InlineStack>
          </InlineGrid>
          <InlineGrid columns={'1fr auto'}>
            <Text tone={isPopular ? 'text-inverse-secondary' : 'base'}>
              <span
                style={{
                  color: isPopular ? 'white' : undefined,
                }}
              >
                {t('PricingModal.PlanTemplate.Widget.subtitle', {
                  widgetsQuantity: (
                    <strong>
                      {numberFormater(plan.maximumRecommendationsServed)}
                    </strong>
                  ),
                })}
              </span>
            </Text>
            <Text tone={isPopular ? 'text-inverse-secondary' : 'base'}>
              {prevPrice && (
                <Text variant="bodyLg" textDecorationLine="line-through">
                  <span
                    style={{
                      color: isPopular ? 'white' : undefined,
                    }}
                  >
                    ${prevPrice}
                    {showAnnual
                      ? t('PricingModal.PlanTemplate.Widget.annualRate')
                      : t('PricingModal.PlanTemplate.Widget.rate')}
                  </span>
                </Text>
              )}
            </Text>
          </InlineGrid>
          {promoText && (
            <div
              style={{
                background: isPopular ? '#63CE8E2B' : '#63CE8E33',
                textAlign: 'center',
                padding:
                  'var(--p-space-100) var(--p-space-200) var(--p-space-100) var(--p-space-200)',
                borderRadius: 'var(--p-border-radius-200)',
                margin: 'var(--p-space-200) 0 0 0',
              }}
            >
              <Text as="h3" variant="headingSm">
                <span
                  style={{
                    color: isPopular ? 'white' : 'var(--p-color-text-success)',
                  }}
                >
                  {promoText}
                </span>
              </Text>
            </div>
          )}
        </BlockStack>
        {planNote}
        <Button
          loading={loading}
          onClick={clickHandler}
          disabled={isCurrentPlan}
          variant={(() => {
            if (isDowngrade) {
              return isPopular ? 'primary' : 'secondary'
            }
            return isPopular ? 'secondary' : 'primary'
          })()}
          size="large"
        >
          {isCurrentPlan
            ? t('PricingModal.PlanTemplate.currentPlan')
            : isDowngrade
              ? t('PricingModal.PlanTemplate.downgradeCta')
              : t('PricingModal.PlanTemplate.buyCta')}
        </Button>
        <BlockStack gap={'300'}>{content(false)}</BlockStack>
      </BlockStack>
      {isPopular && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            transform: 'translateY(-50%)',
            left: 'var(--p-space-300)',
          }}
        >
          <Badge tone="warning-strong">
            {t('PricingPage.recommendedText')}
          </Badge>
        </div>
      )}
      <PlanDowngradeFeedbackForm
        submitCallback={feedbackHandler}
        open={feedbackModalOpen}
        setOpen={setFeebackModalOpen}
      />
    </div>
  )
}

function ListStack({
  title,
  items,
  visibleInCollapse = 3,
  badgeContent,
  collapsed = true,
  darkTheme = false,
}) {
  const [sectionCollapsed, setSectionCollapsed] = useState(collapsed)
  return (
    <BlockStack gap={'100'} padding={'0'}>
      <InlineGrid columns={'1fr auto'} alignItems="center">
        <Text tone="subdued" variant="headingMd">
          <span
            style={{
              color: darkTheme ? 'white' : undefined,
            }}
          >
            {title}
          </span>
        </Text>
        {badgeContent && (
          <InlineStack>
            <Badge tone={darkTheme ? 'info-strong' : undefined}>
              {sectionCollapsed ? badgeContent : items.length}
            </Badge>
            <Button
              variant="plain"
              onClick={() => {
                setSectionCollapsed(!sectionCollapsed)
              }}
              icon={sectionCollapsed ? ChevronRightIcon : ChevronUpIcon}
            />
          </InlineStack>
        )}
      </InlineGrid>
      <BlockStack gap={'100'}>
        {items
          .slice(0, sectionCollapsed ? visibleInCollapse : undefined)
          .map((item, index) => {
            return (
              <InlineGrid columns={'auto 1fr'} gap={'100'} alignItems="start">
                <Icon
                  source={() => (
                    <StatusActiveIcon
                      style={{
                        color: darkTheme ? 'white' : undefined,
                      }}
                    />
                  )}
                />
                <Text key={index}>
                  <span
                    style={{
                      color: darkTheme ? 'white' : undefined,
                    }}
                  >
                    {item}
                  </span>
                </Text>
              </InlineGrid>
            )
          })}
      </BlockStack>
    </BlockStack>
  )
}

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage fullWidth primaryAction title={t('PricingPage.title')}>
      <InlineGrid columns={3} gap={'300'}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Card>
            <BlockStack gap={'300'}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={10} />
            </BlockStack>
          </Card>
        ))}
      </InlineGrid>
    </SkeletonPage>
  )
}

const HSclient = new HubspotClient({
  portalId: '22080564',
  formId: 'ff620b79-2e7d-4c08-93ff-3f1a40ee6b1b',
})

const sendToHubspot = async (values) => {
  try {
    const { reason, otherReason, feedback } = values
    const payload = {
      'TICKET.hs_ticket_category': 'PLAN_DOWNGRADE',
      email: window.shopify.data.user.email,
      'TICKET.subject': otherReason || reason,
      'TICKET.content': feedback,
      shopify_domain: window.shopify.data.shop.myshopifyDomain,
    }
    await HSclient.sendForm(payload)
    return {
      error: null,
    }
  } catch (e) {
    return {
      error: e,
    }
  }
}

const FeaturesOrder = [
  FEATURES.BASIC_RECOMMENDATIONS,
  FEATURES.BUNDLES,
  FEATURES.CHECKOUT_SECTIONS,
  FEATURES.POST_PURCHASE,
  FEATURES.ACCOUNT_PAGE_PERSOLISATION,
  FEATURES.CUSTOM_TEMPLATES,
  FEATURES.RECOMMENDATION_RULES,
  FEATURES.DISCOUNTS,
  FEATURES.SEGMENTS,
  FEATURES.TRANSLATIONS,
  FEATURES.AB_EXPERIENCE,
  FEATURES.LIVE_SUPPORT,
]

const RecommendationOrder = [
  WIDGET_TYPES.bought_together.value,
  WIDGET_TYPES.personalized.value,
  WIDGET_TYPES.similar_products.value,
  WIDGET_TYPES.cross_sell.value,
  WIDGET_TYPES.recently_viewed.value,
  WIDGET_TYPES.slots.value,
  WIDGET_TYPES.bestsellers.value,
  WIDGET_TYPES.trending.value,
  WIDGET_TYPES.recent_launch.value,
  WIDGET_TYPES.collections.value,
  WIDGET_TYPES.promoted.value,
  WIDGET_TYPES.custom.value,
]

const PagesOrder = [
  PAGES.HOME.value,
  PAGES.PRODUCT.value,
  PAGES.AJAX_CART.value,
  PAGES.CART.value,
  PAGES.COLLECTION.value,
  PAGES.BLOG.value,
  PAGES.OTHER.value,
  PAGES.NOT_FOUND.value,
  PAGES.RETURNS.value,
]
